import { Block } from 'baseui/block';
import {
  Button,
  KIND,
} from 'baseui/button';
import {
  ALIGNMENT,
  Cell,
  Grid,
} from 'baseui/layout-grid';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import {
  setWizardActiveSections,
  wizardSectionSelector,
  wizardSectionsSelector,
} from 'store/slices/sections';
import {
  useAppDispatch,
  useAppSelector,
} from 'store/hooks';

export type WizardContainerPropsType = {
  children: ReactNode,
  nextButtonDisabled?: boolean,
  onAction: () => void,
  onFinish: () => void,
};

const WizardContainer = ({
  children,
  nextButtonDisabled = false,
  onAction,
  onFinish,
}: WizardContainerPropsType) => {
  const { t } = useTranslation(['common']);
  const dispatch = useAppDispatch();
  const activeSection = useAppSelector(wizardSectionSelector);
  const sectionsNumber = useAppSelector(wizardSectionsSelector).length;

  const handleOnClickNext = () => {
    if (activeSection < sectionsNumber - 1) {
      dispatch(setWizardActiveSections(activeSection + 1));
      onAction();
    } else {
      onFinish();
    }
  };

  const handleOnClickPrevious = () => {
    dispatch(setWizardActiveSections(activeSection - 1));
    activeSection < sectionsNumber - 1 && onAction();
  };

  return (
    <Grid
      align={ALIGNMENT.center}
    >
      <Cell
        span={12}
        overrides={{
          Cell: {
            style: {
              background: '#FFFFFF',
              'list-style': 'none',
              marginTop: '24px',
            },
          },
        }}
      >
        {children}
        <hr />

        <Block margin="24px" display="flex" justifyContent="space-between">
          {activeSection !== 0 ? (
            <Button
              kind={KIND.secondary}
              onClick={handleOnClickPrevious}
              overrides={{
                BaseButton: {
                  props: {
                    id: 'wizard-container-previous-btn',
                  },
                },
              }}
            >
              {t('common:previous')}
            </Button>
          ) : (
            <Block />
          )}
          <Button
            kind={KIND.primary}
            disabled={nextButtonDisabled}
            onClick={handleOnClickNext}
            overrides={{
              BaseButton: {
                props: {
                  id: 'wizard-container-next-btn',
                },
              },
            }}
          >
            {activeSection < sectionsNumber - 1 ? t('common:next') : t('common:finish')}
          </Button>
        </Block>
      </Cell>
    </Grid>
  );
};

export default WizardContainer;
