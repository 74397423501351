import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  fetchLocationsRequest,
  createLocationRequest,
  editLocationRequest,
  fetchLocationByIdRequest,
  fetchLocationConfigsRequest,
  saveLocationConfigRequest,
  generateLocationsConfigReportRequest,
  patchLocationRequest,
  importLocationsRequest,
} from 'api/locationsAPI';
import {
  ConfigType,
} from 'types/OrganizationTypes';
import {
  CreateLocationParamsType,
  EditLocationConfigsParamsType,
  EditLocationParamsType,
  EditLocationPaycardConfigType,
  FetchLocationParamsType,
  FetchLocationPaycardConfigParamsType,
  FetchLocationsParamsType,
  GenerateLocationsConfigsReportParamsType,
  ImportLocationsPropsType,
  LocationConfig,
  LocationConfigResponseType,
  LocationResponseType,
  LocationStatus,
  SaveLocationPaycardConfigType,
  UpdateLocationStatusPropsType,
} from 'types/LocationTypes';
import {
  fetchLocationPaycardConfigRequest,
  saveLocationPaycardConfigRequest,
} from 'bankingApi/paycardAPI';
import {
  fetchEmployeesWithConfigsLocationsAndGroups,
} from 'store/slices/employees';
import {
  fetchPayGroupsWithLocations,
  resetOrganizationEvent,
} from 'store/events';
import { PayGroupDTOType } from 'types/PayGroupTypes';
import { RootState } from '..';

export interface Location {
  id: string
  externalLocationId: string
  name: string
  dayCutoffTime: string
  timezone: string
  status: LocationStatus
  payGroup: PayGroupDTOType | null
  organization: {
    id: string,
    name: string,
    programsAvailable?: string[]
  }
  tipsProviderId?: string
  netSuiteId?: string
  externalIntegrationId?: string
  storeNumber?: string
  alphaNumericStoreNumber?: string
  conceptId?: string
  bankAccount?: {
    bankAccountId: string
    bankAccountAlias: string
  }
}

export const initialState = {
  list: [] as Location[],
  isListFetched: false,
  locationCreated: false,
  locationEditedSuccessfully: false,
  selectedLocation: null as LocationResponseType | null,
  pending: false,
  pendingList: false,
  pendingPatch: false,
  locationsAssigned: true,
  locationConfig: undefined as LocationConfigResponseType | undefined,
  locationsConfigCsv: undefined,
  locationPaycardSettings: {
    data: undefined as SaveLocationPaycardConfigType | undefined,
    pending: false,
    failed: false,
    failedStatusCode: undefined as number | undefined,
  },
};

export const fetchLocations = createAsyncThunk(
  'locations',
  async (params: FetchLocationsParamsType, { getState, rejectWithValue }): Promise<any> => {
    const { organizationID, onlyTcoEnabled, pageSize = '1000' } = params;
    const storeState = getState() as RootState;

    try {
      const result = await fetchLocationsRequest(storeState.user.accessToken, organizationID, onlyTcoEnabled?.toString(), pageSize);
      return result?.values;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchLocationConfigs = createAsyncThunk(
  'locations/ID/configs',
  async (params: FetchLocationParamsType, { getState, rejectWithValue }): Promise<any> => {
    const { organizationID, locationID } = params;
    const storeState = getState() as RootState;

    try {
      const result = await fetchLocationConfigsRequest(storeState.user.accessToken, organizationID, locationID);
      return result?.values;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchLocation = createAsyncThunk(
  'locations/ID',
  async (params: FetchLocationParamsType, { dispatch, getState, rejectWithValue }): Promise<any> => {
    const { organizationID, locationID } = params;
    const storeState = getState() as RootState;

    try {
      const result = await fetchLocationByIdRequest(storeState.user.accessToken, organizationID, locationID);

      await dispatch(fetchLocationConfigs({
        organizationID,
        locationID,
      }));

      return result;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const createLocation = createAsyncThunk(
  'locations/create',
  async (params: CreateLocationParamsType, { dispatch, getState, rejectWithValue }): Promise<any> => {
    const { organizationID, configs, data } = params;
    const storeState = getState() as RootState;

    try {
      const result = await createLocationRequest(storeState.user.accessToken, organizationID, data);

      const { id: locationID } = result || {};

      locationID && await saveLocationConfigRequest(storeState.user.accessToken, organizationID, locationID, configs);

      await dispatch(fetchLocation({ organizationID, locationID }));

      return result;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const importLocations = createAsyncThunk(
  'locations/import',
  async (params: ImportLocationsPropsType, { dispatch, getState, rejectWithValue }): Promise<any> => {
    const { organizationID, formData } = params;
    const storeState = getState() as RootState;

    try {
      const result = await importLocationsRequest(storeState.user.accessToken, organizationID, formData);

      dispatch(fetchLocations({ organizationID }));
      return result;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const editLocation = createAsyncThunk(
  'locations/ID/edit',
  async (params: EditLocationParamsType, { dispatch, getState, rejectWithValue }): Promise<any> => {
    const {
      organizationID,
      locationID,
      configs,
      data,
    } = params;
    const storeState = getState() as RootState;
    const remappedConfigs = configs.map((i) => ({
      ...i,
      configValue: i.configKeyName === ConfigType.JOB_CODE_EXCLUSIONS && !i.configValue ? null : i.configValue,
    }));
    try {
      const result = await Promise.all([
        editLocationRequest(storeState.user.accessToken, organizationID, locationID, data),
        saveLocationConfigRequest(storeState.user.accessToken, organizationID, locationID, remappedConfigs),
      ]);

      await dispatch(fetchLocation({ organizationID, locationID }));

      return result;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const editLocationConfigs = createAsyncThunk(
  'locations/ID/edit/configs',
  async (params: EditLocationConfigsParamsType, { dispatch, getState, rejectWithValue }): Promise<any> => {
    const {
      organizationID,
      locationID,
      configs,
    } = params;
    const storeState = getState() as RootState;
    const remappedConfigs = configs.map((i) => ({
      ...i,
      configValue: i.configKeyName === ConfigType.JOB_CODE_EXCLUSIONS && !i.configValue ? null : i.configValue,
    }));
    try {
      const result = await Promise.all([
        saveLocationConfigRequest(storeState.user.accessToken, organizationID, locationID, remappedConfigs),
      ]);

      await dispatch(fetchLocationConfigs({
        organizationID,
        locationID,
      }));
      return result;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const patchLocation = createAsyncThunk(
  'locations/ID/patch',
  async (params: UpdateLocationStatusPropsType, { getState, rejectWithValue }): Promise<any> => {
    const {
      organizationID,
      locationID,
      data,
    } = params;
    const storeState = getState() as RootState;

    try {
      return await patchLocationRequest(storeState.user.accessToken, organizationID, locationID, data);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const generateLocationsConfigReport = createAsyncThunk(
  'locations-configs',
  async (params: GenerateLocationsConfigsReportParamsType, { getState, rejectWithValue }): Promise<any> => {
    const {
      organizationID,
    } = params;
    const storeState = getState() as RootState;

    try {
      return await generateLocationsConfigReportRequest(storeState.user.accessToken, organizationID);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchLocationPaycardConfig = createAsyncThunk(
  'location/paycard-settings',
  async (params: FetchLocationPaycardConfigParamsType, { getState, rejectWithValue }): Promise<any> => {
    const { organizationId, locationId } = params;
    const storeState = getState() as RootState;

    try {
      return await fetchLocationPaycardConfigRequest(
        storeState.user.accessToken,
        organizationId,
        locationId,
      );
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const saveLocationPaycardConfig = createAsyncThunk(
  'location/paycard-settings/save',
  async (params: EditLocationPaycardConfigType, { dispatch, getState, rejectWithValue }): Promise<any> => {
    const { organizationId, locationId, data } = params;
    const storeState = getState() as RootState;

    try {
      const result = await Promise.all([
        saveLocationPaycardConfigRequest(
          storeState.user.accessToken,
          organizationId,
          locationId,
          data,
        ),
      ]);

      await dispatch(
        fetchLocationPaycardConfig({
          organizationId,
          locationId,
        }),
      );
      return result;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

const locationsSlice = createSlice({
  name: 'locations',
  initialState,
  reducers: {
    setLocations: (state, action) => {
      state.list = action.payload;
      state.pendingList = false;
      state.isListFetched = true;
    },
    setLocationCreated: (state, action) => {
      state.locationCreated = action.payload;
    },
    resetLocations: () => initialState,
    resetLocationConfigCsv: (state) => {
      state.locationsConfigCsv = undefined;
    },
    resetSelectedLocation: (state) => {
      state.locationCreated = initialState.locationCreated;
      state.selectedLocation = initialState.selectedLocation;
      state.locationConfig = initialState.locationConfig;
    },
    resetAssignedLocations: (state) => {
      state.locationsAssigned = initialState.locationsAssigned;
    },
    resetLocationSuccessfulOperation: (state) => {
      state.locationCreated = initialState.locationCreated;
      state.locationEditedSuccessfully = initialState.locationEditedSuccessfully;
    },
    resetLocationPaycardSettings: (state) => {
      state.locationPaycardSettings = initialState.locationPaycardSettings;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLocations.pending, (state) => {
      state.pendingList = true;
      state.list = initialState.list;
      state.selectedLocation = initialState.selectedLocation;
    });

    builder.addCase(fetchLocations.fulfilled, (state, action) => {
      state.list = action.payload;
      state.pendingList = false;
      state.isListFetched = true;
    });

    builder.addCase(fetchLocations.rejected, (state) => {
      state.pendingList = false;
    });

    builder.addCase(fetchLocation.pending, (state) => {
      state.pending = true;
    });

    builder.addCase(fetchLocation.fulfilled, (state, action) => {
      state.selectedLocation = action.payload;
      state.pending = false;
    });

    builder.addCase(fetchLocation.rejected, (state) => {
      state.pending = false;
    });

    builder.addCase(fetchLocationConfigs.pending, (state) => {
      state.locationConfig = initialState.locationConfig;
      state.pending = true;
    });

    builder.addCase(fetchLocationConfigs.fulfilled, (state, action) => {
      state.locationConfig = action.payload;
      state.pending = false;
    });

    builder.addCase(fetchLocationConfigs.rejected, (state) => {
      state.locationConfig = initialState.locationConfig;
      state.pending = false;
    });

    builder.addCase(createLocation.pending, (state) => {
      state.pending = true;
      state.locationCreated = false;
    });

    builder.addCase(createLocation.fulfilled, (state) => {
      state.locationCreated = true;
      state.pending = false;
    });

    builder.addCase(createLocation.rejected, (state) => {
      state.locationCreated = false;
      state.pending = false;
    });

    builder.addCase(editLocation.pending, (state) => {
      state.pending = true;
      state.locationEditedSuccessfully = false;
    });

    builder.addCase(editLocation.fulfilled, (state) => {
      state.pending = false;
      state.locationEditedSuccessfully = true;
    });

    builder.addCase(editLocation.rejected, (state) => {
      state.pending = false;
      state.locationEditedSuccessfully = false;
    });

    builder.addCase(editLocationConfigs.pending, (state) => {
      state.pending = true;
      state.locationEditedSuccessfully = false;
    });

    builder.addCase(editLocationConfigs.fulfilled, (state) => {
      state.pending = false;
      state.locationEditedSuccessfully = true;
    });

    builder.addCase(editLocationConfigs.rejected, (state) => {
      state.pending = false;
      state.locationEditedSuccessfully = false;
    });

    builder.addCase(saveLocationPaycardConfig.pending, (state) => {
      state.locationPaycardSettings.pending = true;
      state.locationPaycardSettings.failed = false;
    });

    builder.addCase(saveLocationPaycardConfig.fulfilled, (state) => {
      state.locationPaycardSettings.pending = false;
      state.locationPaycardSettings.failed = false;
    });

    builder.addCase(saveLocationPaycardConfig.rejected, (state) => {
      state.locationPaycardSettings.pending = false;
      state.locationPaycardSettings.failed = true;
    });

    builder.addCase(patchLocation.pending, (state) => {
      state.pendingPatch = true;
    });

    builder.addCase(patchLocation.fulfilled, (state, action) => {
      const { id, status, bankAccount } = action.payload;
      const index = state.list.findIndex((item) => item.id === id);
      state.pendingPatch = false;
      state.list[index].status = status;
      state.list[index].bankAccount = bankAccount;
    });

    builder.addCase(patchLocation.rejected, (state) => {
      state.pendingPatch = false;
    });

    builder.addCase(fetchPayGroupsWithLocations.pending, (state) => {
      state.pendingList = true;
      state.list = initialState.list;
    });

    builder.addCase(fetchPayGroupsWithLocations.fulfilled, (state, action) => {
      state.list = action?.payload[1];
      state.pendingList = false;
      state.isListFetched = true;
    });

    builder.addCase(fetchPayGroupsWithLocations.rejected, (state) => {
      state.pendingList = false;
    });

    builder.addCase(generateLocationsConfigReport.fulfilled, (state, action) => {
      state.locationsConfigCsv = action?.payload;
    });

    builder.addCase(fetchEmployeesWithConfigsLocationsAndGroups.fulfilled, (state, action) => {
      state.list = action?.payload[2];
      state.isListFetched = true;
    });

    builder.addCase(resetOrganizationEvent, () => initialState);

    builder.addCase(fetchLocationPaycardConfig.pending, (state) => {
      state.locationPaycardSettings = {
        ...initialState.locationPaycardSettings,
        pending: true,
      };
    });

    builder.addCase(fetchLocationPaycardConfig.fulfilled, (state, action) => {
      state.locationPaycardSettings.data = action.payload;
      state.locationPaycardSettings.pending = false;
      state.locationPaycardSettings.failed = false;
    });

    builder.addCase(fetchLocationPaycardConfig.rejected, (state, action) => {
      state.locationPaycardSettings.pending = false;
      state.locationPaycardSettings.failed = true;
      if (action.payload) {
        const { response } = action.payload as { response: { status: number } };
        if (response.status === 404 || response.status === 400) {
          state.locationPaycardSettings.failedStatusCode = response.status;
        }
      }
    });
  },
});

export const {
  setLocations,
  setLocationCreated,
  resetLocations,
  resetSelectedLocation,
  resetAssignedLocations,
  resetLocationConfigCsv,
  resetLocationSuccessfulOperation,
  resetLocationPaycardSettings,
} = locationsSlice.actions;

export const locationsSelector = (state: RootState): Location[] => state.locations.list;
export const locationPendingSelector = (state: RootState): boolean => state.locations.pending;
export const locationPendingPatchSelector = (state: RootState): boolean => state.locations.pendingPatch;
export const locationPendingListSelector = (state: RootState): boolean => state.locations.pendingList;
export const selectedLocationSelector = (state: RootState): LocationResponseType | null => state.locations.selectedLocation;
export const locationCreatedSelector = (state: RootState): boolean => state.locations.locationCreated;
export const locationsAssignedSelector = (state: RootState): boolean => state.locations.locationsAssigned;
export const locationsIsListFetchedSelector = (state: RootState): boolean => state.locations.isListFetched;
export const locationConfigSelector = (state: RootState): LocationConfig[] | undefined => state.locations.locationConfig;
export const locationsConfigCsvSelector = (state: RootState): string | undefined => state.locations.locationsConfigCsv;
export const locationEditedSuccessfully = (state: RootState): boolean => state.locations.locationEditedSuccessfully;
export const locationPaycardSettingsSelector = (
  state: RootState,
): SaveLocationPaycardConfigType | undefined => state.locations.locationPaycardSettings.data;
export const locationPaycardSettingsPendingSelector = (state: RootState): boolean => (
  state.locations.locationPaycardSettings.pending
);
export const locationPaycardSettingsFailedSelector = (state: RootState): boolean => (
  state.locations.locationPaycardSettings.failed
);
export const locationPaycardSettingsFailedStatusCodeSelector = (state: RootState): number | undefined => (
  state.locations.locationPaycardSettings.failedStatusCode
);

export default locationsSlice.reducer;
