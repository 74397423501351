import { Block } from 'baseui/block';
import {
  ALIGNMENT,
  Cell,
  Grid,
} from 'baseui/layout-grid';
import {
  memo,
  useEffect,
  useState,
} from 'react';
import Loader from 'components/Loader';
import {
  HeadingMedium,
  HeadingXSmall,
} from 'baseui/typography';
import {
  useAppDispatch,
  useAppSelector,
} from 'store/hooks';
import { useTranslation } from 'react-i18next';
import {
  fetchOrganizationRepaymentRequests,
  processRepaymentRequests,
  repaymentsOrganizationRepaymentRequestsPendingListSelector,
  repaymentsOrganizationRepaymentRequestsSearchFromDateSelector,
  repaymentsOrganizationRepaymentRequestsSearchStatusSelector,
  repaymentsOrganizationRepaymentRequestsSearchToDateSelector,
  repaymentsOrganizationRepaymentRequestsSelectedOrganizationSelector,
  resetOrganizationRepaymentRequestsSearch,
  resetOrganizationRepaymentRequestsSelectedOrganization,
  setOrganizationRepaymentRequestsSearchFromDate,
  setOrganizationRepaymentRequestsSearchStatus,
  setOrganizationRepaymentRequestsSearchToDate,
  setOrganizationRepaymentRequestsSelectedOrganization,
  repaymentOrganizationRepaymentRequestsProcessFulfilledErrorsSelector,
  selectedRepaymentRequestsOptionChosenSelector,
  setSelectedRepaymentRequestsOptionChosen,
  resetSelectedRepaymentRequests,
  selectedRepaymentRequestsListSelector,
  repaymentsOrganizationRepaymentRequestsListSelector,
  repaymentOrganizationRepaymentRequestsProcessPendingSelector,
} from 'store/slices/repaymentsBankDetails';
import CellFormControl from 'components/CellFormControl';
import {
  OnChangeParams,
  SIZE,
  Select,
  Value,
} from 'baseui/select';
import {
  FetchRepaymentsRequestsType,
  RepaymentRequestStatus,
  RepaymentRequestType,
} from 'types/RepaymentsBankDetails';
import { useStyletron } from 'baseui';
import {
  Organization,
  fetchAllOrganizations,
  organizationsPendingListSelector,
  organizationsPendingSelector,
  organizationsSelector,
} from 'store/slices/organizations';
import { loggedOrganizationSelector } from 'store/slices/loggedOrganization';
import { DatePicker } from 'baseui/datepicker';
import { OptionalDateOrDateArrayType } from 'types/CommonTypes';
import { datePickerOverrides } from 'components/Form/AppDatePicker';
import moment from 'moment';
import {
  Button,
  KIND,
} from 'baseui/button';
import {
  NotificationType,
  setNotification,
} from 'store/slices/notification';
import { repaymentRequestsList } from 'test/data/repayments-bank-details';
import ConfirmModal from 'components/ConfirmModal/ConfirmModal';
import {
  ModalNames,
  modalsSelector,
  setModal,
} from 'store/slices/modals';
import hasAccess from 'utils/hasAccess';
import {
  AccessCheckType,
  AccessUnit,
} from 'components/Access/Access';
import { store } from 'store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRotate } from '@fortawesome/free-solid-svg-icons';
import {
  blockOverrides,
  contentTableBuilderContainerStyles,
} from '../TreasuryHelper';
import ACHDebitSectionTable from './Table/ACHDebitSectionTable';

export const LOADER_STYLE = {
  background: '#F6F6F6',
};

const datePickerOverridesExtended = (id: string) => ({
  ...datePickerOverrides,
  Input: {
    ...datePickerOverrides.Input,
    props: {
      onKeyDown: (event: any) => event.preventDefault(),
      overrides: {
        ...datePickerOverrides.Input.props.overrides,
      },
      id,
    },
  },
});

const ACHDebitSection = () => {
  const dispatch = useAppDispatch();
  const pending = useAppSelector(repaymentsOrganizationRepaymentRequestsPendingListSelector);
  const { t } = useTranslation(['common', 'treasury', 'dateFormats']);
  const [css, theme] = useStyletron();
  const repaymentStatus = useAppSelector(repaymentsOrganizationRepaymentRequestsSearchStatusSelector);
  const organizations = useAppSelector(organizationsSelector);
  const pendingOrganization = useAppSelector(organizationsPendingSelector);
  const dateFormat = t('dateFormats:standard-reverse');
  const datePickerPlaceholder = t('dateFormats:date-picker.placeholder');
  const pendingOrganizationsList = useAppSelector(organizationsPendingListSelector);
  const [organizationChanged, setOrganizationChanged] = useState(false);
  const [fromChanged, setFromChanged] = useState(false);
  const [toChanged, setToChanged] = useState(false);
  const [statusChanged, setStatusChanged] = useState(false);
  const isSingleRequestChecked = useAppSelector(selectedRepaymentRequestsOptionChosenSelector);
  const selectedRequests = useAppSelector(selectedRepaymentRequestsListSelector);
  const modals = useAppSelector(modalsSelector);
  const isConfirmModalOpen = !!modals?.find((item) => item.name === ModalNames.CONFIRM_MODAL)?.isOpen;
  const repaymentRequests = useAppSelector(repaymentsOrganizationRepaymentRequestsListSelector);
  const pendingProcessing = useAppSelector(repaymentOrganizationRepaymentRequestsProcessPendingSelector);
  const [isProcessingStatusesAvailable, setIsProcessingStatusesAvailable] = useState(false);

  const organizationOptions = organizations
    ?.map((org: Organization) => (
      {
        label: org.name,
        value: org.id,
      }))
    ?.sort((a, b) => a.label.localeCompare(b.label));

  const organization = useAppSelector(repaymentsOrganizationRepaymentRequestsSelectedOrganizationSelector);
  const loggedOrganization = useAppSelector(loggedOrganizationSelector);

  const fromDate = useAppSelector(repaymentsOrganizationRepaymentRequestsSearchFromDateSelector);
  const toDate = useAppSelector(repaymentsOrganizationRepaymentRequestsSearchToDateSelector);

  const handleOrganizationChange = ({
    value,
  }: OnChangeParams) => {
    setOrganizationChanged(true);
    dispatch(setOrganizationRepaymentRequestsSelectedOrganization(value));
    dispatch(resetOrganizationRepaymentRequestsSearch());
    dispatch(resetSelectedRepaymentRequests());
    dispatch(setSelectedRepaymentRequestsOptionChosen(false));
  };

  const handleFromDateChange = ({ date }: { date: OptionalDateOrDateArrayType }) => {
    dispatch(resetSelectedRepaymentRequests());
    if (date !== null || date !== undefined) {
      setFromChanged(true);
      if (date && !Array.isArray(date)) {
        dispatch(setOrganizationRepaymentRequestsSearchFromDate(date as Date));
      } else if (!date) {
        dispatch(setOrganizationRepaymentRequestsSearchFromDate(undefined));
      }
    }
  };

  const handleToDateChange = ({ date }: { date: OptionalDateOrDateArrayType }) => {
    dispatch(resetSelectedRepaymentRequests());
    if (date !== null || date !== undefined) {
      setToChanged(true);
      if (date && !Array.isArray(date)) {
        dispatch(setOrganizationRepaymentRequestsSearchToDate(date as Date));
      } else if (!date) {
        dispatch(setOrganizationRepaymentRequestsSearchToDate(undefined));
      }
    }
  };

  useEffect(() => {
    dispatch(resetSelectedRepaymentRequests());
  }, [isSingleRequestChecked]);

  const repaymentStatuses = [
    {
      id: 1,
      value: RepaymentRequestStatus.NEW,
      label: t(`treasury:achDebit.repaymentRequest.statuses.${RepaymentRequestStatus.NEW}`),
    },
    {
      id: 2,
      value: RepaymentRequestStatus.PENDING,
      label: t(`treasury:achDebit.repaymentRequest.statuses.${RepaymentRequestStatus.PENDING}`),
    },
    {
      id: 3,
      value: RepaymentRequestStatus.PROCESSED,
      label: t(`treasury:achDebit.repaymentRequest.statuses.${RepaymentRequestStatus.PROCESSED}`),
    },
    {
      id: 4,
      value: RepaymentRequestStatus.ACCEPTED_FOR_PROCESSING,
      label: t(`treasury:achDebit.repaymentRequest.statuses.${RepaymentRequestStatus.ACCEPTED_FOR_PROCESSING}`),
    },
    {
      id: 5,
      value: RepaymentRequestStatus.SEND_FOR_APPROVAL,
      label: t(`treasury:achDebit.repaymentRequest.statuses.${RepaymentRequestStatus.SEND_FOR_APPROVAL}`),
    },
    {
      id: 6,
      value: RepaymentRequestStatus.REJECTED_BY_BANK,
      label: t(`treasury:achDebit.repaymentRequest.statuses.${RepaymentRequestStatus.REJECTED_BY_BANK}`),
    },
  ];

  const setIsConfirmModalOpen = (
    isOpen: boolean,
  ) => {
    isConfirmModalOpen !== isOpen && dispatch(setModal({
      name: ModalNames.CONFIRM_MODAL,
      isOpen,
    }));
  };

  const handleConfirmModalClose = () => {
    setIsConfirmModalOpen(false);
  };

  const handleRepaymentRequestStatusesChange = ({
    value,
  }: OnChangeParams) => {
    setStatusChanged(true);
    dispatch(setOrganizationRepaymentRequestsSearchStatus(value));
    dispatch(resetSelectedRepaymentRequests());
  };

  const handleProcessRepaymentRequest = () => {
    organization.length > 0 && organization[0].value
    && dispatch(processRepaymentRequests({
      organizationID: organization[0].value,
      data: {
        repaymentRequests: selectedRequests.map((request) => request.id),
      },
    })).then((action) => {
      if (processRepaymentRequests.fulfilled.match(action)) {
        const updatedErrors = repaymentOrganizationRepaymentRequestsProcessFulfilledErrorsSelector(store.getState());
        dispatch(setNotification({
          type: updatedErrors && updatedErrors.length > 0 ? NotificationType.WARNING : NotificationType.SUCCESS,
          isOpen: true,
          autoHideDuration: 3000,
          title: undefined,
          titleKey: undefined,
          text: t('notifications:successRepaymentRequestInitiated.text'),
          textKey: undefined,
        }));
      }
      dispatch(resetOrganizationRepaymentRequestsSearch());
      setFromChanged(false);
      setToChanged(false);
      setStatusChanged(false);
    });
  };

  const handleOnRefresh = () => {
    dispatch(resetOrganizationRepaymentRequestsSearch());
    dispatch(fetchOrganizationRepaymentRequests({ organizationID: organization[0].value }));
  };

  useEffect(() => {
    if (organization && organization[0]?.value && organizationChanged) {
      const filter = new Map([
        ['organizationID', organization[0].value],
        ['status', repaymentStatus?.[0]?.value || ''],
        ['startDate', fromDate ? moment(fromDate).format(dateFormat) : ''],
        ['endDate', toDate ? moment(toDate).format(dateFormat) : ''],
      ]);
      (organizationChanged || fromChanged || toChanged || statusChanged)
      && dispatch(fetchOrganizationRepaymentRequests(Object.fromEntries(filter) as FetchRepaymentsRequestsType));
    }
  }, [repaymentStatus,
    fromDate,
    toDate,
    organization,
    organizationChanged,
    fromChanged,
    toChanged,
    statusChanged,
  ]);

  useEffect(() => {
    dispatch(fetchAllOrganizations({ pageSize: '1000' }));
    dispatch(setSelectedRepaymentRequestsOptionChosen(false));
    if (loggedOrganization && loggedOrganization.id) {
      dispatch(setOrganizationRepaymentRequestsSelectedOrganization([{ label: loggedOrganization.name, value: loggedOrganization.id }]));
      setOrganizationChanged(true);
    } else {
      dispatch(resetOrganizationRepaymentRequestsSelectedOrganization());
    }
    dispatch(resetOrganizationRepaymentRequestsSearch());
  }, []);

  useEffect(() => {
    setIsProcessingStatusesAvailable(repaymentRequests?.some((request: RepaymentRequestType) => request.status === RepaymentRequestStatus.NEW
      || request.status === RepaymentRequestStatus.REJECTED_BY_BANK));
  }, [repaymentRequests]);

  return (
    <Block
      overrides={blockOverrides}
    >
      <Grid
        align={ALIGNMENT.center}
        gridColumns={12}
      >
        <Cell span={12}>
          <Loader active={pending} />
          <HeadingXSmall margin={0}>{t('treasury:achDebit.repaymentsRequests.heading')}</HeadingXSmall>
        </Cell>
      </Grid>
      <Grid
        gridColumns={12}
      >
        <div className={css(contentTableBuilderContainerStyles)}>
          <Grid gridColumns={12} gridMargins={20}>
            <CellFormControl
              cellSpan={[12, 4, 2.5]}
              label={t('treasury:achDebit.filter.organization.label')}
            >
              <Select
                disabled={pendingOrganization || pendingOrganizationsList}
                isLoading={pendingOrganization || pendingOrganizationsList}
                size={SIZE.compact}
                id="treasury-ach-debit-organization-select"
                clearable
                placeholder={t('common:select')}
                type="select"
                options={organizationOptions}
                labelKey="label"
                valueKey="value"
                onChange={handleOrganizationChange}
                value={organization as Value}
                maxDropdownHeight="300px"
                overrides={{
                  ControlContainer: {
                    props: {
                      id: 'Treasury-ACH-Debit-Organization-select',
                      'data-testid': 'Treasury-ACH-Debit-Organization-select',
                    },
                  },
                }}
              />
            </CellFormControl>
          </Grid>
        </div>
      </Grid>
      {organization && organization?.length ? (
        <Grid
          gridColumns={12}
        >
          <div className={css(contentTableBuilderContainerStyles)}>
            <Grid gridColumns={12} gridMargins={20}>
              <CellFormControl
                cellSpan={[12, 3.5, 2]}
                label={t('treasury:achDebit.filter.status.label')}
              >
                <Select
                  disabled={!organization || !organization?.length || pending}
                  size={SIZE.compact}
                  id="repaymentRequest-status-select"
                  clearable
                  placeholder={t('treasury:achDebit.filter.searchByRepaymentRequestStatus.placeholder')}
                  type="select"
                  options={repaymentStatuses}
                  labelKey="label"
                  valueKey="value"
                  onChange={handleRepaymentRequestStatusesChange}
                  value={repaymentStatus as Value}
                  maxDropdownHeight="300px"
                  overrides={{
                    ControlContainer: {
                      props: {
                        id: 'Treasury-ACH-Debit-Search-by-Repayment-Request-Status-select',
                        'data-testid': 'Treasury-ACH-Debit-Search-by-Repayment-Request-Status-select',
                      },
                    },
                  }}
                />
              </CellFormControl>
              <CellFormControl
                cellSpan={[12, 3.5, 2]}
                label={t('treasury:achDebit.filter.from.label')}
              >
                <DatePicker
                  clearable
                  size={SIZE.compact}
                  formatDisplayValue={fromDate && ((date: any) => new Intl.DateTimeFormat('en-US', {
                    weekday: 'long',
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }).format(date))}
                  disabled={!organization || !organization?.length || pending}
                  formatString="yyyy/MM/dd"
                  value={fromDate}
                  onChange={handleFromDateChange}
                  overrides={datePickerOverridesExtended('treasury-achDebit-from-dateRange')}
                  placeholder={datePickerPlaceholder}
                  maxDate={toDate}
                />
              </CellFormControl>
              <CellFormControl
                cellSpan={[12, 3.5, 2]}
                label={t('treasury:achDebit.filter.to.label')}
              >
                <DatePicker
                  clearable
                  size={SIZE.compact}
                  formatDisplayValue={toDate && ((date: any) => new Intl.DateTimeFormat('en-US', {
                    weekday: 'long',
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }).format(date))}
                  minDate={fromDate}
                  disabled={!organization || !organization?.length || pending}
                  formatString="yyyy/MM/dd"
                  value={toDate}
                  onChange={handleToDateChange}
                  overrides={datePickerOverridesExtended('treasury-achDebit-to-dateRange')}
                  placeholder={datePickerPlaceholder}
                />
              </CellFormControl>
              <CellFormControl
                cellSpan={[2, 1.5, 1]}
                label="&nbsp;"
              >
                <Button
                  disabled={!organization || !organization?.length || pending || pendingProcessing}
                  onClick={handleOnRefresh}
                  size={SIZE.compact}
                >
                  <FontAwesomeIcon
                    icon={faRotate}
                  />
                </Button>
              </CellFormControl>
              <Cell skip={[0, 0, 1]} />
              <CellFormControl
                cellSpan={[12, 3, 3]}
                cellAlign={ALIGNMENT.end}
                formControlProps={{
                  overrides: {
                    ControlContainer: {
                      style: {
                        '@media screen and (min-width: 1136px)': {
                          display: 'inline-flex',
                          justifyItems: 'end',
                          justifyContent: 'flex-end',
                        },
                      },
                    },
                  },
                }}
              >
                <Button
                  kind={KIND.primary}
                  onClick={() => setIsConfirmModalOpen(true)}
                  disabled={!hasAccess(AccessCheckType.oneOf, [AccessUnit.TreasuryManager])
                    || (repaymentRequests && repaymentRequests.length === 0)
                    || (isSingleRequestChecked && selectedRequests.length === 0)
                    || pending
                    || (repaymentStatus && repaymentStatus.length > 0
                      && (repaymentStatus[0].value !== RepaymentRequestStatus.NEW
                        && repaymentStatus[0].value !== RepaymentRequestStatus.REJECTED_BY_BANK
                      )
                    )
                  || !isProcessingStatusesAvailable}
                  isLoading={pendingProcessing}
                  overrides={{
                    Root: {
                      style: {
                        height: '36px',
                      },
                      props: {
                        'data-testid': 'Treasury-ACH-Debit-executePayments-button',
                        id: 'Treasury-ACH-Debit-executePayments-button',
                      },
                    },
                    LoadingSpinner: {
                      style: {
                        borderRightColor: 'white',
                        borderTopColor: 'white',
                        borderLeftColor: 'white',
                      },
                    },
                  }}
                >
                  {t('treasury:achDebit.executePayment.button')}
                </Button>
              </CellFormControl>
            </Grid>
            <Grid gridColumns={12} gridMargins={20}>

              <Cell
                span={[12, 9, 9]}
                align={ALIGNMENT.start}
              >
                <Block
                  marginTop="12px"
                  marginBottom="12px"
                >
                  <Button
                    type="button"
                    disabled={pending}
                    kind={isSingleRequestChecked ? KIND.secondary : KIND.primary}
                    onClick={() => dispatch(setSelectedRepaymentRequestsOptionChosen(false))}
                    size={SIZE.compact}
                    overrides={{
                      BaseButton: {
                        props: {
                          id: 'Treasury-ACH-Debit-all-requests',
                          name: 'Treasury-ACH-Debit-all-requests',
                        },
                      },
                    }}
                  >
                    {t('treasury:achDebit.repaymentRequest.allRequests.button.label')}
                  </Button>
                  <Button
                    type="button"
                    disabled={pending || repaymentRequestsList.length === 0}
                    kind={isSingleRequestChecked ? KIND.primary : KIND.secondary}
                    size={SIZE.compact}
                    onClick={() => dispatch(setSelectedRepaymentRequestsOptionChosen(true))}
                    overrides={{
                      BaseButton: {
                        props: {
                          id: 'Treasury-ACH-Debit-selected-requests',
                          name: 'Treasury-ACH-Debit-selected-requests',
                        },
                      },
                    }}
                  >
                    {t('treasury:achDebit.repaymentRequest.selectedRequests.button.label')}
                  </Button>
                </Block>
              </Cell>
            </Grid>
            {!pending && (
            <Cell span={12}>
              <ACHDebitSectionTable />
            </Cell>
            )}
            <br />
          </div>
        </Grid>
      ) : null}
      <ConfirmModal
        onAction={handleProcessRepaymentRequest}
        title={t('treasury:achDebit.repaymentRequest.process.confirmationModal.title', {
          selectedRequestsCount: selectedRequests.length > 0
            ? selectedRequests.length : 'ALL',
        })}
        actionBtnText={t('common:confirm.ok')}
        onClose={handleConfirmModalClose}
      />
      {(!organization || !organization?.length) && (
        <Block
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          minHeight="500px"
        >
          <HeadingMedium
            color={theme.colors.contentInverseSecondary}
            overrides={{
              Block: {
                style: {
                  maxWidth: 'unset',
                  whiteSpace: 'normal',
                  wordWrap: 'break-word',
                  textAlign: 'center',
                },
              },
            }}
          >
            {t('treasury:achDebit.selectOrganizationMessage')}
          </HeadingMedium>
        </Block>
      )}
    </Block>
  );
};

export default memo(ACHDebitSection);
