import { useState, useEffect } from 'react';
import environment from 'environment';

export const useExposeSpecificFeatureFlag = (feature: string = '') => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  useEffect(() => {
    setIsVisible(Boolean(environment.exposeSpecificFeatures && environment.exposeSpecificFeatures.includes(feature)));
    return () => {
      setIsVisible(false);
    };
  }, []);
  return isVisible;
};

export default useExposeSpecificFeatureFlag;
