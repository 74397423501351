import {
  ChangeEvent,
  memo,
  useEffect,
  useRef,
  useState,
} from 'react';
import moment from 'moment';
import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import {
  Table,
  SIZE,
} from 'baseui/table-semantic';
import { Input } from 'baseui/input';
import {
  ALIGNMENT,
  Cell,
  Grid,
} from 'baseui/layout-grid';
import {
  Button,
  KIND,
} from 'baseui/button';
import { Card } from 'baseui/card';
import Loader from 'components/Loader';
import {
  MasterTransactionsValueType,
  TransactionsStatus,
  FetchMasterAccountTransactionsPropsType,
  TransactionsType,
  StatusItemType,
  TypesItemType,
} from 'types/MasterAccountTypes';
import PriceFormatter from 'utils/priceFormatter';
import { useTranslation } from 'react-i18next';
import {
  useAppDispatch,
  useAppSelector,
} from 'store/hooks';
import {
  ModalNames,
  modalsSelector,
  setModal,
} from 'store/slices/modals';
import checkIsModalOpen from 'utils/checkIsModalOpen';
import {
  fetchMasterAccountTransactionsOnLoadMore,
  masterAccountTransactionsMorePagesSelector,
  masterAccountTransactionsPendingListSelector,
  masterAccountTransactionsSelector,
  masterAccountTransactionsAreFetchedSelector,
  resetTransactions,
  fetchMasterAccountTransactions,
} from 'store/slices/transactions';
import CellFormControl from 'components/CellFormControl';
import { DatePicker } from 'baseui/datepicker';
import { datePickerOverrides } from 'components/Form/AppDatePicker';
import {
  Select,
  Value,
} from 'baseui/select';
import { containerStyles } from 'screens/CommonHelpers';
import CommonHeader from 'components/CommonHeader/CommonHeader';
import {
  InputCustomHTMLElement,
  OptionalDateOrDateArrayType,
} from 'types/CommonTypes';
import { emptyPlaceholder } from 'theme';
import { userAccessUnitsSelector } from 'store/slices/user';
import { AccessUnit } from 'components/Access/Access';
import MasterAccountTransactionsDetailsModal from './MasterAccountTransactionsDetailsModal';

let timer: any;

const MasterAccountTransactions = () => {
  const { t } = useTranslation(['treasury', 'dateFormats']);
  const [css] = useStyletron();
  const mountNodeRef = useRef(null);
  const startDateInputRef = useRef(null);
  const dispatch = useAppDispatch();
  const transactions = useAppSelector(masterAccountTransactionsSelector);
  const morePages = useAppSelector(masterAccountTransactionsMorePagesSelector);
  const pending = useAppSelector(masterAccountTransactionsPendingListSelector);
  const areFetched = useAppSelector(masterAccountTransactionsAreFetchedSelector);
  const modals = useAppSelector(modalsSelector);
  const userRoles = useAppSelector(userAccessUnitsSelector);
  const [transactionStatus, setTransactionStatus] = useState<Value>();
  const [transactionType, setTransactionType] = useState<Value>();
  const [clickedTransaction, setClickedTransaction] = useState<MasterTransactionsValueType | undefined>();
  const [transactionNumber, setTransactionNumber] = useState('');
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [searchDebounce, setSearchDebounce] = useState<string | null>('');
  const [hasPerformedSearchTransactionNumber, setHasPerformedSearchTransactionNumber] = useState<boolean>(false);

  const dateFormat = t('dateFormats:standard-with-time');
  const datePickerDateFormat = t('dateFormats:date-picker.standard');

  const filteredTransactions = [...transactions]?.sort((a, b) => (moment(b?.transactionDate).unix()) - (moment(a?.transactionDate).unix()));

  const listOfStatuses: StatusItemType[] = [
    { label: t('treasury:transactionsStatus.PENDING'), value: TransactionsStatus.PENDING },
    { label: t('treasury:transactionsStatus.COMPLETED'), value: TransactionsStatus.COMPLETED },
    { label: t('treasury:transactionsStatus.DECLINED'), value: TransactionsStatus.DECLINED },
    { label: t('treasury:transactionsStatus.EXPIRED'), value: TransactionsStatus.EXPIRED },
  ];

  const listOfTypes: TypesItemType[] = [
    { label: t('treasury:transactionsType.DEBIT'), value: TransactionsType.DEBIT },
    { label: t('treasury:transactionsType.CREDIT'), value: TransactionsType.CREDIT },
  ];

  const handleStartDateChange = ({ date }: { date: OptionalDateOrDateArrayType }) => {
    if (!date) {
      const startDateRef = startDateInputRef.current as any;
      setTimeout(() => {
        startDateRef.focusCalendar();
        startDateRef.close();
      }, 100);
    }
    if (date && !Array.isArray(date)) {
      setStartDate(date as Date);
      if (endDate && moment(date).isAfter(moment(endDate))) {
        setEndDate(undefined);
      }
    } else {
      setStartDate(undefined);
      setEndDate(undefined);
    }
  };

  const handleEndDateChange = ({ date }: { date: OptionalDateOrDateArrayType }) => {
    if (date && !Array.isArray(date)) {
      setEndDate(date as Date);
    } else {
      setEndDate(undefined);
    }
  };

  const fetchTransactions = (moreTransactions: boolean = false) => {
    const transactionStatusEnum = (transactionStatus && transactionStatus[0]?.value?.toString()) || '';
    const transactionTypeEnum = (transactionType && transactionType[0]?.value?.toString()) || '';
    const filterParams: FetchMasterAccountTransactionsPropsType = {
      // Subtraction/addition of one day due to defect D83955
      startDate: (startDate && moment(startDate).subtract(1, 'days').format('YYYY-MM-DD')) || '',
      endDate: (endDate && moment(endDate).add(1, 'days').format('YYYY-MM-DD')) || '',
      transactionStatus: transactionStatusEnum || '',
      transactionType: transactionTypeEnum || '',
      transactionNumber: hasPerformedSearchTransactionNumber ? transactionNumber : '',
    };

    if (moreTransactions === true) {
      dispatch(fetchMasterAccountTransactionsOnLoadMore(filterParams));
    } else {
      dispatch(fetchMasterAccountTransactions(filterParams));
    }
  };

  const handleChangeTransactionNumber = (e: ChangeEvent<InputCustomHTMLElement>) => {
    setTransactionNumber(e.target.value);
    setHasPerformedSearchTransactionNumber(true);
  };

  const handleLoadMoreClick = () => {
    fetchTransactions(true);
  };

  const handleTransactionDateClick = (transaction: MasterTransactionsValueType) => {
    setClickedTransaction(transaction);
    dispatch(setModal({
      name: ModalNames.TRANSACTION_HISTORY_DETAILS_MODAL,
      isOpen: true,
    }));
  };

  useEffect(() => {
    fetchTransactions(false);
  }, [startDate,
    endDate,
    transactionStatus,
    transactionType,
    searchDebounce]);

  useEffect(() => {
    if (timer) clearTimeout(timer);

    timer = setTimeout(() => {
      timer = undefined;
      setSearchDebounce(transactionNumber);
    }, 1000);
  }, [transactionNumber]);

  useEffect(() => () => {
    dispatch(resetTransactions());
  }, []);

  return (
    <div className={css(containerStyles)}>
      <CommonHeader
        title={`${t('transactionsHist')}`}
        backTo={userRoles.includes(AccessUnit.EWAReport) ? '/treasury/section/1' : '/treasury/section/0'}
      />

      <Loader active={pending && !areFetched} />

      <Block
        marginTop="24px"
        marginBottom="16px"
      >
        <Grid
          align={ALIGNMENT.center}
        >
          <Cell span={12}>
            <b>
              {t('common:searchBy')}
            </b>
          </Cell>

          <CellFormControl
            cellSpan={4}
            label={`${t('treasury:transactionStatus.label')}`}
          >
            <Select
              size={SIZE.compact}
              clearable
              id="select"
              overrides={{
                ControlContainer: {
                  props: {
                    'data-testid': 'select-master-transaction-status',
                    id: 'master-transaction-status-container',
                  },
                },
                Input: {
                  props: {
                    'data-testid': 'select-master-transaction-status-input',
                    id: 'master-transaction-status-input',
                  },
                },
              }}
              placeholder={t('treasury:transactionStatus.title')}
              type="select"
              options={listOfStatuses}
              labelKey="label"
              valueKey="value"
              onChange={({ value }) => setTransactionStatus(value)}
              value={transactionStatus as Value}
              maxDropdownHeight="300px"
              disabled={!!transactionNumber}
            />
          </CellFormControl>
          <CellFormControl
            cellSpan={4}
            label={`${t('treasury:transactionType.label')}`}
          >
            <Select
              size={SIZE.compact}
              clearable
              id="select"
              overrides={{
                ControlContainer: {
                  props: {
                    'data-testid': 'select-master-transaction-type',
                    id: 'master-transaction-type-container',
                  },
                },
                Input: {
                  props: {
                    'data-testid': 'select-master-transaction-type-input',
                    id: 'master-transaction-type-input',
                  },
                },
              }}
              placeholder={t('treasury:transactionType.title')}
              type="select"
              options={listOfTypes}
              labelKey="label"
              valueKey="value"
              onChange={({ value }) => setTransactionType(value)}
              value={transactionType as Value}
              maxDropdownHeight="300px"
              disabled={!!transactionNumber}
            />
          </CellFormControl>
          <CellFormControl
            cellSpan={4}
            label={`${t('treasury:transactionStartDate.label')}`}
          >
            <DatePicker
              size={SIZE.compact}
              clearable
              ref={startDateInputRef}
              mountNode={mountNodeRef?.current as any}
              placeholder={t('treasury:transactionStartDate.title')}
              formatString={datePickerDateFormat}
              value={startDate}
              onChange={handleStartDateChange}
              overrides={{
                ...datePickerOverrides,
                Popover: {
                  props: {
                    id: 'transactions-start-date-container',
                  },
                },
              }}
              disabled={!!transactionNumber}
            />
          </CellFormControl>

          <div id="mount-calendar-here" ref={mountNodeRef} />

          <CellFormControl
            cellSpan={4}
            label={`${t('treasury:transactionEndDate.label')}`}
          >
            <DatePicker
              clearable
              placeholder={t('treasury:transactionEndDate.title')}
              formatString={datePickerDateFormat}
              value={endDate}
              onChange={handleEndDateChange}
              minDate={startDate}
              disabled={!startDate || !!transactionNumber}
              overrides={{
                ...datePickerOverrides,
                Popover: {
                  props: {
                    id: 'transactions-end-date-container',
                  },
                },
              }}
            />
          </CellFormControl>

          <CellFormControl
            cellSpan={4}
            label={t('treasury:transactionNumber.title')}
          >
            <Input
              size={SIZE.compact}
              clearable
              clearOnEscape
              autoComplete="off"
              name="search"
              type="text"
              id="transaction-history-search"
              onChange={handleChangeTransactionNumber}
              value={transactionNumber}
              placeholder={t('treasury:transactionNumber.title')}
              disabled={(transactionStatus && transactionStatus?.length > 0 && transactionStatus !== undefined)
                || (transactionType && transactionType?.length > 0 && transactionType !== undefined)
                || !!startDate
                || !!endDate}
            />
          </CellFormControl>
        </Grid>
      </Block>

      {transactions?.length > 0 && areFetched && (morePages || filteredTransactions?.length > 0)
        ? (
          <>
            <Block>
              <Grid>
                <Cell span={12}>
                  <Table
                    overrides={{
                      TableBodyRow: {
                        style: {
                          ':nth-child(even)': {
                            background: '#F5F5F5',
                          },
                        },
                      },
                      TableBodyCell: {
                        style: {
                          verticalAlign: 'middle',
                        },
                      },
                    }}
                    columns={[
                      t('treasury:table.transactionDate.header'),
                      t('treasury:table.transactionID.header'),
                      t('treasury:table.ledgerID.header'),
                      t('treasury:table.subelementID.header'),
                      t('treasury:table.amount.header'),
                      t('treasury:table.type.header'),
                      t('treasury:table.origin.header'),
                      t('treasury:table.status.header'),
                    ]}
                    data={filteredTransactions?.map((transaction: MasterTransactionsValueType) => {
                      const {
                        ledgerId,
                        subElementId,
                        origin,
                        id: transactionId,
                        transactionDate,
                        status,
                        type,
                        amount,
                      } = transaction;
                      return [
                        <Button
                          overrides={{
                            Root: {
                              props: {
                                id: `TransactionHistory-transactionDate-btn-${transactionId}`,
                              },
                              style: {
                                paddingTop: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                paddingRight: 0,
                                backgroundColor: 'transparent',
                              },
                            },
                          }}
                          kind={KIND.tertiary}
                          size="compact"
                          onClick={() => handleTransactionDateClick(transaction)}
                        >
                          {moment(transactionDate).format(dateFormat)}
                        </Button>,
                        transactionId,
                        ledgerId || emptyPlaceholder,
                        subElementId || emptyPlaceholder,
                        PriceFormatter().format(amount),
                        type,
                        origin?.replace(/_/g, ' '),
                        status,
                      ];
                    })}
                    size={SIZE.compact}
                  />
                </Cell>
              </Grid>
            </Block>

            <Block margin="10px 0 20px" display="flex">
              <Button
                overrides={{
                  Root: {
                    style: {
                      marginTop: 'auto',
                      marginBottom: 'auto',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    },
                    props: {
                      id: 'transaction-more-pages-button',
                    },
                  },
                  LoadingSpinner: {
                    style: {
                      borderRightColor: 'white',
                      borderTopColor: 'white',
                      borderLeftColor: 'white',
                    },
                  },
                }}
                isLoading={areFetched && pending}
                disabled={!morePages || pending}
                onClick={handleLoadMoreClick}
              >
                {t('morePages')}
              </Button>
            </Block>
          </>
        ) : (
          <Block
            marginTop="8px"
            marginBottom="8px"
          >
            <Grid>
              <Cell span={12}>
                <Card>
                  <p>{t('notFound')}</p>
                </Card>
              </Cell>
            </Grid>
          </Block>
        )}
      {checkIsModalOpen(modals, ModalNames.TRANSACTION_HISTORY_DETAILS_MODAL) && <MasterAccountTransactionsDetailsModal transaction={clickedTransaction} />}
    </div>
  );
};

export default memo(MasterAccountTransactions);
