import { memo, ReactNode } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
  SIZE as MODALSIZE,
  ROLE,
} from 'baseui/modal';
import { KIND, SIZE } from 'baseui/button';
import { Layer } from 'baseui/layer';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { ModalNames, modalsSelector, setModal } from 'store/slices/modals';
import {
  Cell,
  Grid,
} from 'baseui/layout-grid';
import { borderRadius, colors } from 'theme';
import { Block } from 'baseui/block';
import ConfirmModal from 'components/ConfirmModal/ConfirmModal';

export type OrganizationModalPropsType = {
  modal: ModalNames
  title?: string | ReactNode
  cancelBtnText?: string
  actionBtnText?: string
  children: ReactNode
  onClose?: () => Promise<void> | void
  onAction?: () => Promise<void> | void
  isActionDisabled?: boolean
  isCloseDisabled?: boolean
  isActionLoading?: boolean
  confirm?: boolean
  confirmTitle?: string,
  confirmBody?: string,
  confirmActionBtnText?: string,
  minWidth?: string
  maxWidth?: string
  modalWidth?: string | string[]
}

const AppModal = ({
  modal,
  title,
  cancelBtnText,
  actionBtnText,
  children,
  onClose,
  onAction,
  isActionDisabled,
  isActionLoading,
  isCloseDisabled,
  confirm,
  confirmTitle,
  confirmBody,
  confirmActionBtnText,
  modalWidth = ['90vw', '90vw', '80vw', '60vw'],
  minWidth = '60%',
  maxWidth = '100%',
}: OrganizationModalPropsType) => {
  const dispatch = useAppDispatch();
  const modals = useAppSelector(modalsSelector);
  const selectedModal = modals?.find((item) => item.name === modal);
  const isModalOpen = !!selectedModal?.isOpen;
  const isConfirmModalOpen = !!modals?.find((item) => item.name === ModalNames.CONFIRM_MODAL)?.isOpen;

  const setIsModalOpen = (
    isOpen: boolean,
  ) => {
    isModalOpen !== isOpen && dispatch(setModal({
      name: modal,
      isOpen,
    }));
  };

  const setIsConfirmModalOpen = (
    isOpen: boolean,
  ) => {
    isConfirmModalOpen !== isOpen && dispatch(setModal({
      name: ModalNames.CONFIRM_MODAL,
      isOpen,
    }));
  };

  const handleModalCloseBtnClick = () => {
    setIsModalOpen(false);
    onClose && onClose();
  };

  const handleModalActionBtnClick = () => {
    onAction && onAction();
  };

  const handleConfirmActionClick = () => {
    if (confirm) {
      setIsConfirmModalOpen(true);
    } else {
      handleModalActionBtnClick();
    }
  };

  return (
    <Layer index={400}>
      <Modal
        animate
        closeable
        onClose={handleModalCloseBtnClick}
        isOpen={isModalOpen}
        autoFocus={false}
        size={MODALSIZE.auto}
        role={ROLE.dialog}
        overrides={{
          DialogContainer: {
            props: {
              id: 'Modal-DialogContainer',
            },
          },
          Dialog: {
            style: {
              minWidth,
              maxWidth,
              overflow: 'auto',
              borderBottomLeftRadius: borderRadius,
              borderBottomRightRadius: borderRadius,
              borderTopLeftRadius: borderRadius,
              borderTopRightRadius: borderRadius,
            },
          },
          Close: {
            props: {
              id: 'Modal-close',
            },
            style: {
              margin: '18px 28px',
              width: '24px',
              height: '24px',
              background: colors.primary,
              borderBottomLeftRadius: borderRadius,
              borderBottomRightRadius: borderRadius,
              borderTopLeftRadius: borderRadius,
              borderTopRightRadius: borderRadius,
              color: '#fff',
            },
          },
        }}
      >
        <Block width={modalWidth}>
          <ModalHeader>
            <Grid
              gridColumns={12}
              gridMargins={16}
            >
              <Cell
                span={11.5}
              >
                <div className="ellipsis">
                  {title}
                </div>
              </Cell>
            </Grid>
          </ModalHeader>

          <ModalBody>{children}</ModalBody>

          <ModalFooter>
            <Grid
              gridColumns={12}
              gridMargins={16}
            >
              <Cell
                span={12}
              >
                <Block
                  marginBottom="16px"
                  display="flex"
                  justifyContent="flex-end"
                >
                  {cancelBtnText && (
                    <ModalButton
                      size={SIZE.compact}
                      type="button"
                      disabled={isCloseDisabled}
                      kind={KIND.secondary}
                      onClick={handleModalCloseBtnClick}
                      overrides={{
                        BaseButton: {
                          props: {
                            id: 'Modal-footer-close',
                          },
                        },
                      }}
                    >
                      {cancelBtnText}
                    </ModalButton>
                  )}

                  {actionBtnText && (
                    <ModalButton
                      size={SIZE.compact}
                      disabled={isActionDisabled}
                      kind={KIND.primary}
                      isLoading={isActionLoading}
                      type="button"
                      onClick={handleConfirmActionClick}
                      overrides={{
                        BaseButton: {
                          props: {
                            id: 'Modal-footer-action',
                          },
                        },
                      }}
                    >
                      {actionBtnText}
                    </ModalButton>
                  )}
                </Block>
              </Cell>
            </Grid>
          </ModalFooter>
        </Block>
      </Modal>

      <ConfirmModal
        onAction={handleModalActionBtnClick}
        title={confirmTitle}
        actionBtnText={confirmActionBtnText}
      >
        {confirmBody}
      </ConfirmModal>
    </Layer>
  );
};

export default memo(AppModal);
