import * as Yup from 'yup';
import messages from './messages';
import { commonPositiveAmountValidationSchema } from './commonSchema';

export const employeeDetailsValidationSchema = Yup.object().shape({
  firstName: Yup
    .string()
    .required(messages?.required)
    .matches(/^[a-zA-Z -.,']+$/, messages?.namePaycard)
    .max(30, messages?.maxLength),
  lastName: Yup
    .string()
    .required(messages?.required)
    .matches(/^[a-zA-Z -.,']+$/, messages?.namePaycard)
    .max(30, messages?.maxLength),
  mobileNumber: Yup
    .string()
    .required(messages?.required)
    .matches(/^\d{3}-\d{3}-\d{4}$/, messages?.mobilePhone),
  address: Yup
    .string()
    .required(messages?.required)
    .max(50, messages?.maxLength)
    .matches(/^[A-Za-z0-9 %#@\\^\\&№,.!'-]*$/, messages.address),
  unit: Yup
    .string()
    .max(30, messages?.maxLength)
    .matches(/^[A-Za-z0-9 #№,.]*$/, messages.addressUnit),
  city: Yup
    .string()
    .required(messages?.required)
    .max(50, messages?.maxLength)
    .min(3, messages?.minLength)
    .matches(/^[A-Za-z '-]*$/, messages?.city),
  state: Yup.array().of(Yup.object().shape(
    {
      value: Yup.string().max(2, messages?.maxLength).required(messages?.required),
      label: Yup.string().max(2, messages?.maxLength),
    },
  ))
    .required(messages?.required),
  zip: Yup
    .string()
    .required(messages?.required)
    .max(12, messages?.maxLength)
    .matches(/^\d{5}(-\d{4})?$/, messages?.zipCode),
  ssn: Yup
    .string()
    .required(messages?.required)
    .matches(/^\d{3}-\d{2}-\d{4}$/, messages?.ssn),
  birthDate: Yup
    .date()
    .typeError(messages?.dateStandard)
    .required(messages?.required),
  payrollId: Yup
    .string()
    .required(messages?.required)
    .max(123, messages?.maxLength)
    .matches(/^[A-Za-z0-9_-]*$/, messages?.payrollId),
});

export const employeePaymentDetailsValidationSchema = Yup.object().shape({
  proxyNumber: Yup
    .string()
    .required(messages?.required)
    .matches(/^\d{13}$/, messages?.proxyNumber)
    .max(13, messages?.maxLength),
  funds: commonPositiveAmountValidationSchema(),
});
