import { store } from 'store';

const state = store.getState();
const { application } = state || {};
const { priceScale } = application || {};

export const scalePrice = (
  amount: number,
  scale: number = priceScale,
) => amount * (10 ** scale);

export const unScalePrice = (
  amount: number,
  scale: number = priceScale,
) => amount / (10 ** scale);
