import { useStyletron } from 'baseui';
import { WorkerListItemPropsType } from 'types/WorkerTypes';
import {
  colors,
  emptyPlaceholder,
} from 'theme';
import { useTranslation } from 'react-i18next';
import {
  Button,
  KIND,
  SIZE,
} from 'baseui/button';
import Access,
{ AccessUnit } from 'components/Access/Access';
import { useHistory } from 'react-router-dom';
import { setPrevPage } from 'store/slices/application';
import {
  useAppDispatch,
  useAppSelector,
} from 'store/hooks';
import {
  setWorkerProfileAttributesTrusted,
  workerProfileAttributesTrustedPendingSelector,
} from 'store/slices/workers';
import { useState } from 'react';
import {
  PLACEMENT,
  Popover,
} from 'baseui/popover';
import { popoverExportOverrides } from 'screens/TipManagement/TipManagementHelper';
import { Overflow } from 'baseui/icon';
import {
  ALIGNMENT,
  Cell,
  Grid,
} from 'baseui/layout-grid';
import {
  HeadingXSmall,
  LabelMedium,
  ParagraphMedium,
} from 'baseui/typography';
import {
  Tag,
  VARIANT,
} from 'baseui/tag';
import {
  NotificationType,
  setNotification,
} from 'store/slices/notification';

const listStyles = {
  padding: '0px',
};

const listItemStyles = {
  background: '#FFFFFF',
  'list-style': 'none',
  padding: '8px',
};

const listItemNameAndStatusesWithMenu = {
  display: 'flex',
  'flex-direction': 'row',
  'justify-content': 'space-between',
  margin: '8px 8px 0px 0px',
};

const listItemNameAndStatuses = {
  display: 'flex',
  'flex-direction': 'row',
  'justify-content': 'space-between',
  margin: '8px 8px 0px 0px',
  '@media screen and (max-width: 520px)': {
    'flex-direction': 'column',
    alignItems: 'flex-start',
  },
};

/**
 * WorkerListItem component
 *
 * @param worker worker data
 * @param setIsEmploymentsModalOpen if WORKER_EMPLOYMENTS_MODAL is open
 * @param setIsLinkedAccountsModalOpen if WORKER_LINKED_ACCOUNTS_MODAL is open
 * @param setIsShiftsModalOpen if WORKER_UNIVERSAL_CALENDAR_SHIFTS_MODAL is open
 */

const WorkerListItem = ({
  worker,
  setIsEmploymentsModalOpen,
  setIsLinkedAccountsModalOpen,
  setIsShiftsModalOpen,
}: WorkerListItemPropsType) => {
  const [css] = useStyletron();
  const theme = useStyletron()[1];
  const { t } = useTranslation(['common', 'workers', 'employees']);
  const setTrustUserPending = useAppSelector(workerProfileAttributesTrustedPendingSelector);
  const [clickedWorkerID, setClickedWorkerID] = useState<string>();
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const [isTrustedActionClicked, setIsTrustedActionClicked] = useState<boolean>(false);
  const [isButtonChangeTrustDisabled, setIsButtonChangeTrustDisabled] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const history = useHistory();

  const visitEmploymentsList = () => {
    dispatch(setPrevPage('/workers'));
    setIsEmploymentsModalOpen(true, String(worker.id));
  };

  const visitTransactionsHistory = () => {
    dispatch(setPrevPage('/workers'));
    history.push(`/treasury/transaction-history/${worker?.id}`);
  };

  const handleTrustUserAction = (trusted: boolean) => {
    if (worker.id) {
      setClickedWorkerID(worker.id);
      dispatch(setWorkerProfileAttributesTrusted({
        workerID: worker.id,
        data: {
          trusted,
        },
      })).then(() => {
        setClickedWorkerID('');
        setIsButtonChangeTrustDisabled(true);
      });
      dispatch(setNotification({
        type: NotificationType.INFO,
        isOpen: true,
        autoHideDuration: 1500,
        title: undefined,
        titleKey: undefined,
        text: t('notifications:action.inProgress'),
        textKey: undefined,
      }));
    }
  };

  return (
    <ul className={css(listStyles)}>
      <li className={css(listItemStyles)}>
        <div className={css(listItemNameAndStatusesWithMenu)}>
          <div className={css(listItemNameAndStatuses)}>
            <HeadingXSmall
              overrides={{
                Block: {
                  style: {
                    marginTop: '4px',
                    marginLeft: '16px',
                    marginRight: '8px',
                    marginBottom: '0px',
                  },
                },
              }}
            >
              {`${worker?.firstName} ${worker?.lastName}`}
            </HeadingXSmall>
            <Tag
              closeable={false}
              kind={worker.knownSource ? 'positive' : 'warning'}
              variant={VARIANT.solid}
              overrides={{
                Root: {
                  style: {
                    marginLeft: '16px',
                    maxWidth: '100%',
                  },
                },
                Text: {
                  style: {
                    maxWidth: 'unset',
                  },
                },
              }}
            >
              {worker.knownSource ? t('workers:workers.trusted') : t('workers:workers.untrusted') }
            </Tag>
          </div>
          <Access oneOf={[AccessUnit.EWAManager]}>
            <div>
              <Button
                onClick={() => setIsPopoverOpen(!isPopoverOpen)}
                kind={KIND.secondary}
                overrides={{
                  Root: {
                    props: {
                      id: `Workers-${worker.id}-actions-btn`,
                      'data-testid': `Workers-${worker.id}-actions-btn`,
                    },
                  },
                }}
              >
                <Overflow />
              </Button>
              <Popover
                isOpen={isPopoverOpen}
                onClickOutside={() => setIsPopoverOpen(false)}
                placement={PLACEMENT.bottomRight}
                content={() => (
                  <div className={css(popoverExportOverrides)}>
                    <Button
                      onClick={() => { visitEmploymentsList(); setIsPopoverOpen(false); }}
                      kind={KIND.tertiary}
                      size={SIZE.compact}
                      overrides={{
                        Root: {
                          style: {
                            color: '#545454',
                          },
                          props: {
                            'data-testid': `Workers-${worker.id}-employments-btn`,
                            id: `Workers-${worker.id}-employments-btn`,
                          },
                        },
                      }}
                    >
                      {t('workers:employmentsButton')}
                    </Button>
                    <Access oneOf={[AccessUnit.FBOManager, AccessUnit.FBOReader]}>
                      <Button
                        onClick={() => { visitTransactionsHistory(); setIsPopoverOpen(false); }}
                        kind={KIND.tertiary}
                        size={SIZE.compact}
                        overrides={{
                          Root: {
                            style: {
                              color: '#545454',
                            },
                            props: {
                              'data-testid': `Workers-${worker.id}-fboAccount-btn`,
                              id: `Workers-${worker.id}-fboAccount-btn`,
                            },
                          },
                        }}
                      >
                        {t('workers:viewTransactions')}
                      </Button>
                      <Button
                        onClick={() => { setIsLinkedAccountsModalOpen(true, String(worker.id)); setIsPopoverOpen(false); }}
                        kind={KIND.tertiary}
                        size={SIZE.compact}
                        overrides={{
                          Root: {
                            style: {
                              color: '#545454',
                            },
                            props: {
                              'data-testid': `Workers-${worker.id}-linkedAccounts-btn`,
                              id: `Workers-${worker.id}-linkedAccounts-btn`,
                            },
                          },
                        }}
                      >
                        {t('workers:linkedAccounts')}
                      </Button>
                    </Access>
                    {worker.knownSource ? (
                      <Button
                        onClick={() => { handleTrustUserAction(false); setIsTrustedActionClicked(false); setIsPopoverOpen(false); }}
                        isLoading={setTrustUserPending && !isTrustedActionClicked && clickedWorkerID === worker.id}
                        kind={KIND.tertiary}
                        size={SIZE.compact}
                        disabled={isButtonChangeTrustDisabled}
                        overrides={{
                          Root: {
                            style: {
                              color: '#545454',
                            },
                            props: {
                              'data-testid': `Workers-${worker.id}-markAsNOTTrusted-btn`,
                              id: `Workers-${worker.id}-markAsNOTTrusted-btn`,
                            },
                          },
                          LoadingSpinner: {
                            style: {
                              borderRightColor: colors.primary,
                              borderTopColor: colors.primary,
                              borderLeftColor: colors.primary,
                            },
                          },
                        }}
                      >
                        {t('workers:untrustUserButton')}
                      </Button>
                    ) : (
                      <Button
                        onClick={() => { handleTrustUserAction(true); setIsTrustedActionClicked(true); setIsPopoverOpen(false); }}
                        isLoading={setTrustUserPending && isTrustedActionClicked && clickedWorkerID === worker.id}
                        kind={KIND.tertiary}
                        size={SIZE.compact}
                        disabled={isButtonChangeTrustDisabled}
                        overrides={{
                          Root: {
                            style: {
                              color: '#545454',
                            },
                            props: {
                              'data-testid': `Workers-${worker.id}-markAsTrusted-btn`,
                              id: `Workers-${worker.id}-markAsTrusted-btn`,
                            },
                          },
                          LoadingSpinner: {
                            style: {
                              borderRightColor: colors.primary,
                              borderTopColor: colors.primary,
                              borderLeftColor: colors.primary,
                            },
                          },
                        }}
                      >
                        {t('workers:trustUserButton')}
                      </Button>
                    )}
                    <Button
                      onClick={() => { setIsShiftsModalOpen(true, String(worker.id)); setIsPopoverOpen(false); }}
                      overrides={{
                        Root: {
                          style: {
                            color: '#545454',
                          },
                          props: {
                            'data-testid': `Workers-${worker.id}-shifts-btn`,
                            id: `Workers-${worker.id}-shifts-btn`,
                          },
                        },
                      }}
                      kind={KIND.tertiary}
                      size={SIZE.compact}
                    >
                      {t('workers:shiftsButton')}
                    </Button>
                  </div>
                )}
              >
                <div />
              </Popover>
            </div>
          </Access>
        </div>
        <Grid
          gridColumns={12}
          gridMargins={18}
        >
          <Cell
            span={[12, 6, 4]}
            align={ALIGNMENT.end}
          >
            <LabelMedium display="inline-flex" color={theme.colors.contentSecondary}>
              {t('workers:id')}
              : &nbsp;
            </LabelMedium>
            <ParagraphMedium display="inline-flex">
              {worker.id || emptyPlaceholder}
            </ParagraphMedium>
          </Cell>

          <Cell
            span={[12, 4, 2]}
            align={ALIGNMENT.end}
          >
            <LabelMedium display="inline-flex" color={theme.colors.contentSecondary}>
              {t('workers:username')}
              : &nbsp;
            </LabelMedium>
            <ParagraphMedium display="inline-flex">
              {worker.username || emptyPlaceholder}
            </ParagraphMedium>
          </Cell>

          <Cell
            span={[12, 4, 2.5]}
            align={ALIGNMENT.end}
          >
            <LabelMedium display="inline-flex" color={theme.colors.contentSecondary}>
              {t('employees:mobileNumber')}
              : &nbsp;
            </LabelMedium>
            <ParagraphMedium display="inline-flex">
              {worker.mobilePhone || emptyPlaceholder}
            </ParagraphMedium>
          </Cell>

          <Cell
            span={[12, 4, 3]}
            align={ALIGNMENT.end}
          >
            <LabelMedium display="inline-flex" color={theme.colors.contentSecondary}>
              {t('workers:email')}
              : &nbsp;
            </LabelMedium>
            <ParagraphMedium display="inline-flex">
              {worker.email || emptyPlaceholder}
            </ParagraphMedium>
          </Cell>
          {worker.fuegoCardIssued && (
          <Cell
            span={[12, 4, 1.5]}
          >
            <Tag
              closeable={false}
              variant={VARIANT.solid}
              kind={KIND.primary}
              overrides={{
                Text: {
                  style: {
                    maxWidth: 'unset',
                  },
                },
              }}
            >
              {t('workers:workers.fuegoCardHolder.visa')}
            </Tag>
          </Cell>
          )}
          {worker.fuegoPaycardIssued && (
          <Cell
            span={[12, 4, 1.5]}
          >
            <Tag
              closeable={false}
              variant={VARIANT.outlined}
              kind={KIND.primary}
              overrides={{
                Text: {
                  style: {
                    maxWidth: 'unset',
                  },
                },
              }}
            >
              {t('workers:workers.fuegoCardHolder.paycard')}
            </Tag>
          </Cell>
          )}

        </Grid>
      </li>
    </ul>
  );
};

export default WorkerListItem;
