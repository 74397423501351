import moment from 'moment';
import {
  SaveOrganizationRequestType,
  ProfileValuesType,
  DefaultConfigResponseType,
  OrganizationConfigResponseType,
  OrganizationResponseType,
  OrganizationIDType,
  SaveOrganizationConfigsRequestType,
  ConfigType,
  DefaultConfigFormType,
  EWASettingsValuesType,
  BatchDeliveryMethods,
  TPOSettingsValuesType,
  DirectDepositSettingsValuesType,
  TipSourceConfigurationValuesType,
  BankAccountValuesType,
  BankAccountValuesParamsType,
} from 'types/OrganizationTypes';
import {
  SubElementEWATypeEnum,
  SubElementPaymentMethodEnum,
  ZendeskTicketRequestType,
  ZendeskTicketResponseType,
  ZendeskTicketValuesType,
} from 'types/EmployeeTypes';
import {
  scalePrice,
  unScalePrice,
} from 'utils/priceScale';
import { SetOrganizationTPOSettingsRequest } from 'types/TPOTypes';
import { SetOrganizationDirectDepositSettingsRequest } from 'types/DirectDepositTypes';
import {
  OrganizationPaycardSettingsFormParamsType,
  OrganizationPaycardSettingsParamsType,
  SaveOrganizationPaycardSettingsFormParamsType,
} from 'types/PaycardManagementTypes';

export type OrganizationFormConfig = {
  programStatus?: { value: string }[]
  organizationTimezone?: string
  businessDayStartTime?: Date
  payrollPeriodStartDay?: { value: string }[]
  paydayOffset?: { value: number }[]
  payrollCutoffTime?: Date
  ewaCutoffTime?: Date
  autoClockOutTime?: Date
  excludeAutoClockedOutShifts?: boolean
  ewaIncludeCCTips?: boolean
  ewaInclude3rdPartyTips?: boolean
  ewaIncludeServiceCharges?: boolean
  ewaIncludeDeclaredTips?: boolean
  ewaIncludeCashTips?: boolean
  ewaIncludeTipsPool?: boolean
  ewaSubtractIncludedCCTips?: boolean
  ewaSubtractIncluded3rdPartyTips?: boolean
  ewaSubtractIncludedServiceCharges?: boolean
  ewaSubtractIncludedDeclaredTips?: boolean
  ewaSubtractIncludedCashTips?: boolean
  ewaSubtractIncludedTipsPool?: boolean
  excludeTippedShifts?: boolean
  maxOfferTotal?: number
  maxShiftLength?: number
  offerAvailableTime?: Date
  offerPercentPerEmployee?: number
  jobCodeExclusions?: boolean
  jobCodeExclusionsValue?: string,
  deductionsCsvFormat?: { value: string }[]
  employmentDataSource?: { value: string }[]
  tnaDataSource?: { value: string }[]
  tnaDataImport?: { value: string }[]
  offerCalculationMode?: { value: string }[]
  automaticBatchGeneration?: boolean
  batchDeliveryManual: boolean
  batchDeliveryEmail: boolean
  organizationBatchDeliveryEmailAddresses?: string | null
  ewaEnabled: boolean
  tpoEnabled: boolean,
  tpoPercent: number,
  tpoDailyPayoutSchedule: [{ value: string }],
  tpoDailyPayoutTime: Date,
  tpoIncludeCCTips: boolean,
  tpoIncludeServiceCharges: boolean,
  tpoInclude3rdPartyTips: boolean,
  tpoIncludeDeclaredTips: boolean,
  tpoIncludeCashTips?: boolean,
  tpoIncludeTipsPool?: boolean,
  tpoSubtractIncludedCCTips: boolean,
  tpoSubtractIncludedServiceCharges: boolean,
  tpoSubtractIncluded3rdPartyTips: boolean,
  tpoSubtractIncludedDeclaredTips: boolean,
  tpoSubtractIncludedCashTips?: boolean,
  tpoSubtractIncludedTipsPool?: boolean,
  tpoExcludeNewUserFromDate: Date,
  tpoFeesPaidByEmployer: boolean,
  tpoFeeEmployeePerPeriod: boolean,
  tpoFeeEmployeePerPeriodValue: number,
  tpoFuegoCardPaymentEnabled: boolean,
  tpoFuegoCardPaymentValue: number,
  tpoAchPaymentFeeEnabled: boolean,
  tpoAchPaymentFeeValue: number,
  tpoSameDayAchPaymentFeeEnabled: boolean,
  tpoSameDayAchPaymentFeeValue: number,
  tpoPushToCardPaymentFeeEnabled: boolean,
  tpoPushToCardPaymentFeeValue: number,
  deductionShortfallSystem?: { value: string }[]
  estimatedPay: boolean,
  directDepositServiceType: string,
  offerProRataMethod: { value: string }[],
  hourlyPaidEmployeesEnable: boolean,
  salariedEmployeesEnable: boolean,
  regularlyPaidEmployeesEnable: boolean,
  ewaMaxHourlyRateTimecard?: number,
  ewaMaxHourlyRateSalariedEmployees?: number,
}

export const defaultConfigMapper = (
  data: DefaultConfigResponseType,
): DefaultConfigFormType => {
  const {
    // ACH_PAYMENT_FEE,
    AUTO_CLOCK_OUT_TIME,
    // BI_WEEKLY_DRAW_FEE_LIMIT,
    // CORPORATE_TIMEZONE,
    // BUSINESS_DAY_START_TIME,
    // CURRENCY,
    // DAILY_DRAW_FEE,
    // DAILY_DRAW_FEE_LIMIT,
    // EMPLOYER_FEE_LIMIT,
    // FEE_CAP,
    // FUEGO_CARD_PAYMENT_FEE,
    INCLUDE_AUTO_CLOCKED_OUT_SHIFTS,
    INCLUDE_CC_TIPS,
    INCLUDE_3RD_PARTY_TIPS,
    INCLUDE_SERVICE_CHARGES,
    INCLUDE_DECLARED_TIPS,
    INCLUDE_CASH_TIPS,
    INCLUDE_TIPS_POOL,
    INCLUDE_TIPPED_SHIFTS,
    SUBTRACT_INCLUDED_CC_TIPS,
    SUBTRACT_INCLUDED_SERVICE_CHARGES,
    SUBTRACT_INCLUDED_3RD_PARTY_TIPS,
    SUBTRACT_INCLUDED_DECLARED_TIPS,
    SUBTRACT_INCLUDED_CASH_TIPS,
    SUBTRACT_INCLUDED_TIPS_POOL,
    MAX_OFFER_TOTAL,
    MAX_SHIFT_LENGTH,
    OFFER_AVAILABLE_TIME,
    OFFER_PERCENT,
    // PAYDAY_OFFSET,
    // PAYROLL_CUTOFF_TIME,
    PAYROLL_PERIOD_START_DAY,
    // PROGRAM_STATUS
    // PUSH_TO_CARD_PAYMENT_FEE,
    // SAME_DAY_ACH_PAYMENT_FEE,
    // SCALE,
    // SQUIRREL_CARD_PAYMENT_FEE,
    // WEEKLY_DRAW_FEE_LIMIT,
    EWA_ENABLED,
    TPO_ENABLED,
    TPO_PERCENT,
    TPO_INCLUDE_CC_TIPS,
    TPO_INCLUDE_3RD_PARTY_TIPS,
    TPO_INCLUDE_SERVICE_CHARGES,
    TPO_INCLUDE_DECLARED_TIPS,
    TPO_INCLUDE_CASH_TIPS,
    TPO_INCLUDE_TIPS_POOL,
    TPO_SUBTRACT_INCLUDED_CC_TIPS,
    TPO_SUBTRACT_INCLUDED_3RD_PARTY_TIPS,
    TPO_SUBTRACT_INCLUDED_SERVICE_CHARGES,
    TPO_SUBTRACT_INCLUDED_DECLARED_TIPS,
    TPO_SUBTRACT_INCLUDED_CASH_TIPS,
    TPO_SUBTRACT_INCLUDED_TIPS_POOL,
    EWA_MAX_HOURLY_RATE_TIMECARD,
    EWA_MAX_HOURLY_RATE_SALARIED_EMPLOYEE,
    TPO_MAX_EARNINGS_PER_BUSINESS_DAY,
    TPO_MAX_EARNINGS_PER_PERIOD,
  } = data || {};

  return {
    autoClockOutTime: moment(AUTO_CLOCK_OUT_TIME, 'HH:mm:ss').toDate(),
    excludeAutoClockedOutShifts: !INCLUDE_AUTO_CLOCKED_OUT_SHIFTS,
    ewaIncludeCCTips: !!INCLUDE_CC_TIPS,
    ewaInclude3rdPartyTips: !!INCLUDE_3RD_PARTY_TIPS,
    ewaIncludeServiceCharges: !!INCLUDE_SERVICE_CHARGES,
    ewaIncludeDeclaredTips: !!INCLUDE_DECLARED_TIPS,
    ewaIncludeCashTips: !!INCLUDE_CASH_TIPS,
    ewaIncludeTipsPool: !!INCLUDE_TIPS_POOL,
    ewaSubtractIncludedCCTips: !!SUBTRACT_INCLUDED_CC_TIPS,
    ewaSubtractIncluded3rdPartyTips: !!SUBTRACT_INCLUDED_3RD_PARTY_TIPS,
    ewaSubtractIncludedServiceCharges: !!SUBTRACT_INCLUDED_SERVICE_CHARGES,
    ewaSubtractIncludedDeclaredTips: !!SUBTRACT_INCLUDED_DECLARED_TIPS,
    ewaSubtractIncludedCashTips: !!SUBTRACT_INCLUDED_CASH_TIPS,
    ewaSubtractIncludedTipsPool: !!SUBTRACT_INCLUDED_TIPS_POOL,
    ewaMaxHourlyRateTimecard: unScalePrice(Number(EWA_MAX_HOURLY_RATE_TIMECARD)),
    ewaMaxHourlyRateSalariedEmployees: unScalePrice(Number(EWA_MAX_HOURLY_RATE_SALARIED_EMPLOYEE)),
    excludeTippedShifts: !INCLUDE_TIPPED_SHIFTS,
    maxOfferTotal: unScalePrice(Number(MAX_OFFER_TOTAL)),
    maxShiftLength: Number(MAX_SHIFT_LENGTH),
    offerAvailableTime: moment(OFFER_AVAILABLE_TIME, 'HH:mm:ss').toDate(),
    offerPercentPerEmployee: Number(OFFER_PERCENT),
    payrollPeriodStartDay: [{ value: PAYROLL_PERIOD_START_DAY }],
    tpoOfferPercentPerEmployee: Number(TPO_PERCENT),
    tpoIncludeCCTips: !!TPO_INCLUDE_CC_TIPS,
    tpoInclude3rdPartyTips: !!TPO_INCLUDE_3RD_PARTY_TIPS,
    tpoIncludeServiceCharges: !!TPO_INCLUDE_SERVICE_CHARGES,
    tpoIncludeDeclaredTips: !!TPO_INCLUDE_DECLARED_TIPS,
    tpoIncludeCashTips: !!TPO_INCLUDE_CASH_TIPS,
    tpoIncludeTipsPool: !!TPO_INCLUDE_TIPS_POOL,
    tpoSubtractIncludedCCTips: !!TPO_SUBTRACT_INCLUDED_CC_TIPS,
    tpoSubtractIncluded3rdPartyTips: !!TPO_SUBTRACT_INCLUDED_3RD_PARTY_TIPS,
    tpoSubtractIncludedServiceCharges: !!TPO_SUBTRACT_INCLUDED_SERVICE_CHARGES,
    tpoSubtractIncludedDeclaredTips: !!TPO_SUBTRACT_INCLUDED_DECLARED_TIPS,
    tpoSubtractIncludedCashTips: !!TPO_SUBTRACT_INCLUDED_CASH_TIPS,
    tpoSubtractIncludedTipsPool: !!TPO_SUBTRACT_INCLUDED_TIPS_POOL,
    tpoEnabled: !!TPO_ENABLED,
    ewaEnabled: !!EWA_ENABLED,
    tpoMaxEarningsPerBusinessDay: unScalePrice(Number(TPO_MAX_EARNINGS_PER_BUSINESS_DAY)),
    tpoMaxEarningsPerPeriod: unScalePrice(Number(TPO_MAX_EARNINGS_PER_PERIOD)),
  };
};

export const organizationConfigMapper = (
  data: OrganizationConfigResponseType[],
) => {
  const result = data.reduce((acc, item) => {
    switch (item.configKeyName as ConfigType) {
      case ConfigType.CORPORATE_TIMEZONE:
        acc.organizationTimezone = item.configValue || '';
        break;

      case ConfigType.BUSINESS_DAY_START_TIME:
        acc.businessDayStartTime = moment(item.configValue, 'HH:mm:ss').toDate();
        break;

      case ConfigType.PAYROLL_PERIOD_START_DAY:
        acc.payrollPeriodStartDay = [{ value: item.configValue || '' }];
        break;

      case ConfigType.PAYDAY_OFFSET:
        acc.paydayOffset = [{ value: Number(item.configValue) }];
        break;

      case ConfigType.PROGRAM_STATUS:
        acc.programStatus = [{ value: item.configValue || '' }];
        break;

      case ConfigType.PAYROLL_CUTOFF_TIME:
        acc.payrollCutoffTime = moment(item.configValue, 'HH:mm:ss').toDate();
        break;

      case ConfigType.EWA_ENABLED:
        acc.ewaEnabled = item.configValue === 'true';
        break;

      case ConfigType.AUTO_CLOCK_OUT_TIME:
        acc.autoClockOutTime = moment(item.configValue, 'HH:mm:ss').toDate();
        break;

      case ConfigType.INCLUDE_AUTO_CLOCKED_OUT_SHIFTS:
        acc.excludeAutoClockedOutShifts = !(item.configValue === 'true');
        break;

      case ConfigType.INCLUDE_CC_TIPS:
        acc.ewaIncludeCCTips = item.configValue === 'true';
        break;

      case ConfigType.INCLUDE_3RD_PARTY_TIPS:
        acc.ewaInclude3rdPartyTips = item.configValue === 'true';
        break;

      case ConfigType.INCLUDE_SERVICE_CHARGES:
        acc.ewaIncludeServiceCharges = item.configValue === 'true';
        break;

      case ConfigType.INCLUDE_DECLARED_TIPS:
        acc.ewaIncludeDeclaredTips = item.configValue === 'true';
        break;

      case ConfigType.INCLUDE_CASH_TIPS:
        acc.ewaIncludeCashTips = item.configValue === 'true';
        break;

      case ConfigType.INCLUDE_TIPS_POOL:
        acc.ewaIncludeTipsPool = item.configValue === 'true';
        break;

      case ConfigType.INCLUDE_TIPPED_SHIFTS:
        acc.excludeTippedShifts = !(item.configValue === 'true');
        break;

      case ConfigType.SUBTRACT_INCLUDED_CC_TIPS:
        acc.ewaSubtractIncludedCCTips = item.configValue === 'true';
        break;

      case ConfigType.SUBTRACT_INCLUDED_3RD_PARTY_TIPS:
        acc.ewaSubtractIncluded3rdPartyTips = item.configValue === 'true';
        break;

      case ConfigType.SUBTRACT_INCLUDED_SERVICE_CHARGES:
        acc.ewaSubtractIncludedServiceCharges = item.configValue === 'true';
        break;

      case ConfigType.SUBTRACT_INCLUDED_DECLARED_TIPS:
        acc.ewaSubtractIncludedDeclaredTips = item.configValue === 'true';
        break;

      case ConfigType.SUBTRACT_INCLUDED_CASH_TIPS:
        acc.ewaSubtractIncludedCashTips = item.configValue === 'true';
        break;

      case ConfigType.SUBTRACT_INCLUDED_TIPS_POOL:
        acc.ewaSubtractIncludedTipsPool = item.configValue === 'true';
        break;

      case ConfigType.MAX_OFFER_TOTAL:
        acc.maxOfferTotal = unScalePrice(Number(item.configValue));
        break;

      case ConfigType.MAX_SHIFT_LENGTH:
        acc.maxShiftLength = Number(item.configValue);
        break;

      case ConfigType.OFFER_AVAILABLE_TIME:
        acc.offerAvailableTime = moment(item.configValue, 'HH:mm:ss').toDate();
        break;

      case ConfigType.OFFER_PERCENT:
        acc.offerPercentPerEmployee = Number(item.configValue);
        break;

      case ConfigType.JOB_CODE_EXCLUSIONS:
        acc.jobCodeExclusions = !!item.configValue;
        acc.jobCodeExclusionsValue = item.configValue || '';
        break;
      case ConfigType.DEDUCTIONS_CSV_FORMAT:
        acc.deductionsCsvFormat = [{ value: item.configValue || '' }];
        break;
      case ConfigType.EMPLOYMENTS_SOURCE_IMPORT:
        acc.employmentDataSource = [{ value: item.configValue || '' }];
        break;
      case ConfigType.TNA_SOURCE_INTEGRATION:
        acc.tnaDataSource = [{ value: item.configValue || '' }];
        break;
      case ConfigType.TNA_SOURCE_IMPORT:
        acc.tnaDataImport = [{ value: item.configValue || '' }];
        break;
      case ConfigType.AUTOMATIC_BATCH_GENERATION:
        acc.automaticBatchGeneration = item.configValue === 'true';
        break;
      case ConfigType.OFFER_CALCULATION_MODE:
        acc.offerCalculationMode = [{ value: item.configValue || '' }];
        break;

      case ConfigType.BATCH_DELIVERY_METHODS:
        acc.batchDeliveryManual = item.configValue ? !!item.configValue.includes(BatchDeliveryMethods.API_MANUAL) : false;
        acc.batchDeliveryEmail = item.configValue ? !!item.configValue.includes(BatchDeliveryMethods.EMAIL) : false;
        break;

      case ConfigType.BATCH_DELIVERY_EMAIL_ADDRESSES:
        acc.organizationBatchDeliveryEmailAddresses = item.configValue ? item.configValue.replace(/,/g, ', ') : null; // workaround till API handles it
        break;

      case ConfigType.TPO_ENABLED:
        acc.tpoEnabled = item.configValue === 'true';
        break;

      case ConfigType.TPO_PERCENT:
        acc.tpoPercent = Number(item.configValue);
        break;

      case ConfigType.TPO_DAILY_PAYOUT_SCHEDULE:
        acc.tpoDailyPayoutSchedule = [{ value: item.configValue || '' }];
        break;

      case ConfigType.TPO_DAILY_PAYOUT_TIME:
        acc.tpoDailyPayoutTime = moment(item.configValue ? item.configValue : new Date(new Date().setHours(9, 0, 0)), 'HH:mm:ss').toDate();
        break;

      case ConfigType.TPO_INCLUDE_CC_TIPS:
        acc.tpoIncludeCCTips = item.configValue === 'true';
        break;

      case ConfigType.TPO_INCLUDE_SERVICE_CHARGES:
        acc.tpoIncludeServiceCharges = item.configValue === 'true';
        break;

      case ConfigType.TPO_INCLUDE_3RD_PARTY_TIPS:
        acc.tpoInclude3rdPartyTips = item.configValue === 'true';
        break;

      case ConfigType.TPO_INCLUDE_DECLARED_TIPS:
        acc.tpoIncludeDeclaredTips = item.configValue === 'true';
        break;

      case ConfigType.TPO_INCLUDE_CASH_TIPS:
        acc.tpoIncludeCashTips = item.configValue === 'true';
        break;

      case ConfigType.TPO_INCLUDE_TIPS_POOL:
        acc.tpoIncludeTipsPool = item.configValue === 'true';
        break;

      case ConfigType.TPO_SUBTRACT_INCLUDED_CC_TIPS:
        acc.tpoSubtractIncludedCCTips = item.configValue === 'true';
        break;

      case ConfigType.TPO_SUBTRACT_INCLUDED_SERVICE_CHARGES:
        acc.tpoSubtractIncludedServiceCharges = item.configValue === 'true';
        break;

      case ConfigType.TPO_SUBTRACT_INCLUDED_3RD_PARTY_TIPS:
        acc.tpoSubtractIncluded3rdPartyTips = item.configValue === 'true';
        break;

      case ConfigType.TPO_SUBTRACT_INCLUDED_DECLARED_TIPS:
        acc.tpoSubtractIncludedDeclaredTips = item.configValue === 'true';
        break;

      case ConfigType.TPO_SUBTRACT_INCLUDED_CASH_TIPS:
        acc.tpoSubtractIncludedCashTips = item.configValue === 'true';
        break;

      case ConfigType.TPO_SUBTRACT_INCLUDED_TIPS_POOL:
        acc.tpoSubtractIncludedTipsPool = item.configValue === 'true';
        break;

      case ConfigType.TPO_FEES_PAID_BY_EMPLOYER:
        acc.tpoFeesPaidByEmployer = item.configValue === 'true';
        break;

      case ConfigType.TPO_FEE_EMPLOYEE_PER_PERIOD_VALUE:
        acc.tpoFeeEmployeePerPeriodValue = unScalePrice(Number(item.configValue));
        acc.tpoFeeEmployeePerPeriod = Number(item.configValue) > 0;
        break;

      case ConfigType.TPO_FUEGO_CARD_PAYMENT_ENABLED:
        acc.tpoFuegoCardPaymentEnabled = item.configValue === 'true';
        break;

      case ConfigType.TPO_FUEGO_CARD_PAYMENT_VALUE:
        acc.tpoFuegoCardPaymentValue = unScalePrice(Number(item.configValue));
        break;

      case ConfigType.TPO_ACH_PAYMENT_ENABLED:
        acc.tpoAchPaymentFeeEnabled = item.configValue === 'true';
        break;

      case ConfigType.TPO_ACH_PAYMENT_FEE_VALUE:
        acc.tpoAchPaymentFeeValue = unScalePrice(Number(item.configValue));
        break;

      case ConfigType.TPO_SAME_DAY_ACH_PAYMENT_ENABLED:
        acc.tpoSameDayAchPaymentFeeEnabled = item.configValue === 'true';
        break;

      case ConfigType.TPO_SAME_DAY_ACH_PAYMENT_FEE_VALUE:
        acc.tpoSameDayAchPaymentFeeValue = unScalePrice(Number(item.configValue));
        break;

      case ConfigType.TPO_PUSH_TO_CARD_PAYMENT_ENABLED:
        acc.tpoPushToCardPaymentFeeEnabled = item.configValue === 'true';
        break;

      case ConfigType.TPO_PUSH_TO_CARD_PAYMENT_FEE_VALUE:
        acc.tpoPushToCardPaymentFeeValue = unScalePrice(Number(item.configValue));
        break;

      case ConfigType.DEDUCTION_SHORTFALL_SYSTEM:
        acc.deductionShortfallSystem = [{ value: item.configValue || '' }];
        break;

      case ConfigType.ESTIMATED_PAY_ENABLED:
        acc.estimatedPay = item.configValue === 'true';
        break;

      case ConfigType.SUPPORTED_EMPLOYMENT_EWA_TYPES:
        acc.hourlyPaidEmployeesEnable = item.configValue ? item.configValue.includes(SubElementEWATypeEnum.HOURLY_PAID) : false;
        acc.salariedEmployeesEnable = item.configValue ? item.configValue.includes(SubElementEWATypeEnum.SALARIED_NOT_CLOCKING) : false;
        acc.regularlyPaidEmployeesEnable = item.configValue ? item.configValue.includes(SubElementEWATypeEnum.REGULARLY_PAID_HOURLY) : false;
        break;

      case ConfigType.OFFER_PRO_RATA_METHOD:
        acc.offerProRataMethod = [{ value: item.configValue || '' }];
        break;

      case ConfigType.EWA_MAX_HOURLY_RATE_TIMECARD:
        acc.ewaMaxHourlyRateTimecard = unScalePrice(Number(item.configValue));
        break;

      case ConfigType.EWA_MAX_HOURLY_RATE_SALARIED_EMPLOYEE:
        acc.ewaMaxHourlyRateSalariedEmployees = unScalePrice(Number(item.configValue));
        break;

      default:
        break;
    }

    return acc;
  }, {} as OrganizationFormConfig);

  return result;
};

export const saveOrganizationEWASettingsMapper = (
  values: EWASettingsValuesType,
): SaveOrganizationConfigsRequestType => {
  let supportedEmploymentEWAType = '';

  if (values.hourlyPaidEmployeesEnable) {
    if (supportedEmploymentEWAType.length > 0) {
      supportedEmploymentEWAType += ',';
    }
    supportedEmploymentEWAType += SubElementEWATypeEnum.HOURLY_PAID;
  }
  if (values.salariedEmployeesEnable) {
    if (supportedEmploymentEWAType.length > 0) {
      supportedEmploymentEWAType += ',';
    }
    supportedEmploymentEWAType += SubElementEWATypeEnum.SALARIED_NOT_CLOCKING;
  }
  if (values.regularlyPaidEmployeesEnable) {
    if (supportedEmploymentEWAType.length > 0) {
      supportedEmploymentEWAType += ',';
    }
    supportedEmploymentEWAType += SubElementEWATypeEnum.REGULARLY_PAID_HOURLY;
  }

  return (
    [
      {
        configKeyName: ConfigType.EWA_ENABLED,
        configValue: values.ewaEnabled.toString(),
      },
      {
        configKeyName: ConfigType.AUTO_CLOCK_OUT_TIME,
        configValue: moment(values.autoClockOutTime).format('HH:mm:ss'),
      },
      {
        configKeyName: ConfigType.INCLUDE_AUTO_CLOCKED_OUT_SHIFTS,
        configValue: (!values.excludeAutoClockedOutShifts).toString(),
      },
      {
        configKeyName: ConfigType.INCLUDE_TIPPED_SHIFTS,
        configValue: (!values.excludeTippedShifts).toString(),
      },
      {
        configKeyName: ConfigType.MAX_OFFER_TOTAL,
        configValue: Math.round(scalePrice(values.maxOfferTotal)).toString(),
      },
      {
        configKeyName: ConfigType.MAX_SHIFT_LENGTH,
        configValue: values.maxShiftLength.toString(),
      },
      {
        configKeyName: ConfigType.OFFER_AVAILABLE_TIME,
        configValue: moment(values.offerAvailableTime).format('HH:mm:ss'),
      },
      {
        configKeyName: ConfigType.OFFER_PERCENT,
        configValue: values.offerPercentPerEmployee.toString(),
      },
      {
        configKeyName: ConfigType.SUPPORTED_EMPLOYMENT_EWA_TYPES,
        configValue: supportedEmploymentEWAType.length > 0 ? supportedEmploymentEWAType : null,
      },
      {
        configKeyName: ConfigType.OFFER_PRO_RATA_METHOD,
        configValue: values.offerProRataMethod[0].value.toString(),
      },
      {
        configKeyName: ConfigType.EWA_MAX_HOURLY_RATE_TIMECARD,
        configValue: Math.round(scalePrice(values.ewaMaxHourlyRateTimecard)).toString(),
      },
      {
        configKeyName: ConfigType.EWA_MAX_HOURLY_RATE_SALARIED_EMPLOYEE,
        configValue: Math.round(scalePrice(values.ewaMaxHourlyRateSalariedEmployees)).toString(),
      },
      // {
      //   configKeyName: ConfigType.JOB_CODE_EXCLUSIONS,
      //   configValue: values.jobCodeExclusionsValue.toString(),
      // },
    ]
  );
};

export const saveOrganizationTPOSettingsMapper = (
  values: TPOSettingsValuesType,
): SaveOrganizationConfigsRequestType => (
  [
    {
      configKeyName: ConfigType.TPO_ENABLED,
      configValue: values.tpoEnabled.toString(),
    },
    {
      configKeyName: ConfigType.TPO_PERCENT,
      configValue: values.tpoPercent.toString(),
    },
    {
      configKeyName: ConfigType.TPO_DAILY_PAYOUT_SCHEDULE,
      configValue: values.tpoDailyPayoutSchedule[0].value.toString().toUpperCase(),
    },
    {
      configKeyName: ConfigType.TPO_DAILY_PAYOUT_TIME,
      configValue: values.tpoDailyPayoutSchedule[0].value.toString() !== 'API' ? moment(values?.tpoDailyPayoutTime).format('HH:mm:ss') : null,
    },
    {
      configKeyName: ConfigType.TPO_FEES_PAID_BY_EMPLOYER,
      configValue: values.tpoFeesPaidByEmployer.toString(),
    },
    {
      configKeyName: ConfigType.TPO_FEE_EMPLOYEE_PER_PERIOD_VALUE,
      configValue: !values.tpoFeeEmployeePerPeriod ? '0' : Math.round(scalePrice(values.tpoFeeEmployeePerPeriodValue)).toString(),
    },
    {
      configKeyName: ConfigType.TPO_FUEGO_CARD_PAYMENT_ENABLED,
      configValue: values.tpoFuegoCardPaymentEnabled.toString(),
    },
    {
      configKeyName: ConfigType.TPO_FUEGO_CARD_PAYMENT_VALUE,
      configValue: values.tpoFuegoCardPaymentEnabled ? Math.round(scalePrice(values.tpoFuegoCardPaymentValue)).toString() : '0',
    },
    {
      configKeyName: ConfigType.TPO_ACH_PAYMENT_ENABLED,
      configValue: values.tpoAchPaymentFeeEnabled.toString(),
    },
    {
      configKeyName: ConfigType.TPO_ACH_PAYMENT_FEE_VALUE,
      configValue: values.tpoAchPaymentFeeEnabled ? Math.round(scalePrice(values.tpoAchPaymentFeeValue)).toString() : '0',
    },
    {
      configKeyName: ConfigType.TPO_SAME_DAY_ACH_PAYMENT_ENABLED,
      configValue: values.tpoSameDayAchPaymentFeeEnabled.toString(),
    },
    {
      configKeyName: ConfigType.TPO_SAME_DAY_ACH_PAYMENT_FEE_VALUE,
      configValue: values.tpoSameDayAchPaymentFeeEnabled ? Math.round(scalePrice(values.tpoSameDayAchPaymentFeeValue)).toString() : '0',
    },
    {
      configKeyName: ConfigType.TPO_PUSH_TO_CARD_PAYMENT_ENABLED,
      configValue: values.tpoPushToCardPaymentFeeEnabled.toString(),
    },
    {
      configKeyName: ConfigType.TPO_PUSH_TO_CARD_PAYMENT_FEE_VALUE,
      configValue: values.tpoPushToCardPaymentFeeEnabled ? Math.round(scalePrice(values.tpoPushToCardPaymentFeeValue)).toString() : '0',
    },
  ]
);

export const saveOrganizationDirectDepositEWAMapper = (
  values: DirectDepositSettingsValuesType,
): SaveOrganizationConfigsRequestType => (
  [
    {
      configKeyName: ConfigType.DIRECT_DEPOSIT,
      configValue: values.directDepositSetting[0].value.toString(),
    },
  ]
);

export const organizationMapper: any = (
  data: OrganizationResponseType | null | undefined,
) => ({
  externalId: data?.externalId || '',
  name: data?.name,
  invitationCode: data?.invitationCode || '',
  salesForceId: data?.salesForceId || '',
  netSuiteId: data?.netSuiteId || '',
  deductionCode: data?.deductionCode || '',
  externalIntegrationId: data?.externalIntegrationId || '',
  externalPayrollSystemId: data?.externalPayrollSystemId || '',
});

export const saveOrganizationMapper = (
  values: ProfileValuesType,
  organizationID?: OrganizationIDType,
): SaveOrganizationRequestType => ({
  id: organizationID || null,
  externalId: values.externalId || null,
  name: values.name,
  type: 'HOTSCHEDULES_INTEGRATED',
  tnaPollingCron: null,
  employerLoginUrl: null,
  deductionCode: values.deductionCode || null,
  netSuiteId: values.netSuiteId || null,
  salesForceId: values.salesForceId || null,
  externalIntegrationId: values.externalIntegrationId || null,
  externalPayrollSystemId: values.externalPayrollSystemId || null,
});

export const saveOrganizationConfigsMapper = (
  values: ProfileValuesType,
): SaveOrganizationConfigsRequestType => (
  [
    {
      configKeyName: ConfigType.PROGRAM_STATUS,
      configValue: values.programStatus[0].value.toString().toUpperCase(),
    },
    {
      configKeyName: ConfigType.CORPORATE_TIMEZONE,
      configValue: values.organizationTimezone.toString(),
    },
    {
      configKeyName: ConfigType.BUSINESS_DAY_START_TIME,
      configValue: moment(values.businessDayStartTime).format('HH:mm:ss'),
    },
    {
      configKeyName: ConfigType.PAYROLL_PERIOD_START_DAY,
      configValue: values.payrollPeriodStartDay[0].value.toString().toUpperCase(),
    },
    {
      configKeyName: ConfigType.PAYDAY_OFFSET,
      configValue: values.paydayOffset[0].value.toString(),
    },
    {
      configKeyName: ConfigType.PAYROLL_CUTOFF_TIME,
      configValue: moment(values.payrollCutoffTime).format('HH:mm:ss'),
    },
    {
      configKeyName: ConfigType.DEDUCTIONS_CSV_FORMAT,
      configValue: values.deductionsCsvFormat[0].value.toString().toUpperCase(),
    },
    {
      configKeyName: ConfigType.EMPLOYMENTS_SOURCE_IMPORT,
      configValue: values.employmentDataSource[0].value.toString().toUpperCase(),
    },
    {
      configKeyName: ConfigType.TNA_SOURCE_INTEGRATION,
      configValue: values.tnaDataSource[0].value.toString().toUpperCase(),
    },
    {
      configKeyName: ConfigType.TNA_SOURCE_IMPORT,
      configValue: values.tnaDataImport[0].value.toString().toUpperCase(),
    },
    {
      configKeyName: ConfigType.AUTOMATIC_BATCH_GENERATION,
      configValue: values.automaticBatchGeneration.toString(),
    },
    {
      configKeyName: ConfigType.BATCH_DELIVERY_METHODS,
      configValue: values.batchDeliveryEmail && values.automaticBatchGeneration
        ? BatchDeliveryMethods.EMAIL.concat(',', BatchDeliveryMethods.API_MANUAL) as string
        : BatchDeliveryMethods.API_MANUAL as string,
    },
    {
      configKeyName: ConfigType.BATCH_DELIVERY_EMAIL_ADDRESSES,
      // configValue: values.organizationBatchDeliveryEmailAddresses.toString(),
      configValue: values.batchDeliveryEmail && values.automaticBatchGeneration && values.organizationBatchDeliveryEmailAddresses
        ? values.organizationBatchDeliveryEmailAddresses.toString().replace(/\s/g, '') : null, // workaround till API handles it
    },
    {
      configKeyName: ConfigType.DEDUCTION_SHORTFALL_SYSTEM,
      configValue: values.deductionShortfallSystem[0].value.toString().toUpperCase(),
    },
    {
      configKeyName: ConfigType.OFFER_CALCULATION_MODE,
      configValue: values.offerCalculationMode[0].value.toString().toUpperCase(),
    },
    {
      configKeyName: ConfigType.ESTIMATED_PAY_ENABLED,
      configValue: values.estimatedPay.toString(),
    },
  ]
);

export const zendeskTicketMapper = (
  {
    id,
    status,
  }: ZendeskTicketResponseType,
): ZendeskTicketValuesType => ({
  ticketNumber: id,
  ticketStatuses: [{ value: status?.toString() }],
});

export const saveZendeskTicketMapper = (
  values: ZendeskTicketValuesType,
): ZendeskTicketRequestType => ({
  id: values.ticketNumber,
  status: (values?.ticketStatuses && values?.ticketStatuses[0]?.value) || '',
});

export const saveOrganizationTPOSettingsBankingMapper = (
  values: TPOSettingsValuesType,
): SetOrganizationTPOSettingsRequest => ({
  employerPayTcoFees: values.tpoFeesPaidByEmployer,
  effectiveDate: moment(new Date()).format('YYYY-MM-DD'),
  allowedMethods: [
    {
      paymentMethod: SubElementPaymentMethodEnum.FUEGO_CARD,
      fee: {
        value: values.tpoFuegoCardPaymentEnabled
          ? Math.round(scalePrice(values.tpoFuegoCardPaymentValue))
          : 0,
        scale: 2,
        currencyCode: 'USD',
      },
      enabled: values.tpoFuegoCardPaymentEnabled,
    },
    {
      paymentMethod: SubElementPaymentMethodEnum.FUEGO_PAYCARD,
      fee: {
        value: values.tpoFuegoCardPaymentEnabled
          ? Math.round(scalePrice(values.tpoFuegoCardPaymentValue))
          : 0,
        scale: 2,
        currencyCode: 'USD',
      },
      enabled: values.tpoFuegoCardPaymentEnabled,
    },
    {
      paymentMethod: SubElementPaymentMethodEnum.ACH_TRANSFER,
      fee: {
        value: values.tpoAchPaymentFeeEnabled
          ? Math.round(scalePrice(values.tpoAchPaymentFeeValue))
          : 0,
        scale: 2,
        currencyCode: 'USD',
      },
      enabled: values.tpoAchPaymentFeeEnabled,
    },
    {
      paymentMethod: SubElementPaymentMethodEnum.ACH_TRANSFER_SAME_DAY,
      fee: {
        value: values.tpoSameDayAchPaymentFeeEnabled
          ? Math.round(scalePrice(values.tpoSameDayAchPaymentFeeValue))
          : 0,
        scale: 2,
        currencyCode: 'USD',
      },
      enabled: values.tpoSameDayAchPaymentFeeEnabled,
    },

    {
      paymentMethod: SubElementPaymentMethodEnum.PUSH_TO_EXTERNAL_CARD,
      fee: {
        value: values.tpoPushToCardPaymentFeeEnabled
          ? Math.round(scalePrice(values.tpoPushToCardPaymentFeeValue))
          : 0,
        scale: 2,
        currencyCode: 'USD',
      },
      enabled: values.tpoPushToCardPaymentFeeEnabled,
    },
  ],
});

export const saveOrganizationDirectDepositBankingMapper = (
  values: DirectDepositSettingsValuesType,
): SetOrganizationDirectDepositSettingsRequest => ({
  organizationName: values.organizationName,
  serviceType: values.directDepositSetting[0].value,
  customMessage:
    values.directDepositSetting[0].value === 'DIRECT_DEPOSIT_CUSTOM'
    && values.ddCustomMessage
      ? values.ddCustomMessage?.toString()
      : undefined,
  termsAndConditions:
    values.directDepositSetting[0].value === 'DIRECT_DEPOSIT_FUEGO'
    && values.fddTermsAndConditions
      ? values.fddTermsAndConditions?.toString()
      : null,
  scheduledReport:
    values.directDepositSetting[0].value === 'DIRECT_DEPOSIT_FUEGO'
      ? {
        enabled: values.automaticFDDReportEnabled,
        recipients: values.automaticFDDReportEnabled
          ? values.automaticFDDReportEmailRecipients
            .toString()
            .replace(/\s/g, '')
          : null,
        frequency: values.automaticFDDReportEnabled
          ? values.automaticFDDReportFrequency[0].value
          : null,
        occurrence:
            values.automaticFDDReportEnabled
            && values.automaticFDDReportFrequency[0].value !== 'DAILY'
              ? values.automaticFDDReportOccurrence[0].value
              : null,
      }
      : undefined,
  externalPayrollSystemId: values.directDepositSetting[0].value === 'DIRECT_DEPOSIT_FUEGO'
    ? values.fddExternalPayrollSystemId
    : null,
});

export const fetchOrganizationDirectDepositBankingMapper = (
  values: SetOrganizationDirectDepositSettingsRequest,
): DirectDepositSettingsValuesType => ({
  organizationName: values.organizationName,
  directDepositSetting: [{ value: values.serviceType }],
  ddCustomMessage: values.customMessage ? values.customMessage : '',
  fddTermsAndConditions: values.termsAndConditions
    ? values.termsAndConditions
    : '',
  automaticFDDReportEnabled: values.scheduledReport?.enabled || false,
  automaticFDDReportEmailRecipients: values.scheduledReport?.recipients
    ? values.scheduledReport?.recipients
    : '',
  automaticFDDReportFrequency: [
    { value: values.scheduledReport?.frequency || '' },
  ],
  automaticFDDReportOccurrence: [
    { value: values.scheduledReport?.occurrence || '' },
  ],
  fddExternalPayrollSystemId: values.externalPayrollSystemId || '',
});

export const saveOrganizationTipSourceConfigurationMapper = (
  values: TipSourceConfigurationValuesType,
): SaveOrganizationConfigsRequestType => (
  [
    {
      configKeyName: ConfigType.INCLUDE_CC_TIPS,
      configValue: values.ewaIncludeCCTips.toString(),
    },
    {
      configKeyName: ConfigType.INCLUDE_3RD_PARTY_TIPS,
      configValue: values.ewaInclude3rdPartyTips.toString(),
    },
    {
      configKeyName: ConfigType.INCLUDE_SERVICE_CHARGES,
      configValue: values.ewaIncludeServiceCharges.toString(),
    },
    {
      configKeyName: ConfigType.INCLUDE_DECLARED_TIPS,
      configValue: values.ewaIncludeDeclaredTips.toString(),
    },
    {
      configKeyName: ConfigType.INCLUDE_CASH_TIPS,
      configValue: values.ewaIncludeCashTips.toString(),
    },
    {
      configKeyName: ConfigType.INCLUDE_TIPS_POOL,
      configValue: values.ewaIncludeTipsPool.toString(),
    },
    {
      configKeyName: ConfigType.SUBTRACT_INCLUDED_CC_TIPS,
      configValue: values.ewaSubtractIncludedCCTips.toString(),
    },
    {
      configKeyName: ConfigType.SUBTRACT_INCLUDED_3RD_PARTY_TIPS,
      configValue: values.ewaSubtractIncluded3rdPartyTips.toString(),
    },
    {
      configKeyName: ConfigType.SUBTRACT_INCLUDED_SERVICE_CHARGES,
      configValue: values.ewaSubtractIncludedServiceCharges.toString(),
    },
    {
      configKeyName: ConfigType.SUBTRACT_INCLUDED_DECLARED_TIPS,
      configValue: values.ewaSubtractIncludedDeclaredTips.toString(),
    },
    {
      configKeyName: ConfigType.SUBTRACT_INCLUDED_CASH_TIPS,
      configValue: values.ewaSubtractIncludedCashTips.toString(),
    },
    {
      configKeyName: ConfigType.SUBTRACT_INCLUDED_TIPS_POOL,
      configValue: values.ewaSubtractIncludedTipsPool.toString(),
    },
    {
      configKeyName: ConfigType.TPO_INCLUDE_CC_TIPS,
      configValue: values.tpoIncludeCCTips.toString(),
    },
    {
      configKeyName: ConfigType.TPO_INCLUDE_SERVICE_CHARGES,
      configValue: values.tpoIncludeServiceCharges.toString(),
    },
    {
      configKeyName: ConfigType.TPO_INCLUDE_3RD_PARTY_TIPS,
      configValue: values.tpoInclude3rdPartyTips.toString(),
    },
    {
      configKeyName: ConfigType.TPO_INCLUDE_DECLARED_TIPS,
      configValue: values.tpoIncludeDeclaredTips.toString(),
    },
    {
      configKeyName: ConfigType.TPO_INCLUDE_CASH_TIPS,
      configValue: values.tpoIncludeCashTips.toString(),
    },
    {
      configKeyName: ConfigType.TPO_INCLUDE_TIPS_POOL,
      configValue: values.tpoIncludeTipsPool.toString(),
    },
    {
      configKeyName: ConfigType.TPO_SUBTRACT_INCLUDED_CC_TIPS,
      configValue: values.tpoSubtractIncludedCCTips.toString(),
    },
    {
      configKeyName: ConfigType.TPO_SUBTRACT_INCLUDED_SERVICE_CHARGES,
      configValue: values.tpoSubtractIncludedServiceCharges.toString(),
    },
    {
      configKeyName: ConfigType.TPO_SUBTRACT_INCLUDED_3RD_PARTY_TIPS,
      configValue: values.tpoSubtractIncluded3rdPartyTips.toString(),
    },
    {
      configKeyName: ConfigType.TPO_SUBTRACT_INCLUDED_DECLARED_TIPS,
      configValue: values.tpoSubtractIncludedDeclaredTips.toString(),
    },
    {
      configKeyName: ConfigType.TPO_SUBTRACT_INCLUDED_CASH_TIPS,
      configValue: values.tpoSubtractIncludedCashTips.toString(),
    },
    {
      configKeyName: ConfigType.TPO_SUBTRACT_INCLUDED_TIPS_POOL,
      configValue: values.tpoSubtractIncludedTipsPool.toString(),
    },
  ]
);

export const fetchPaycardOrganizationSettingsMapper = (
  values: OrganizationPaycardSettingsParamsType,
  paycardProgramsListPending: boolean,
  paycardOrganizationSettingsPending: boolean,
  paycardFundingAccountIdsListPending: boolean,
): OrganizationPaycardSettingsFormParamsType => ({
  externalIntegrationId: values.organizationExternalId
    ? [{
      value: values.organizationExternalId,
      label: paycardOrganizationSettingsPending
        ? values.organizationExternalId
        : undefined,
    }]
    : [],
  paycardCustomer: values.personalizedCardsProgram?.enabled || false,
  programId: values.personalizedCardsProgram?.programId
    ? [{
      value: values.personalizedCardsProgram?.programId,
      label: paycardProgramsListPending
        ? values.personalizedCardsProgram?.programId
        : undefined,
    }]
    : [],
  cardManagement: values.nonPersonalizedCardsProgram?.enabled || false,
  fundingAccountId: values.nonPersonalizedCardsProgram?.fundingAccountId
    ? [{
      value: values.nonPersonalizedCardsProgram?.fundingAccountId,
      label: paycardFundingAccountIdsListPending
        ? values.nonPersonalizedCardsProgram?.fundingAccountId
        : undefined,
    }]
    : [],
});

export const savePaycardOrganizationSettingsMapper = (
  values: SaveOrganizationPaycardSettingsFormParamsType,
): OrganizationPaycardSettingsParamsType => ({
  organizationName: values.organizationName,
  organizationExternalId: values.externalIntegrationId[0].value,
  personalizedCardsProgram: {
    enabled: values.paycardCustomer,
    programId: values.paycardCustomer ? values.programId?.[0].value : undefined,
  },
  nonPersonalizedCardsProgram: {
    enabled: values.cardManagement,
    fundingAccountId: values.cardManagement ? values.fundingAccountId?.[0].value : undefined,
  },
});

export const saveOrganizationBankAccountMapper = (
  values: BankAccountValuesType,
): BankAccountValuesParamsType => ({
  organizationName: values.organizationName,
  routingNumber: values.routingNumber,
  accountNumber: values.accountNumber,
  legalName: values.legalName,
  nickname: values.nickname,
  repaymentStatement: values.repaymentStatement,
  destinationAccountId: values.destinationAccountId[0].value,
});
