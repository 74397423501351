export type AdministratorsHeaderTypes = {
  createAdmin: () => void,
}

export enum AdministratorRolesEnum {
  EWA_MANAGER = 'EWA_MANAGER',
  EWA_CLIENT_MANAGER = 'EWA_CLIENT_MANAGER',
  EWA_CLIENT_LOCATION_MANAGER = 'EWA_CLIENT_LOCATION_MANAGER',
  FBO_MANAGER = 'FBO_MANAGER',
  FBO_READER = 'FBO_READER',
  TREASURY_MANAGER = 'TREASURY_MANAGER',
  TREASURY_READER = 'TREASURY_READER',
  TIPS_READER = 'TIPS_READER',
  TIPS_CLIENT_MANAGER = 'TIPS_CLIENT_MANAGER',
  FDD_CLIENT_MANAGER = 'FDD_CLIENT_MANAGER',
  EWA_REPORT = 'EWA_REPORT'
}

export interface AdministratorType {
  id?: string,
  firstName: string,
  lastName: string,
  password?: string,
  email: string,
  organizations?: {
    id?: string,
    name?: string,
    programsAvailable?: string[]
  }[] | undefined,
  displayName?: string,
  userSource?: string,
  roles: (string | null)[],
  locations?: {
    id?: string,
    name?: string,
  }[] | undefined,
  mobileCountryCode?: string,
  mobilePhone?: string,
}
export type EditAdminRolesType = {
  organizations?: {
    id?: string,
    name?: string,
    programsAvailable?: string[]
  }[] | undefined,
  roles: (string | null)[],
  locations?: {
    id?: string,
    name?: string,
  }[] | undefined,
}
export type CreateAdminUserPropTypes = {
  data: AdministratorType,
}
export type EditAdminRolesRequestType = {
  data: EditAdminRolesType,
  id: string,
}

export type CreateAdministratorFormType = {
  id?: string,
  firstName: string,
  lastName: string,
  password?: string,
  email: string,
  organizationID?: {
    label: string,
    value: string,
  }[],
  locationID?: {
    label: string,
    value: string,
  }[],
  mobileCountry?: {
    id: string,
    label: string,
    dialCode: string
  },
  mobilePhone?: string,
  displayName?: string,
  userSource?: string,
  ewaManager?: boolean,
  ewaClientManager?: boolean,
  treasuryManager?: boolean,
  treasuryReader?: boolean,
  fboManager?: boolean,
  fboReader?: boolean,
  tipsReader?: boolean,
  ewaClientLocationManager?: boolean,
  tipsClientManager?: boolean,
  fddClientManager?: boolean,
  ewaReport?: boolean
}

export type EditAdministratorFormType = {
  organizationID?: {
    label: string,
    value: string,
  }[],
  locationID?: {
    label: string,
    value: string,
  }[],
  mobileCountry?: {
    id: string,
    label: string,
    dialCode: string
  },
  ewaManager?: boolean,
  ewaClientManager?: boolean,
  treasuryManager?: boolean,
  treasuryReader?: boolean,
  fboManager?: boolean,
  fboReader?: boolean,
  tipsReader?: boolean,
  ewaClientLocationManager?: boolean,
  tipsClientManager?: boolean,
  fddClientManager?: boolean,
  ewaReport?: boolean,
}

export type AllAdministratorsFilterType = {
  firstName?: string,
  lastName?: string,
  namePattern?: string,
  email?: string,
  nextToken?: string,
  active?: boolean
}
