import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import commonEN from './locales/en/common.json';
import notificationsEN from './locales/en/notifications.json';
import organizationsEN from './locales/en/organizations.json';
import employeesEN from './locales/en/employees.json';
import locationsEN from './locales/en/locations.json';
import payGroupsEN from './locales/en/payGroups.json';
import batchesEN from './locales/en/batches.json';
import repaymentsEN from './locales/en/repayments.json';
import offersEN from './locales/en/offers.json';
import administratorsEN from './locales/en/administrators.json';
import errorsEN from './locales/en/errors.json';
import dateFormatsEN from './locales/en/dateFormats.json';
import treasuryEN from './locales/en/treasury.json';
import workersEN from './locales/en/workers.json';
import integrationDataEN from './locales/en/integrationData.json';
import tipsManagementEN from './locales/en/tipsManagement.json';
import paycardManagementEN from './locales/en/paycardManagement.json';

export const defaultNS = 'common';
export const resources = {
  en: {
    common: commonEN,
    errors: errorsEN,
    notifications: notificationsEN,
    organizations: organizationsEN,
    employees: employeesEN,
    locations: locationsEN,
    payGroups: payGroupsEN,
    batches: batchesEN,
    repayments: repaymentsEN,
    administrators: administratorsEN,
    dateFormats: dateFormatsEN,
    offers: offersEN,
    treasury: treasuryEN,
    workers: workersEN,
    integrationData: integrationDataEN,
    tipsManagement: tipsManagementEN,
    paycardManagement: paycardManagementEN,
  },
} as const;

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    lng: 'en',
    ns: [
      'common',
      'errors',
      'organizations',
      'notifications',
      'locations',
      'payGroups',
      'employees',
      'batches',
      'repayments',
      'offers',
      'administrators',
      'dateFormats',
      'treasury',
      'workers',
      'integrationData',
      'tipsManagement',
      'paycardManagement',
    ],
    defaultNS,
    resources,
  });

const t = i18n.t.bind(i18n);

export { t };
export default i18n;
