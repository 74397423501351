import {
  Cell,
  Grid,
} from 'baseui/layout-grid';
import {
  ParagraphSmall,
} from 'baseui/typography';
import {
  ChangeEvent,
  memo,
  useMemo,
  useState,
} from 'react';
import {
  useAppDispatch,
  useAppSelector,
} from 'store/hooks';
import { useTranslation } from 'react-i18next';
import { unScalePrice } from 'utils/priceScale';
import { colors, emptyPlaceholder } from 'theme';
import priceFormatter from 'utils/priceFormatter';
import {
  TableBuilder,
  TableBuilderColumn,
} from 'baseui/table-semantic';
import {
  fetchOrganizationRepaymentRequestsOnLoadMore,
  repaymentsOrganizationRepaymentRequestsAreFetchedSelector,
  repaymentsOrganizationRepaymentRequestsListSelector,
  repaymentsOrganizationRepaymentRequestsMorePagesSelector,
  repaymentsOrganizationRepaymentRequestsPendingListSelector,
  repaymentsOrganizationRepaymentRequestsSearchFromDateSelector,
  repaymentsOrganizationRepaymentRequestsSearchStatusSelector,
  repaymentsOrganizationRepaymentRequestsSearchToDateSelector,
  repaymentsOrganizationRepaymentRequestsSelectedOrganizationSelector,
  selectedRepaymentRequestsListSelector,
  selectedRepaymentRequestsOptionChosenSelector,
  setSelectedRepaymentRequests,
} from 'store/slices/repaymentsBankDetails';
import { useHistory } from 'react-router-dom';
import { setLoggedOrganization } from 'store/slices/loggedOrganization';
import {
  FetchRepaymentsRequestsType,
  RepaymentRequestStatus,
  RepaymentRequestType,
} from 'types/RepaymentsBankDetails';
import moment from 'moment';
import { Block } from 'baseui/block';
import { Button, KIND } from 'baseui/button';
import { Tag } from 'baseui/tag';
import { Checkbox } from 'baseui/checkbox';
import AppTooltip from 'components/Form/AppTooltip';
import { TETHER_PLACEMENT } from 'baseui/layer';
import { PLACEMENT, StatefulPopover } from 'baseui/popover';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StatefulMenu } from 'baseui/menu';
import { fetchRepayment, setRepayment } from 'store/slices/repayments';

const ACHDebitSectionTable = () => {
  const history = useHistory();
  const { t } = useTranslation(['treasury, common, dateFormats']);
  const dateFormat = t('dateFormats:standard-with-time');
  const dispatch = useAppDispatch();

  const repaymentRequests = useAppSelector(repaymentsOrganizationRepaymentRequestsListSelector);
  const morePagesAvailable = useAppSelector(repaymentsOrganizationRepaymentRequestsMorePagesSelector);
  const pending = useAppSelector(repaymentsOrganizationRepaymentRequestsPendingListSelector);
  const areFetched = useAppSelector(repaymentsOrganizationRepaymentRequestsAreFetchedSelector);

  const repaymentStatus = useAppSelector(repaymentsOrganizationRepaymentRequestsSearchStatusSelector);
  const dateFormatStandard = t('dateFormats:standard-reverse');
  const organization = useAppSelector(repaymentsOrganizationRepaymentRequestsSelectedOrganizationSelector);
  const fromDate = useAppSelector(repaymentsOrganizationRepaymentRequestsSearchFromDateSelector);
  const toDate = useAppSelector(repaymentsOrganizationRepaymentRequestsSearchToDateSelector);

  const isSingleRequestChecked = useAppSelector(selectedRepaymentRequestsOptionChosenSelector);
  const selectedRequests = useAppSelector(selectedRepaymentRequestsListSelector);

  const [openPopover] = useState<boolean>(false);

  const repaymentStatusNoProcessing = useMemo(() => repaymentStatus
    && repaymentStatus.length > 0
    && (repaymentStatus[0].value !== RepaymentRequestStatus.NEW
      && repaymentStatus[0].value !== RepaymentRequestStatus.REJECTED_BY_BANK
    ), [repaymentStatus]);

  const repaymentRequestsNoProcessing = useMemo(
    () => repaymentRequests && repaymentRequests.length > 0
    && repaymentRequests.some((request: RepaymentRequestType) => request.status !== RepaymentRequestStatus.NEW
      && request.status !== RepaymentRequestStatus.REJECTED_BY_BANK),
    [repaymentRequests],
  );

  const TAG_REPAYMENTS_STATUS_KINDS: {
    [index: string]: string,
  } = {
    UNKNOWN: 'warning',
    NEW: 'accent',
    SEND_FOR_APPROVAL: 'warning',
    ACCEPTED_FOR_PROCESSING: 'warning',
    REJECTED_BY_BANK: 'negative',
    PENDING: 'warning',
    PROCESSED: 'positive',
  };

  const hasAny = Boolean(repaymentRequests?.length);
  const hasAll = useMemo(
    () => hasAny && (repaymentRequests?.every((x) => x.selected) || repaymentRequests?.every((x) => selectedRequests.find((i) => i.id === x.id))),
    [repaymentRequests,
      selectedRequests,
      hasAny],
  );
  const hasSome = useMemo(
    () => hasAny && repaymentRequests?.some((x) => x.selected),
    [repaymentRequests,
      hasAny],
  );

  const toggleAll = () => {
    const filteredAndRemapped = repaymentRequests?.map((request) => ({
      ...request,
      selected: !hasAll,
    }));
    if (!hasAll) {
      dispatch(setSelectedRepaymentRequests(selectedRequests
        .concat(filteredAndRemapped?.filter((request) => !selectedRequests.find((row) => String(row.id) === String(request.id))))));
    } else {
      dispatch(setSelectedRepaymentRequests(selectedRequests
        .filter((request) => !repaymentRequests?.find((row) => String(row.id) === String(request.id)))));
    }
  };

  const toggle = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    const { name, checked } = event.currentTarget;

    const targetedRequest = repaymentRequests?.find((row) => String(row.id) === name);
    if (checked && targetedRequest) {
      dispatch(setSelectedRepaymentRequests(selectedRequests.concat([targetedRequest])));
    } else {
      dispatch(setSelectedRepaymentRequests(selectedRequests.filter(({ id }) => targetedRequest && targetedRequest.id !== id)));
    }
  };

  const handleLoadMoreClick = () => {
    if ((organization && organization[0]?.value)) {
      const filter = new Map([
        ['organizationID', organization[0].value],
        ['status', repaymentStatus?.[0]?.value || ''],
        ['startDate', fromDate ? moment(fromDate).format(dateFormatStandard) : ''],
        ['endDate', toDate ? moment(toDate).format(dateFormatStandard) : ''],
      ]);
      dispatch(fetchOrganizationRepaymentRequestsOnLoadMore(Object.fromEntries(filter) as FetchRepaymentsRequestsType));
    }
  };

  const navigateToRepayment = (repaymentRequest: RepaymentRequestType) => {
    dispatch(setLoggedOrganization({
      name: organization[0].label,
      id: organization[0].value,
    }));
    dispatch(fetchRepayment({
      organizationId: organization[0]?.value,
      repaymentId: repaymentRequest.repaymentId,
    })).then(({ payload }) => {
      dispatch(setRepayment(payload));
      history.push(`/repayments/${payload.id}`);
    });
  };

  return (
    <Grid
      gridColumns={12}
      gridMargins={0}
    >
      <Cell
        span={12}
      >
        <TableBuilder
          data={repaymentRequests}
          emptyMessage={t('treasury:achDebit.repaymentsRequests.noResults')}
        >
          {isSingleRequestChecked && (
          <TableBuilderColumn
            header={(
              <Checkbox
                checked={hasAll}
                isIndeterminate={!hasAll && hasSome}
                disabled={repaymentStatusNoProcessing || repaymentRequestsNoProcessing}
                onChange={toggleAll}
                overrides={{
                  Root: {
                    props: {
                      id: 'repayments-requests-has-all-checkbox',
                    },
                  },
                  Input: {
                    props: {
                      'aria-checked': `${hasAll}`,
                    },
                  },
                }}
              />
                    )}
          >
            {(request: RepaymentRequestType) => (
              <ParagraphSmall>
                <Checkbox
                  name={request.id.toString()}
                  checked={!!selectedRequests.find(({ id }) => id === request.id)}
                  onChange={toggle}
                  disabled={request.status !== RepaymentRequestStatus.NEW && request.status !== RepaymentRequestStatus.REJECTED_BY_BANK}
                  overrides={{
                    Root: {
                      props: {
                        id: `repayments-requests-${request.id}-checkbox`,
                        'data-testid': `repayments-requests-${request.id}-checkbox`,
                      },
                    },
                    Input: {
                      props: {
                        'aria-checked': `${!!selectedRequests.find(({ id }) => id === request.id)}`,
                        'data-testid': `repayments-requests-${request.id}-checkbox-input`,
                      },
                    },
                  }}
                />
              </ParagraphSmall>
            )}
          </TableBuilderColumn>
          )}

          <TableBuilderColumn
            header={t('treasury:achDebit.repaymentRequest.table.repaymentName')}
          >
            {(repaymentRequest: RepaymentRequestType) => (
              <Block
                overrides={{
                  Block: {
                    style: {
                      display: 'inline-flex',
                      alignItems: 'center',
                      gap: '6px',
                    },
                  },
                }}
              >
                <ParagraphSmall
                  onClick={() => navigateToRepayment(repaymentRequest)}
                  color={colors.primary}
                  overrides={{
                    Block: {
                      style: {
                        cursor: 'pointer',
                      },
                    },
                  }}
                >
                  {repaymentRequest?.repaymentName || emptyPlaceholder}
                </ParagraphSmall>
              </Block>
            )}
          </TableBuilderColumn>

          <TableBuilderColumn
            header={t('treasury:achDebit.repaymentRequest.table.amount')}
          >
            {(repaymentRequest: RepaymentRequestType) => (
              <ParagraphSmall>
                { repaymentRequest.amount
                  ? priceFormatter().format(unScalePrice(repaymentRequest.amount.value, repaymentRequest.amount.scale))
                  : emptyPlaceholder}
              </ParagraphSmall>
            )}
          </TableBuilderColumn>

          <TableBuilderColumn
            header={t('treasury:achDebit.repaymentRequest.table.status')}
          >
            {(repaymentRequest: RepaymentRequestType) => {
              const kindType = TAG_REPAYMENTS_STATUS_KINDS[repaymentRequest.status as keyof typeof TAG_REPAYMENTS_STATUS_KINDS];
              return (
                <Tag
                  closeable={false}
                  kind={kindType as any}
                  overrides={{
                    Root: {
                      style: {
                        marginTop: '12px',
                      },
                    },
                    Text: {
                      style: {
                        maxWidth: 'unset',
                      },
                    },
                  }}
                >
                  {`${t(`treasury:achDebit.repaymentRequest.statuses.${repaymentRequest.status}`)}`}
                </Tag>
              );
            }}
          </TableBuilderColumn>

          <TableBuilderColumn
            header={(
              <>
                {t('treasury:achDebit.repaymentRequest.table.requestedAt')}
                <AppTooltip
                  title={t('treasury:achDebit.repaymentRequest.table.requestedAtTooltip')}
                  placement={TETHER_PLACEMENT.right}
                />
              </>
            )}
          >
            {(repaymentRequest: RepaymentRequestType) => (
              <ParagraphSmall>
                { moment(repaymentRequest?.requestedAt).format(dateFormat) || emptyPlaceholder}
              </ParagraphSmall>
            )}
          </TableBuilderColumn>

          <TableBuilderColumn
            header={t('treasury:achDebit.repaymentRequest.table.sourceAccount')}
          >
            {(repaymentRequest: RepaymentRequestType) => (
              <ParagraphSmall>
                { `${repaymentRequest?.sourceAccountNickname} - ${repaymentRequest?.sourceAccountLastFour}` || emptyPlaceholder}
              </ParagraphSmall>
            )}
          </TableBuilderColumn>

          <TableBuilderColumn
            header={t('treasury:achDebit.repaymentRequest.table.actions')}
          >
            {(repaymentRequest: RepaymentRequestType) => (
              <StatefulPopover
                initialState={{ isOpen: openPopover }}
                content={() => (
                  <StatefulMenu
                    onItemSelect={() => {}}
                    items={[]}
                  />
                )}
                accessibilityType="tooltip"
                placement={PLACEMENT.bottomLeft}
              >
                <Button
                  kind={KIND.tertiary}
                  overrides={{
                    Root: {
                      props: {
                        id: `ACHDebitSectionTable-${repaymentRequest.id}-tooltip-menu`,
                        'data-testid': `ACHDebitSectionTable-${repaymentRequest.id}-tooltip-menu`,
                      },
                      style: {
                        color: colors.primary,
                        backgroundColor: 'inherit',
                        ':hover': {
                          backgroundColor: 'inherit',
                        },
                      },
                    },
                  }}
                >
                  <FontAwesomeIcon
                    icon={faEllipsisVertical}
                  />
                </Button>
              </StatefulPopover>
            )}
          </TableBuilderColumn>
        </TableBuilder>
        <Block margin="10px 0 20px" display="flex">
          <Button
            overrides={{
              Root: {
                style: {
                  marginTop: 'auto',
                  marginBottom: 'auto',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                },
                props: {
                  id: 'repayments-requests-more-pages-button',
                },
              },
              LoadingSpinner: {
                style: {
                  borderRightColor: 'white',
                  borderTopColor: 'white',
                  borderLeftColor: 'white',
                },
              },
            }}
            isLoading={areFetched && pending}
            disabled={!morePagesAvailable || pending}
            onClick={handleLoadMoreClick}
          >
            {t('treasury:morePages')}
          </Button>
        </Block>
      </Cell>
    </Grid>
  );
};

export default memo(ACHDebitSectionTable);
