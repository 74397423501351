export const containerStyles = {
  display: 'inherit',
  width: '100%',
  background: '#F6F6F6',
  minHeight: '100vh',
};

export const imageContainerStyles = {
  width: '100%',
  'text-align': 'center',
  marginBottom: '38px',
};
