import {
  useIsAuthenticated,
} from '@azure/msal-react';
import { useStyletron } from 'baseui';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import CommonHeader from 'components/CommonHeader/CommonHeader';
import { containerStyles } from 'screens/CommonHelpers';

const Dashboard = () => {
  const isAuthenticated = useIsAuthenticated();
  const [css] = useStyletron();
  const { t } = useTranslation();

  if (!isAuthenticated) return null;
  return (
    <div className={css(containerStyles)}>
      <CommonHeader title={t('dashboard')} />
    </div>
  );
};

export default memo(Dashboard);
