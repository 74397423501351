import { TransactionsStatus } from 'types/MasterAccountTypes';
import { WorkerIDType } from 'types/WorkerTypes';
import { NewTransactionValuesType } from 'screens/Treasury/NewTransactionModal';
import { RecoverFundsRequestType } from 'types/WorkerAccountTypes';
import request from './request';

export const fetchWorkerAccountExternalRequest = (
  token: string,
  workerID: WorkerIDType,
) => request({
  token,
  url: `workers/${workerID}/accounts/external`,
});

export const fetchWorkerAccountTransactionsRequest = (
  token: string,
  workerID: WorkerIDType,
  page: string,
  transactionStatus?: TransactionsStatus,
  transactionType?: string,
  transactionNumber?: string,
  startDate?: string,
  endDate?: string,
) => {
  const queryParams = new URLSearchParams({
    page,
  });
  if (transactionStatus) {
    queryParams.set('transactionStatus', transactionStatus);
  }
  if (transactionType) {
    queryParams.set('transactionType', transactionType);
  }
  if (transactionNumber) {
    queryParams.set('transactionNumber', transactionNumber);
  }
  if (startDate) {
    queryParams.set('fromDate', startDate);
  }
  if (endDate) {
    queryParams.set('toDate', endDate);
  }
  return request({
    token,
    url: `workers/${workerID}/accounts/fbo/transactions?${queryParams.toString()}`,
  });
};

export const fetchWorkerAccountBalanceRequest = (
  token: string,
  workerID: WorkerIDType,
) => request({
  token,
  url: `workers/${workerID}/accounts/fbo/balance`,
});

export const createTransactionRequest = (
  token: string,
  payload: NewTransactionValuesType,
) => request({
  token,
  method: 'POST',
  url: 'workers',
  payload,
});

export const recoverFundsRequest = (
  token: string,
  workerID: WorkerIDType,
  data: RecoverFundsRequestType,
) => request({
  token,
  method: 'POST',
  payload: data,
  url: `workers/${workerID}/accounts/fbo/transactions/recover`,
});
