import { ReactNode, useContext } from 'react';
import { Input, InputProps, SIZE } from 'baseui/input';
import {
  Cell,
  ALIGNMENT,
  Responsive,
  CellProps,
  Alignment,
} from 'baseui/layout-grid';
import {
  FormControlProps,
} from 'baseui/form-control';
import {
  FormikHandlers, FormikHelpers, FormikState,
} from 'formik';
import AppFormControl from './AppFormControl';

export type AppInputPropsType = {
  name: string
  context: any
  label?: any
  cellSpan?: Responsive<number> | undefined
  cellAlign?: Alignment | undefined
  cellProps?: CellProps
  inputProps?: InputProps
  formControlProps?: Pick<FormControlProps, Exclude<keyof FormControlProps, 'children'>>
  placeholder?: string
  showStar?: boolean
  tooltipTitle?: ReactNode,
  tooltipContent?: ReactNode,
  dynamicErrors?: boolean
}

const AppInput = ({
  label,
  name,
  context,
  cellSpan = 3,
  cellAlign = ALIGNMENT.start,
  cellProps,
  inputProps,
  formControlProps,
  placeholder,
  showStar,
  tooltipTitle,
  tooltipContent,
  dynamicErrors = false,
}: AppInputPropsType) => {
  const formik = useContext<FormikState<any> & FormikHelpers<any> & FormikHandlers>(context);

  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    setFieldTouched,
  } = formik;

  return (
    <Cell
      span={cellSpan}
      align={cellAlign}
      {...cellProps}
    >
      <AppFormControl
        tooltipTitle={tooltipTitle}
        tooltipContent={tooltipContent}
        showStar={showStar}
        label={label}
        error={!!(errors[name] && touched[name]) && errors[name]}
        {...formControlProps}
      >
        <Input
          size={SIZE.compact}
          type="text"
          name={name}
          onChange={(e) => {
            handleChange(e);
            dynamicErrors && setFieldTouched(name, true, false);
          }}
          onBlur={handleBlur}
          value={values[name]}
          error={!!(errors[name] && touched[name] && errors[name])}
          placeholder={placeholder}
          overrides={{
            Root: {
              props: {
                id: `Form-input-${name}`,
                'data-testid': `Form-input-${name}`,
              },
            },
            ...inputProps?.disabled === true ? { MaskToggleButton: () => null } : '',
          }}
          {...inputProps}
        />
      </AppFormControl>
    </Cell>
  );
};

export default AppInput;
