import {
  Button,
  KIND,
} from 'baseui/button';
import { Overflow } from 'baseui/icon';
import {
  Cell,
  Grid,
} from 'baseui/layout-grid';
import {
  SIZE,
  TableBuilder,
  TableBuilderColumn,
} from 'baseui/table-semantic';
import {
  HeadingXSmall,
  ParagraphSmall,
} from 'baseui/typography';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/hooks';
import {
  paycardPaycardManagementCardStockFetchFailedSelector,
  paycardPaycardManagementCardStockListPendingSelector,
  paycardPaycardManagementCardStockListSelector,
  paycardPaycardManagementCardStockPageNumberSelector,
  paycardPaycardManagementCardStockTotalPagesSelector,
} from 'store/slices/paycards';
import { CardStockLocationItem } from 'types/PaycardManagementTypes';
import { ReactComponent as NotFound } from 'assets/not-found.svg';
import { useStyletron } from 'styletron-react';
import { Pagination } from 'baseui/pagination';
import { paginationTransparentOverrides } from 'screens/CommonHelpers';
import { Block } from 'baseui/block';
import AppTooltip from 'components/Form/AppTooltip';
import { Skeleton } from 'baseui/skeleton';
import { imageContainerStyles } from 'screens/PaycardManagement/PaycardManagementHelper';

export type PaycardManagementCardStockTablePropsType = {
  handlePageChange: ({ nextPage }: { nextPage: number }) => void,
}

const PaycardManagementCardStockTable = ({
  handlePageChange,
}: PaycardManagementCardStockTablePropsType) => {
  const { t } = useTranslation(['common', 'paycardManagement', 'dateFormats']);
  const cardStockList = useAppSelector(paycardPaycardManagementCardStockListSelector);
  const [css] = useStyletron();
  const numPages = useAppSelector(paycardPaycardManagementCardStockTotalPagesSelector);
  const page = useAppSelector(paycardPaycardManagementCardStockPageNumberSelector);
  const fetchFailed = useAppSelector(paycardPaycardManagementCardStockFetchFailedSelector);
  const pending = useAppSelector(paycardPaycardManagementCardStockListPendingSelector);

  return (
    <Grid
      gridColumns={12}
      gridMargins={12}
    >
      <Cell
        span={12}
      >
        <TableBuilder
          data={cardStockList}
          size={SIZE.compact}
          emptyMessage={(
            <div className={css(imageContainerStyles)}>
              {fetchFailed
                ? (<HeadingXSmall margin={0}>{t('paycardManagement:paycardManagement.errorOnLoading')}</HeadingXSmall>)
                : (
                  <>
                    <NotFound />
                    <HeadingXSmall margin={0}>{t('paycardManagement:paycardManagement.cardStock.table.noResults')}</HeadingXSmall>
                  </>
                )}
            </div>
          )}
          isLoading={pending}
          loadingMessage={(
            <Skeleton
              height="72px"
              animation
            />
          )}
        >
          <TableBuilderColumn
            header={t('paycardManagement:paycardManagement.cardStock.table.locations')}
          >
            {(location: CardStockLocationItem) => (
              <ParagraphSmall>
                {location.locationName}
              </ParagraphSmall>
            )}
          </TableBuilderColumn>
          <TableBuilderColumn
            header={t('paycardManagement:paycardManagement.cardStock.table.address')}
          >
            {(location: CardStockLocationItem) => (
              <ParagraphSmall>
                {`${location.locationAddress.addressLine1},
                ${location.locationAddress.city},
                ${location.locationAddress.state},
                ${location.locationAddress.postalCode}`}
              </ParagraphSmall>
            )}
          </TableBuilderColumn>
          <TableBuilderColumn
            header={t('paycardManagement:paycardManagement.cardStock.table.availableCards')}
          >
            {(location: CardStockLocationItem) => (
              <ParagraphSmall>
                {location.availableCards}
              </ParagraphSmall>
            )}
          </TableBuilderColumn>
          <TableBuilderColumn
            header={(
              <>
                {t('paycardManagement:paycardManagement.cardStock.table.threshold')}
                <AppTooltip
                  content={t('paycardManagement:paycardManagement.cardStock.table.thresholdTooltip')}
                />
              </>
)}
          >
            {(location: CardStockLocationItem) => (
              <ParagraphSmall>
                {location.threshold}
              </ParagraphSmall>
            )}
          </TableBuilderColumn>
          <TableBuilderColumn
            header={t('paycardManagement:paycardManagement.cardStock.table.actions')}
          >
            {(location: CardStockLocationItem) => (
              <Button
                onClick={() => null}
                disabled
                kind={KIND.secondary}
                overrides={{
                  Root: {
                    props: {
                      id: `Paycard-Management-cardStock-${location.locationId}-actions-btn`,
                      'data-testid': `Paycard-Management-cardStock-${location.locationId}-actions-btn`,
                    },
                  },
                }}
              >
                <Overflow />
              </Button>
            )}
          </TableBuilderColumn>
        </TableBuilder>

      </Cell>
      {!pending && (
      <Cell span={12}>
        <Block
          display="flex"
          width="100%"
          alignItems="center"
          justifyContent="center"
          justifyItems="center"
          marginBottom="16px"
        >
          <Pagination
            size={SIZE.compact}
            numPages={numPages}
            currentPage={page}
            overrides={paginationTransparentOverrides}
            onPageChange={handlePageChange}
          />
        </Block>
      </Cell>
      )}
    </Grid>
  );
};

export default memo(PaycardManagementCardStockTable);
