import {
  Cell,
  Grid,
} from 'baseui/layout-grid';
import {
  ParagraphSmall,
} from 'baseui/typography';
import {
  ChangeEvent,
  memo,
} from 'react';
import {
  useAppDispatch,
  useAppSelector,
} from 'store/hooks';
import {
  tippedEmployeesByPayPeriodNumPagesSelector,
  tippedEmployeesByPayPeriodPageNumberSelector,
  fetchTippedEmployeeBalanceOffer,
  setTippedEmployeeInfo,
  tippedEmployeesSelectedLocationSelector,
  tpoSearchPayrollPeriodSelector,
  tippedEmployeesByPayPeriodListSelector,
  tippedEmployeeBalanceOfferGeneratedSelector,
  selectedTippedEmployeesSelector,
  setSelectedTippedEmployees,
  isSingleTipPayoutOptionChosenSelector,
} from 'store/slices/tpo';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { unScalePrice } from 'utils/priceScale';
import {
  KIND,
  Tag,
} from 'baseui/tag';
import { Block } from 'baseui/block';
import {
  Pagination,
  SIZE,
} from 'baseui/pagination';
import { paginationTransparentOverrides } from 'screens/CommonHelpers';
import {
  ModalNames,
  modalsSelector,
  setModal,
} from 'store/slices/modals';
import checkIsModalOpen from 'utils/checkIsModalOpen';
import {
  colors,
  emptyPlaceholder,
} from 'theme';
import { TableBuilder, TableBuilderColumn } from 'baseui/table-semantic';
import { Checkbox } from 'baseui/checkbox';
import priceFormatter from 'utils/priceFormatter';
import { TippedEmployeePerPayrollPeriod } from 'types/TPOTypes';
import useExposeSpecificFeatureFlag from 'hooks/useExposeSpecificFeatureFlag';
import { faWarning } from '@fortawesome/free-solid-svg-icons';
import AppTooltip from 'components/Form/AppTooltip';
import TippedEmployeeBalanceModal from './TippedEmployeeBalanceModal';

export type TippedEmployeesPerPayPeriodTablePropsType = {
  handlePageChange: ({ nextPage }: { nextPage: number }) => void,
}

const TippedEmployeesPerPayPeriodTable = ({
  handlePageChange,
}: TippedEmployeesPerPayPeriodTablePropsType) => {
  const { t } = useTranslation(['tipsManagement']);
  const history = useHistory();
  const dispatch = useAppDispatch();
  const selectedTippedEmployees = useAppSelector(selectedTippedEmployeesSelector);
  const isSingleTPOChecked = useAppSelector(isSingleTipPayoutOptionChosenSelector);
  const modals = useAppSelector(modalsSelector);
  const pageNumber = useAppSelector(tippedEmployeesByPayPeriodPageNumberSelector);
  const numPages = useAppSelector(tippedEmployeesByPayPeriodNumPagesSelector);
  const selectedLocation = useAppSelector(tippedEmployeesSelectedLocationSelector);
  const selectedPayrollPeriod = useAppSelector(tpoSearchPayrollPeriodSelector);
  const tippedEmployees = useAppSelector(tippedEmployeesByPayPeriodListSelector);
  const balanceOfferGenerated = useAppSelector(tippedEmployeeBalanceOfferGeneratedSelector);

  const isTPOImprovementsVisible = useExposeSpecificFeatureFlag('tpoImprovements');

  const TAG_TPO_STATUS_STATUS_KINDS: {
    [index: string]: string,
  } = {
    EMPLOYEE_NOT_REGISTERED: 'primary',
    PAYMENT_FAILED: 'warning',
    OK: 'positive',
    PAYMENT_PROCESSING: 'positive',
    TCO_DEFAULT_ACCOUNT_NOT_SELECTED: 'primary',
    NON_TCO_EMPLOYMENT: 'primary',
  };

  const ACTIVE_PROGRAMS: {
    [index: string]: string,
  } = {
    TCO: 'TPO',
    EWA: 'EWA',
  };

  const TAG_EMPLOYEE_STATUS_KINDS: {
    [index: string]: string,
  } = {
    ACTIVE: 'positive',
    INACTIVE: 'primary',
    TERMINATED: 'primary',
    MERGED: 'primary',
    BAD_DATA: 'warning',
  };

  const navigateToUser = (employee: any): void => {
    dispatch(setTippedEmployeeInfo(employee));
    history.push(`/tips/${employee.id}`);
  };

  const handleBalanceClick = (employee: any) => {
    dispatch(fetchTippedEmployeeBalanceOffer({
      locationID: selectedLocation[0].id,
      employeeID: employee.id.toString(),
      payrollPeriodID: selectedPayrollPeriod[0].id.toString(),
    }));
    dispatch(setTippedEmployeeInfo(employee));
    dispatch(setModal({
      name: ModalNames.EMPLOYEE_BALANCE_DETAILS_MODAL,
      isOpen: true,
    }));
  };

  const hasAny = Boolean(tippedEmployees?.length);
  const hasAll = hasAny && (tippedEmployees?.every((x) => x.selected) || tippedEmployees?.every((x) => selectedTippedEmployees.find((i) => i.id === x.id)));
  const hasSome = hasAny && tippedEmployees?.some((x) => x.selected);

  const toggleAll = () => {
    const filteredAndRemapped = tippedEmployees?.map((employee) => ({
      ...employee,
      selected: !hasAll,
    }));
    if (!hasAll) {
      dispatch(setSelectedTippedEmployees(selectedTippedEmployees
        .concat(filteredAndRemapped?.filter((employee) => !selectedTippedEmployees.find((row) => String(row.id) === String(employee.id))))));
    } else {
      dispatch(setSelectedTippedEmployees(selectedTippedEmployees
        .filter((employee) => !tippedEmployees?.find((row) => String(row.id) === String(employee.id)))));
    }
  };

  const toggle = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    const { name, checked } = event.currentTarget;

    const targetedEmployee = tippedEmployees?.find((row) => String(row.id) === name);
    if (checked && targetedEmployee) {
      dispatch(setSelectedTippedEmployees(selectedTippedEmployees.concat([targetedEmployee])));
    } else {
      dispatch(setSelectedTippedEmployees(selectedTippedEmployees.filter(({ id }) => targetedEmployee && targetedEmployee.id !== id)));
    }
  };

  return (
    <Grid
      gridColumns={12}
      gridMargins={0}
    >
      <Cell
        span={12}
      >
        <TableBuilder
          data={tippedEmployees}
          emptyMessage={t('tipsManagement:location.noResults')}
        >
          {isSingleTPOChecked && (
          <TableBuilderColumn
            header={(
              <Checkbox
                checked={hasAll}
                isIndeterminate={!hasAll && hasSome}
                onChange={toggleAll}
                overrides={{
                  Root: {
                    props: {
                      id: 'tipped-employees-has-all-checkbox',
                    },
                  },
                  Input: {
                    props: {
                      'aria-checked': `${hasAll}`,
                    },
                  },
                }}
              />
                    )}
          >
            {(employee: TippedEmployeePerPayrollPeriod) => (
              <ParagraphSmall>
                <Checkbox
                  name={employee.id.toString()}
                  checked={!!selectedTippedEmployees.find(({ id }) => id === employee.id)}
                  onChange={toggle}
                  overrides={{
                    Root: {
                      props: {
                        id: `tipped-employees-${employee.id}-checkbox`,
                      },
                    },
                    Input: {
                      props: {
                        'aria-checked': `${!!selectedTippedEmployees.find(({ id }) => id === employee.id)}`,
                      },
                    },
                  }}
                />
              </ParagraphSmall>
            )}
          </TableBuilderColumn>
          )}

          <TableBuilderColumn header={t('tipsManagement:employeeName.header')}>
            {(employee: TippedEmployeePerPayrollPeriod) => (
              <Block
                overrides={{
                  Block: {
                    style: {
                      display: 'inline-flex',
                      alignItems: 'center',
                      gap: '6px',
                    },
                  },
                }}
              >
                <ParagraphSmall
                  onClick={() => navigateToUser(employee)}
                  color={colors.primary}
                  overrides={{
                    Block: {
                      style: {
                        cursor: 'pointer',
                        textDecorationLine: 'underline',
                      },
                    },
                  }}
                >
                  {employee.name}
                </ParagraphSmall>
                {isTPOImprovementsVisible && (employee.thresholdReached || employee.highEarner) && (
                <AppTooltip
                  content={employee.thresholdReached
                    ? t('tipsManagement:employeeName.thresholdReached.tooltip.text')
                    : t('tipsManagement:employeeName.highEarner.tooltip.text')}
                  title={employee.thresholdReached
                    ? t('tipsManagement:employeeName.thresholdReached.label')
                    : t('tipsManagement:employeeName.highEarner.label')}
                  icon={faWarning}
                  iconColor={employee.thresholdReached ? colors.primary : '#FDCF70'}
                />
                )}
              </Block>
            )}
          </TableBuilderColumn>

          <TableBuilderColumn
            header={t('tipsManagement:tipsEarned.header')}
          >
            {(employee: TippedEmployeePerPayrollPeriod) => (
              <ParagraphSmall>
                { employee.tipsEarnedToDate
                  ? priceFormatter().format(unScalePrice(employee.tipsEarnedToDate.value, employee.tipsEarnedToDate.scale))
                  : emptyPlaceholder}
              </ParagraphSmall>
            )}
          </TableBuilderColumn>

          <TableBuilderColumn
            header={t('tipsManagement:payableTips.header')}
          >
            {(employee: TippedEmployeePerPayrollPeriod) => (
              <ParagraphSmall>
                { employee.tipsPayable
                  ? priceFormatter().format(unScalePrice(employee.tipsPayable.value, employee.tipsPayable.scale))
                  : emptyPlaceholder}
              </ParagraphSmall>
            )}
          </TableBuilderColumn>

          <TableBuilderColumn
            header={t('tipsManagement:paidThisPeriod.header')}
          >
            {(employee: TippedEmployeePerPayrollPeriod) => (
              <ParagraphSmall>
                { employee.tipsPaid
                  ? priceFormatter().format(unScalePrice(employee.tipsPaid.value, employee.tipsPaid.scale))
                  : emptyPlaceholder}
              </ParagraphSmall>
            )}
          </TableBuilderColumn>

          <TableBuilderColumn
            header={t('tipsManagement:balance.header')}
          >
            {(employee: TippedEmployeePerPayrollPeriod) => {
              const checkEmpStatusForBalance = employee.status === 'NON_TCO_EMPLOYMENT'
                || employee.balance === undefined
                || (employee.tipsEarnedToDate === undefined && employee.tipsPayable === undefined);

              if (checkEmpStatusForBalance) {
                return (
                  <ParagraphSmall>
                    {emptyPlaceholder}
                  </ParagraphSmall>
                );
              }

              return (
                <ParagraphSmall
                  onClick={() => handleBalanceClick(employee)}
                  color={colors.primary}
                  overrides={{
                    Block: {
                      style: {
                        cursor: 'pointer',
                        textDecorationLine: 'underline',
                      },
                    },
                  }}
                >
                  {employee.balance
                    ? priceFormatter().format(unScalePrice(employee.balance.value, employee.balance.scale))
                    : emptyPlaceholder}
                </ParagraphSmall>
              );
            }}
          </TableBuilderColumn>

          <TableBuilderColumn header={t('tipsManagement:activeProgram.header')}>
            {(employee: TippedEmployeePerPayrollPeriod) => {
              const convertedActivePrograms = employee.activePrograms
                .filter((i: string) => !i.includes('DIRECT_DEPOSIT'))
                .map((item: string) => ACTIVE_PROGRAMS[item as keyof typeof ACTIVE_PROGRAMS]);

              if (convertedActivePrograms && convertedActivePrograms.length > 0) {
                return (
                  convertedActivePrograms.map((program: string) => (
                    <>
                      <Tag
                        closeable={false}
                        kind={KIND.accent}
                        overrides={{
                          Root: {
                            style: {
                              marginTop: '12px',
                            },
                          },
                        }}
                      >
                        { program }
                      </Tag>
                      <br />
                    </>
                  ))
                );
              }

              return (
                <Tag
                  closeable={false}
                  kind={KIND.warning}
                  overrides={{
                    Root: {
                      style: {
                        marginTop: '12px',
                      },
                    },
                  }}
                >
                  Not Registered
                </Tag>
              );
            }}

          </TableBuilderColumn>

          <TableBuilderColumn header={t('tipsManagement:employeeStatus.header')}>
            {(employee: TippedEmployeePerPayrollPeriod) => {
              const kindType = TAG_EMPLOYEE_STATUS_KINDS[employee.employmentStatus as keyof typeof TAG_EMPLOYEE_STATUS_KINDS];
              return (
                <Tag
                  closeable={false}
                  kind={kindType as any}
                  overrides={{
                    Root: {
                      style: {
                        marginTop: '12px',
                      },
                    },
                  }}
                >
                  {`${t(`tipsManagement:employmentStatuses.${employee.employmentStatus}`)}`}
                </Tag>
              );
            }}
          </TableBuilderColumn>

          <TableBuilderColumn
            header={t('tipsManagement:lastPaymentState.header')}
          >
            {(employee: TippedEmployeePerPayrollPeriod) => {
              const kindTypeTPOStatus = TAG_TPO_STATUS_STATUS_KINDS[employee.status as keyof typeof TAG_TPO_STATUS_STATUS_KINDS];
              return (
                <Tag
                  closeable={false}
                  kind={kindTypeTPOStatus as any}
                  overrides={{
                    Root: {
                      style: {
                        marginTop: '12px',
                      },
                    },
                  }}
                >
                  {employee.status === 'NON_TCO_EMPLOYMENT' || employee.status === 'EMPLOYEE_NOT_REGISTERED'
                    ? emptyPlaceholder : `${t(`tipsManagement:statuses.${employee.status}`)}`}
                </Tag>
              );
            }}

          </TableBuilderColumn>
        </TableBuilder>
      </Cell>

      {tippedEmployees && tippedEmployees?.length > 0 && (
        <Cell span={12}>
          <Block
            display="flex"
            width="100%"
            alignItems="center"
            justifyContent="center"
            justifyItems="center"
            marginBottom="16px"
            marginTop="16px"
          >
            <Pagination
              size={SIZE.compact}
              numPages={numPages}
              currentPage={pageNumber}
              overrides={paginationTransparentOverrides}
              onPageChange={handlePageChange}
            />
          </Block>
        </Cell>
      )}
      {checkIsModalOpen(modals, ModalNames.EMPLOYEE_BALANCE_DETAILS_MODAL) && balanceOfferGenerated && (
      <TippedEmployeeBalanceModal />
      )}

    </Grid>
  );
};

export default memo(TippedEmployeesPerPayPeriodTable);
