import moment from 'moment';
import {
  ChangeEvent, memo, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRotate } from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router-dom';
import { useStyletron } from 'styletron-react';
import { Input } from 'baseui/input';
import { Button } from 'baseui/button';
import { ALIGNMENT, Cell, Grid } from 'baseui/layout-grid';
import { SIZE } from 'baseui/table-semantic';
import { Block } from 'baseui/block';
import { Search } from 'baseui/icon';
import AccordionSection from 'components/AccordionSection/AccordionSection';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  employeeEWADrawsSelector,
  employeesEWADrawsAreListFetchedSelector,
  employeesEWADrawsNumPagesSelector,
  employeesEWADrawsPageNumberSelector,
  employeesEWADrawsPendingListSelector,
  employeesEWADrawsTotalSizeSelector,
  fetchEmployeeDetailsEWADraws,
} from 'store/slices/employees';
import {
  Draw,
  EmployeeIDType,
  EmployeeResponseType,
  SubElementType,
  SubElementTypeEnum,
} from 'types/EmployeeTypes';
import priceFormatter from 'utils/priceFormatter';
import { unScalePrice } from 'utils/priceScale';
import { emptyPlaceholder } from 'theme';
import Loader from 'components/Loader';
import { Select, Value, OnChangeParams } from 'baseui/select';
import { FormControl } from 'baseui/form-control';
import {
  fetchPayrollPeriods,
  payGroupPayrollPeriodsSelector,
  payGroupPendingPayrollPeriodsListSelector,
  resetPayGroupPayRollPeriods,
} from 'store/slices/payGroups';
import { loggedOrganizationSelector } from 'store/slices/loggedOrganization';
import { InputCustomHTMLElement } from 'types/CommonTypes';
import {
  checkIfSubElementStatusTypeIsPositive,
  containerStyles,
  statusOptions,
} from '../../EmployeesHelpers';
import EmployeeDetailsDrawTable, { feeTypes } from './EmployeeDetailsDrawTable';

export type EmployeeDetailsDrawSectionPropsType = {
  expanded?: boolean,
  selectedEmployee?: EmployeeResponseType,
}

const selectContainerStyle = {
  height: '48px',
  display: 'flex',
  alignItems: 'center',
};

export const extractAmountItem = (subElements: SubElementType[]): SubElementType | undefined => (
  subElements?.find((subElement) => subElement.type === SubElementTypeEnum.DRAW_RESEND
    && checkIfSubElementStatusTypeIsPositive(subElement.status))
    ?? subElements?.find((subElement) => subElement.type === SubElementTypeEnum.DRAW_REQUEST)
);

export const extractFeesItems = (subElements: SubElementType[]) => subElements?.filter((subElement) => feeTypes.includes(subElement?.type));

export const calculateFees = (feesItems: SubElementType[]) => feesItems
  ?.reduce((acc, subElement) => +acc + unScalePrice(subElement?.amount?.value, subElement?.amount?.scale), 0);

const EmployeeDetailsDrawSection = ({
  expanded,
  selectedEmployee,
}: EmployeeDetailsDrawSectionPropsType) => {
  const dispatch = useAppDispatch();
  const [css] = useStyletron();
  const { t } = useTranslation(['common', 'employees', 'dateFormats']);
  const { employeeID }: { employeeID: EmployeeIDType } = useParams<{ employeeID: string }>();
  const draws = useAppSelector(employeeEWADrawsSelector);
  const pendingList = useAppSelector(employeesEWADrawsPendingListSelector);
  const areListFetched = useAppSelector(employeesEWADrawsAreListFetchedSelector);
  const loggedOrganization = useAppSelector(loggedOrganizationSelector);
  const payrollPeriods = useAppSelector(payGroupPayrollPeriodsSelector);
  const pendingPayrollPeriods = useAppSelector(payGroupPendingPayrollPeriodsListSelector);
  const [search, setSearch] = useState('');
  const numPages = useAppSelector(employeesEWADrawsNumPagesSelector);
  const pageNumber = useAppSelector(employeesEWADrawsPageNumberSelector);
  const totalSize = useAppSelector(employeesEWADrawsTotalSizeSelector);
  const [drawStatus, setDrawStatus] = useState<Value>();
  const [payrollPeriod, setPayrollPeriod] = useState<Value>();
  const dateFormat = t('dateFormats:standard');

  const payPeriodFormat: any = (startDate: any, endDate: any) => `${moment(startDate).format(dateFormat)} - ${moment(endDate).format(dateFormat)}`;

  const searchFilter = (draw: Draw) => {
    const searchWord = search?.toLowerCase();
    const amountItem = extractAmountItem(draw?.subElements);
    const amount = amountItem?.amount ? unScalePrice(amountItem?.amount?.value, amountItem?.amount?.scale) : 0;
    const feeItems = extractFeesItems(draw?.subElements);
    const feesSum = calculateFees(feeItems);

    // eslint-disable-next-line max-len
    return `${amountItem?.recipient?.accountNickname?.toLowerCase() || emptyPlaceholder} - ${amountItem?.recipient?.accountLastFour || emptyPlaceholder}`?.includes(searchWord)
      || priceFormatter().format(feesSum)?.toString()?.includes(searchWord)
      || priceFormatter().format(amount)?.toString()?.includes(searchWord)
      || moment(draw?.drawRequestedDate).format(dateFormat).toString()?.includes(searchWord)
      || payPeriodFormat(draw?.payrollPeriod?.startDate, draw?.payrollPeriod?.endDate)?.includes(searchWord)
      || t(`employees:draws.paymentMethods.${draw?.paymentMethod}`).toLowerCase()?.includes(searchWord)
      || amountItem?.status?.toLowerCase()?.includes(searchWord)
      || draw?.id?.toString()?.toLowerCase()?.includes(searchWord)
      || draw?.offerId?.toString()?.toLowerCase()?.includes(searchWord);
  };

  const filteredDraws = draws
    ?.filter(searchFilter)
    ?.sort((a, b) => (moment(b?.drawRequestedDate).unix()) - (moment(a?.drawRequestedDate).unix()));

  const fetchPayrollPeriodsByPaygroup = () => {
    const payGroupID = selectedEmployee?.assignments?.find((i) => i.primaryLocation)?.location?.payGroup.id;
    if (payGroupID) {
      dispatch(fetchPayrollPeriods({
        organizationID: loggedOrganization?.id,
        payGroupID: String(payGroupID),
      }));
    }
  };

  const handleOnExpand = () => {
    if (employeeID && !areListFetched) {
      dispatch(fetchEmployeeDetailsEWADraws({ employeeID, filter: { pageNumber: pageNumber.toString(), type: 'EWA' } }));
      !payrollPeriods && fetchPayrollPeriodsByPaygroup();
    }
  };

  const handleOnRefresh = () => {
    if (employeeID) {
      setDrawStatus(undefined);
      setPayrollPeriod(undefined);
      dispatch(fetchEmployeeDetailsEWADraws({ employeeID, filter: { pageNumber: pageNumber.toString(), type: 'EWA' } }));
    }
  };

  const handleChangeSearch = (
    e: ChangeEvent<InputCustomHTMLElement>,
  ) => {
    setSearch(e.target.value);
  };

  const handleStatusChange = ({
    value,
  }: OnChangeParams) => {
    setDrawStatus(value);
    let filter: any = { pageNumber: '1', type: 'EWA' };
    if (value && value?.[0]?.value) {
      filter = { ...filter, statuses: value[0]?.value };
    }
    if (payrollPeriod && payrollPeriod?.[0]) {
      filter = { ...filter, payrollPeriodId: payrollPeriod?.[0].id };
    }
    dispatch(fetchEmployeeDetailsEWADraws({ employeeID, filter }));
  };

  const handlePayrollPeriodChange = ({
    value,
  }: OnChangeParams) => {
    setPayrollPeriod(value);
    let filter: any = { pageNumber: '1', type: 'EWA' };
    if (value && value?.[0]?.id) {
      filter = { ...filter, payrollPeriodId: value[0]?.id };
    }
    if (drawStatus && drawStatus?.[0]) {
      filter = { ...filter, statuses: drawStatus?.[0].value };
    }
    dispatch(fetchEmployeeDetailsEWADraws({ employeeID, filter }));
  };

  const handlePageChange = ({ nextPage }: { nextPage: number }) => {
    const page = Math.min(Math.max(nextPage, 1), totalSize).toString();
    let filter: any = { pageNumber: page, type: 'EWA' };
    if (payrollPeriod && payrollPeriod?.[0]) {
      filter = { ...filter, payrollPeriodId: payrollPeriod[0]?.id };
    }
    if (drawStatus && drawStatus?.[0]) {
      filter = { ...filter, statuses: drawStatus?.[0].value };
    }
    dispatch(fetchEmployeeDetailsEWADraws({
      employeeID,
      filter,
    }));
  };

  useEffect(() => {
    fetchPayrollPeriodsByPaygroup();
  }, [selectedEmployee]);

  useEffect(() => () => {
    dispatch(resetPayGroupPayRollPeriods());
  }, []);

  return (
    <AccordionSection
      expanded={expanded}
      title={t('employees:drawHistory')}
      disabled={false}
      showStatus={false}
      onExpand={handleOnExpand}
    >
      <div className={css(containerStyles)}>
        <Loader active={pendingList} />

        <Block
          marginBottom="16px"
        >
          <Grid
            gridColumns={12}
            gridGutters={5}
            align={ALIGNMENT.center}
            gridMargins={36}
            gridGaps={8}
          >
            <Cell
              span={[12, 6, 3]}
              align={ALIGNMENT.center}
            >
              <Block
                alignItems="start"
                justifyContent="start"
                display="flex"
                width="auto"
              >

                <Input
                  startEnhancer={<Search />}
                  type="text"
                  name="search"
                  onChange={handleChangeSearch}
                  value={search}
                  clearOnEscape
                  clearable
                  placeholder={t('employees:draws.search.placeholder')}
                  overrides={{
                    Input: {
                      props: {
                        id: 'EmployeeDetailsDrawSection-search',
                        autoComplete: 'off',
                      },
                    },
                  }}
                />

              </Block>
            </Cell>
            <Cell
              span={[12, 6, 3]}
              align={ALIGNMENT.center}
              overrides={{
                Cell: {
                  style: {
                    height: '115px',
                  },
                },
              }}
            >
              <FormControl
                label={t('employees:draws.statusLabel')}
                overrides={{
                  Label: {
                    style: {
                      marginBottom: '2px',
                    },
                  },
                }}
              >
                <Select
                  size={SIZE.compact}
                  maxDropdownHeight="300px"
                  placeholder={t('common:select')}
                  type="select"
                  clearable
                  options={statusOptions}
                  labelKey="value"
                  valueKey="id"
                  onChange={handleStatusChange}
                  value={drawStatus as Value}
                  overrides={{
                    ControlContainer: {
                      style: selectContainerStyle,
                      props: {
                        'data-testid': 'employeeDrawSection-status-select',
                        id: 'employeeDrawSection-status-select-id',
                      },
                    },
                  }}
                />
              </FormControl>
            </Cell>
            <Cell
              span={[12, 6, 3]}
              align={ALIGNMENT.center}
              overrides={{
                Cell: {
                  style: {
                    height: '115px',
                  },
                },
              }}
            >
              <FormControl
                label={t('employees:table.payPeriod')}
                overrides={{
                  Label: {
                    style: {
                      marginBottom: '2px',
                    },
                  },
                }}
              >
                <Select
                  size={SIZE.compact}
                  maxDropdownHeight="300px"
                  placeholder={t('common:select')}
                  isLoading={pendingPayrollPeriods}
                  type="select"
                  clearable
                  options={payrollPeriods?.map(({ startDate, endDate, id }) => ({
                    name: `${moment(startDate).format(dateFormat)} - ${moment(endDate).format(dateFormat)}`,
                    id,
                  }))}
                  labelKey="name"
                  valueKey="id"
                  onChange={handlePayrollPeriodChange}
                  value={payrollPeriod as Value}
                  overrides={{
                    ControlContainer: {
                      style: selectContainerStyle,
                      props: {
                        'data-testid': 'employeeDrawSection-payrollPeriod-select',
                        id: 'employeeDrawSection-payrollPeriod-select-id',
                      },
                    },
                  }}
                  noResultsMsg={t('common:noResults')}
                />
              </FormControl>
            </Cell>
            <Cell
              span={[12, 6, 3]}
              align={ALIGNMENT.center}
            >
              <Block
                alignItems="end"
                justifyContent="end"
                display="flex"
                width="auto"
              >
                <Button
                  onClick={handleOnRefresh}
                >
                  <FontAwesomeIcon
                    className={css({ cursor: 'pointer', justifyContent: 'center' })}
                    icon={faRotate}
                  />
                </Button>

              </Block>
            </Cell>
          </Grid>
        </Block>

        <Block
          paddingLeft="36px"
          paddingRight="36px"
        >
          <EmployeeDetailsDrawTable
            filteredDraws={filteredDraws}
            employeeID={employeeID}
            handlePageChange={handlePageChange}
            pageNumber={pageNumber}
            numPages={numPages}
            search={search}
          />
        </Block>
      </div>
    </AccordionSection>
  );
};

export default memo(EmployeeDetailsDrawSection);
