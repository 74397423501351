import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { Layer } from 'baseui/layer';
import { Cell, Grid } from 'baseui/layout-grid';
import AppModal from 'components/AppModal/AppModal';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useAppDispatch,
  useAppSelector,
} from 'store/hooks';
import {
  ModalNames,
  setModal,
} from 'store/slices/modals';
import { emptyPlaceholder } from 'theme';
import moment from 'moment';
import priceFormatter from 'utils/priceFormatter';
import { unScalePrice } from 'utils/priceScale';
import {
  tpoSearchPayrollPeriodSelector,
  tippedEmployeeBalanceOfferAreFetchedSelector,
  tippedEmployeeBalanceOfferGeneratedSelector,
  tippedEmployeeInfoSelector,
  tippedEmployeesSelectedLocationSelector,
} from 'store/slices/tpo';
import {
  LabelLarge,
  LabelMedium,
  ParagraphMedium,
  ParagraphSmall,
} from 'baseui/typography';
import AppGridTable from 'components/AppGridTable/AppGridTable';
import OfferCalculationListItem from 'components/OfferCalculationListItem';
import Loader from 'components/Loader';
import { TimeCard, Tip } from 'types/OfferTypes';
import {
  cellOverrides,
  MODAL_TABLE_STYLES,
  firstColumnWidthOverrides,
  secondColumnWidthOverrides,
} from './TipManagementHelper';

const EmployeeBalanceDetailsModal = () => {
  const theme = useStyletron()[1];
  const dispatch = useAppDispatch();
  const balanceOfferGenerated = useAppSelector(tippedEmployeeBalanceOfferGeneratedSelector);
  const balanceFetched = useAppSelector(tippedEmployeeBalanceOfferAreFetchedSelector);
  const { t } = useTranslation(['offers', 'tipsManagement', 'dateFormats']);
  const modalName = ModalNames.EMPLOYEE_BALANCE_DETAILS_MODAL;
  const dateFormat = t('dateFormats:standard');
  const timeFormat = t('dateFormats:standard-time');

  const selectedLocation = useAppSelector(tippedEmployeesSelectedLocationSelector);
  const selectedPayrollPeriod = useAppSelector(tpoSearchPayrollPeriodSelector);
  const tippedEmployee = useAppSelector(tippedEmployeeInfoSelector);

  const {
    balance,
    tipsEarnedToDate,
    tipsPayable,
    previousPaymentsAmount,
    data,
  } = balanceOfferGenerated || {};

  const {
    tips,
    timeCards,
  } = data || {};

  const unscaledTipsToDate = tipsEarnedToDate
    ? unScalePrice(tipsEarnedToDate?.value, tipsEarnedToDate?.scale)
    : 0;
  const unscaledTipsPayable = tipsPayable
    ? unScalePrice(tipsPayable?.value, tipsPayable?.scale)
    : 0;
  const unscaledPreviousPayout = previousPaymentsAmount
    ? unScalePrice(previousPaymentsAmount?.value, previousPaymentsAmount?.scale)
    : 0;
  const unscaledTipsDue = balance
    ? unScalePrice(balance?.value, balance?.scale)
    : 0;

  const setIsModalOpen = (
    isOpen: boolean,
  ) => {
    dispatch(setModal({
      name: modalName,
      isOpen,
    }));
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <Layer index={400}>
      <AppModal
        modalWidth={['90vw', '80vw', '70vw', '50vw']}
        minWidth="360px"
        maxWidth="100%"
        onClose={handleModalClose}
        modal={modalName}
        title={t('tipsManagement:balance.details.title')}
      >
        <Block
          marginTop="40px"
        >
          <Loader active={!balanceFetched} />
          <Grid
            gridColumns={12}
            gridMargins={16}
            gridGaps={16}
          >
            <Cell span={12} overrides={cellOverrides}>
              <Block>
                <ParagraphMedium display="inline-flex" overrides={firstColumnWidthOverrides}>
                  {t('offers:details.employeeName')}
                  :
                </ParagraphMedium>

                <ParagraphMedium display="inline-flex" overrides={secondColumnWidthOverrides}>
                  {tippedEmployee.name || emptyPlaceholder}
                </ParagraphMedium>
              </Block>
            </Cell>
            <Cell span={12} overrides={cellOverrides}>
              <ParagraphMedium display="inline-flex" overrides={firstColumnWidthOverrides}>
                {t('offers:details.locationName')}
                :
              </ParagraphMedium>
              <ParagraphMedium display="inline-flex" overrides={secondColumnWidthOverrides}>
                {selectedLocation[0].name || emptyPlaceholder}
              </ParagraphMedium>
            </Cell>
            <Cell span={12} overrides={cellOverrides}>
              <ParagraphMedium display="inline-flex" overrides={firstColumnWidthOverrides}>
                {t('offers:details.payPeriod')}
                :
              </ParagraphMedium>
              <ParagraphMedium display="inline-flex" overrides={secondColumnWidthOverrides}>
                {`${moment(selectedPayrollPeriod[0].startDate).format(dateFormat)} - ${moment(selectedPayrollPeriod[0].endDate).format(dateFormat)}`
                || emptyPlaceholder}
              </ParagraphMedium>
            </Cell>

            <Cell span={12} overrides={cellOverrides}>
              <ParagraphMedium display="inline-flex" overrides={firstColumnWidthOverrides}>
                {t('offers:details.payrollPeriodID')}
                :
              </ParagraphMedium>
              <ParagraphMedium display="inline-flex" overrides={secondColumnWidthOverrides}>
                {selectedPayrollPeriod[0].id || emptyPlaceholder}
              </ParagraphMedium>
            </Cell>

            <Block
              marginTop="48px"
            >
              <Cell span={12}>
                <LabelLarge>
                  {t('offers:details.balanceCalculation')}
                </LabelLarge>
              </Cell>
            </Block>

            <OfferCalculationListItem
              title={t('offers:details.tips.tipsToDate')}
              additional={priceFormatter().format(unscaledTipsToDate)}
            />

            <OfferCalculationListItem
              title={t('offers:details.tips.tipsPayable')}
              additional={priceFormatter().format(unscaledTipsPayable)}
            />
            <OfferCalculationListItem
              title={t('offers:details.tips.previousPayout')}
              additional={priceFormatter().format(unscaledPreviousPayout)}
            />

            <OfferCalculationListItem
              title={t('offers:details.tips.tipsDue')}
              additional={priceFormatter().format(unscaledTipsDue)}
            />

            {timeCards && timeCards.length > 0 && (
              <>
                <Block
                  marginTop="48px"
                >
                  <Cell span={12}>
                    <LabelLarge>
                      {t('offers:details.earningDetails.title')}
                    </LabelLarge>
                  </Cell>
                </Block>

                <Cell span={12}>
                  <AppGridTable
                    tableStyle={MODAL_TABLE_STYLES}
                    tableGridTemplateColumns={MODAL_TABLE_STYLES.gridTemplateColumns}
                    headingCells={[
                      <LabelMedium>{t('tipsManagement:tipsOffer.details.locationName')}</LabelMedium>,
                      <LabelMedium>{t('tipsManagement:tipsOffer.details.jobName')}</LabelMedium>,
                      <LabelMedium>{t('tipsManagement:tipsOffer.details.shift.businessDay')}</LabelMedium>,
                      <LabelMedium>{t('tipsManagement:tipsOffer.details.shift.startTime')}</LabelMedium>,
                      <LabelMedium>{t('tipsManagement:tipsOffer.details.shift.endTime')}</LabelMedium>,
                      <LabelMedium>{t('tipsManagement:tipsOffer.details.ccTips')}</LabelMedium>,
                      <LabelMedium>{t('tipsManagement:tipsOffer.details.servicesCharges')}</LabelMedium>,
                      <LabelMedium>{t('tipsManagement:tipsOffer.details.declaredTips')}</LabelMedium>,
                      <LabelMedium>{t('tipsManagement:tipsOffer.details.cashTips')}</LabelMedium>,
                      <LabelMedium>{t('tipsManagement:tipsOffer.details.tipsPool')}</LabelMedium>,
                    ]}
                    rows={timeCards?.map((timeCard: TimeCard) => {
                      const unscaledServiceChargePay = timeCard.serviceChargePay.value
                        ? unScalePrice(timeCard.serviceChargePay.value, timeCard.serviceChargePay.scale)
                        : 0;
                      const unscaledCreditedTips = timeCard.creditTipsPay.value
                        ? unScalePrice(timeCard.creditTipsPay.value, timeCard.creditTipsPay.scale)
                        : 0;
                      const unscaledDeclaredTips = timeCard?.declaredTipsPay?.value
                        ? unScalePrice(timeCard.declaredTipsPay.value, timeCard.declaredTipsPay.scale)
                        : 0;
                      const unscaledCashTips = timeCard?.cashTipsPay?.value
                        ? unScalePrice(timeCard.cashTipsPay.value, timeCard.cashTipsPay.scale)
                        : 0;
                      const unscaledTipsPool = timeCard?.tipsPoolPay?.value
                        ? unScalePrice(timeCard.tipsPoolPay.value, timeCard.tipsPoolPay.scale)
                        : 0;

                      return {
                        id: `${timeCard.location.id}-${timeCard.startedAt}-${timeCard.id}`,
                        cells: [
                          <ParagraphSmall color={theme.colors.contentSecondary}>{timeCard.location.name || emptyPlaceholder}</ParagraphSmall>,
                          <ParagraphSmall color={theme.colors.contentSecondary}>{timeCard.jobName || emptyPlaceholder}</ParagraphSmall>,
                          <ParagraphSmall color={theme.colors.contentSecondary}>
                            {moment(timeCard.startedAt).format(dateFormat) || emptyPlaceholder}
                          </ParagraphSmall>,
                          <ParagraphSmall color={theme.colors.contentSecondary}>
                            {moment(timeCard.startedAt).format(timeFormat) || emptyPlaceholder}
                          </ParagraphSmall>,
                          <ParagraphSmall color={theme.colors.contentSecondary}>
                            {moment(timeCard.endedAt).format(timeFormat) || emptyPlaceholder}
                          </ParagraphSmall>,
                          <ParagraphSmall color={theme.colors.contentSecondary}>
                            {priceFormatter().format(unscaledCreditedTips) || emptyPlaceholder}
                          </ParagraphSmall>,
                          <ParagraphSmall color={theme.colors.contentSecondary}>
                            {priceFormatter().format(unscaledServiceChargePay) || emptyPlaceholder}
                          </ParagraphSmall>,
                          <ParagraphSmall color={theme.colors.contentSecondary}>
                            {priceFormatter().format(unscaledDeclaredTips) || emptyPlaceholder}
                          </ParagraphSmall>,
                          <ParagraphSmall color={theme.colors.contentSecondary}>
                            {priceFormatter().format(unscaledCashTips) || emptyPlaceholder}
                          </ParagraphSmall>,
                          <ParagraphSmall color={theme.colors.contentSecondary}>
                            {priceFormatter().format(unscaledTipsPool) || emptyPlaceholder}
                          </ParagraphSmall>,
                        ],
                      };
                    }) || []}
                  />
                </Cell>
              </>
            )}

            {tips && tips.length > 0 && (
              <>

                <Block
                  marginTop="48px"
                >
                  <Cell span={12}>
                    <LabelLarge>
                      {t('offers:details.thirdPartyTips.title')}
                    </LabelLarge>
                  </Cell>
                </Block>

                <Cell span={12}>
                  <AppGridTable
                    tableGridTemplateColumns="minmax(30%, max-content) minmax(70%, max-content)"
                    headingCells={[
                      <LabelMedium>{t('offers:details.tips.businessDay.header')}</LabelMedium>,
                      <LabelMedium>{t('offers:details.tips.tipsEarned.header')}</LabelMedium>,
                    ]}
                    rows={tips?.map((tip: Tip, index: any) => {
                      const unscaledTotalTips = tip?.totalTipsAmount?.value
                        ? unScalePrice(tip.totalTipsAmount.value, tip.totalTipsAmount.scale)
                        : 0;

                      return {
                        id: `${tip.businessDate.toString()}-${index}`,
                        cells: [
                          <ParagraphSmall color={theme.colors.contentSecondary}>
                            {moment(tip?.businessDate).format(dateFormat) || emptyPlaceholder}
                          </ParagraphSmall>,
                          <ParagraphSmall color={theme.colors.contentSecondary}>
                            {priceFormatter().format(unscaledTotalTips) || emptyPlaceholder}
                          </ParagraphSmall>,
                        ],
                      };
                    }) || []}
                  />
                </Cell>
              </>
            )}
          </Grid>
        </Block>
      </AppModal>
    </Layer>
  );
};

export default memo(EmployeeBalanceDetailsModal);
