import { TFunction } from 'i18next';
import moment from 'moment';
import { SearchDropDown } from 'types/CommonTypes';
import {
  PaymentDetailsType,
  RegisterPaycardRequestParamsType,
} from 'types/PaycardManagementTypes';

export const fetchOrganizationPaycardsFilterMapper = (
  value: string,
  searchOption: SearchDropDown,
): string => {
  switch (searchOption.id) {
    case 0:
      return `name=${value}`;
    case 1:
      return `payrollNumber=${value}`;
    case 2:
      return `proxyNumber=${value}`;
    default:
      return '';
  }
};

export const registerPaycardMapper = (
  employeeDetails: any,
  paymentDetails: PaymentDetailsType,
): RegisterPaycardRequestParamsType => ({
  proxyNumber: paymentDetails.proxyNumber,
  firstName: employeeDetails.firstName,
  lastName: employeeDetails.lastName,
  payrollNumber: employeeDetails.payrollId,
  dateOfBirth: moment(employeeDetails?.birthDate[0]).format('YYYY-MM-DD'),
  ssn: employeeDetails.ssn,
  phone: employeeDetails.mobileNumber.replaceAll('-', ''),
  address: {
    country: 'US',
    state: employeeDetails.state[0].value,
    city: employeeDetails.city,
    postalCode: employeeDetails.zip,
    addressLine1: employeeDetails.address,
    addressLine2: employeeDetails.unit || undefined,
  },
});

export const loadFundsMapper = (
  {
    funds,
    paymentDescription,
    internalNotes,
  }: PaymentDetailsType,
  t: TFunction,
  organizationName?: string,
): any => ({
  amount: funds,
  currencyCode: 'USD',
  description: paymentDescription
  || t('paycardManagement:paycardManagement.registerCard.pairCard.paymentDescription.placeholder', { orgName: `${organizationName || ''}` }),
  internalNotes,
});
