import {
  faCheck,
  faCircleExclamation,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Block } from 'baseui/block';
import {
  ALIGNMENT,
  Cell,
  Grid,
} from 'baseui/layout-grid';
import {
  Tag,
  VARIANT,
} from 'baseui/tag';
import {
  LabelMedium,
  LabelSmall,
  ParagraphSmall,
} from 'baseui/typography';
import AppGridTable from 'components/AppGridTable/AppGridTable';
import Loader from 'components/Loader';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { emptyPlaceholder } from 'theme';
import priceFormatter from 'utils/priceFormatter';
import { unScalePrice } from 'utils/priceScale';
import { useStyletron } from 'baseui';
import { memo } from 'react';
import {
  useAppDispatch,
  useAppSelector,
} from 'store/hooks';
import { Card } from 'baseui/card';
import {
  Draw,
  SubElementStatusType,
  SubElementType,
  SubElementTypeEnum,
} from 'types/EmployeeTypes';
import { employeesTPODrawsPendingListSelector } from 'store/slices/employees';
import { fetchTippedEmployeeBalanceOfferById } from 'store/slices/tpo';
import {
  ModalNames,
  modalsSelector,
  setModal,
} from 'store/slices/modals';
import checkIsModalOpen from 'utils/checkIsModalOpen';
import TippedEmployeeTPOPaymentsOfferModal from 'screens/TipManagement/EmployeeTipOverviewTPOPayments/TippedEmployeeTPOPaymentsOfferModal';
import {
  Pagination,
  SIZE,
} from 'baseui/pagination';
import { paginationTransparentOverrides } from 'screens/CommonHelpers';
import { Value } from 'baseui/select';
import { checkIfSubElementStatusTypeIsPositive } from 'screens/Employees/EmployeesHelpers';

export const PAYMENTS_TABLE_STYLES = {
  // eslint-disable-next-line max-len
  gridTemplateColumns: 'minmax(7%, max-content) minmax(20%, max-content) minmax(15%, max-content) minmax(15%, max-content) minmax(15%, max-content) minmax(10%, max-content) minmax(18%, max-content)',
  '@media (max-width: 1200px)': {
    gridTemplateColumns: '100px 250px 150px 150px 150px 100px 300px',
  },
};

export type EmployeeDetailsTPODrawTablePropsType = {
  filteredDraws: Draw[],
  payPeriod: Value | undefined,
  handlePageChange: ({ nextPage }: { nextPage: number }) => void,
  pageNumber: number,
  numPages: number,
}

export const extractAmountItem = (subElements: SubElementType[]) => (
  subElements?.find((subElement) => subElement.type === SubElementTypeEnum.TCO_RESEND
    && checkIfSubElementStatusTypeIsPositive(subElement.status))
    ?? subElements?.find((subElement) => subElement.type === SubElementTypeEnum.TCO_REQUEST)
);

const EmployeeDetailsTPODrawsTable = ({
  filteredDraws,
  payPeriod,
  handlePageChange,
  pageNumber,
  numPages,
}: EmployeeDetailsTPODrawTablePropsType) => {
  const [css, theme] = useStyletron();
  const { t } = useTranslation(['tipsManagement', 'dateFormats']);
  const dateFormat = t('dateFormats:standard-day-of-week-and-year');
  const dispatch = useAppDispatch();
  const pending = useAppSelector(employeesTPODrawsPendingListSelector);
  const modals = useAppSelector(modalsSelector);

  const NEUTRAL = {
    tagColor: theme.colors.backgroundTertiary,
    labelColor: theme.colors.contentSecondary,
  };

  const WARNING = {
    tagColor: theme.colors.backgroundWarningLight,
    labelColor: '#674D1B',
  };

  const EMPLOYEE_TPO_STATUS_TO_TAG_CONFIGS: {
    [index: string]: { tagColor: string, labelColor: string },
  } = {
    OK: NEUTRAL,
    EMPLOYEE_NOT_REGISTERED: WARNING,
    PAYMENT_PROCESSING: WARNING,
    PAYMENT_FAILED: WARNING,
    NON_TCO_EMPLOYMENT: WARNING,
    TCO_DEFAULT_ACCOUNT_NOT_SELECTED: WARNING,
  };

  const headingCells = [
    <LabelMedium>{t('tipsManagement:table.header.status.label')}</LabelMedium>,
    <LabelMedium>{t('tipsManagement:table.header.date.label')}</LabelMedium>,
    <LabelMedium>{t('tipsManagement:table.header.earnedToDay.label')}</LabelMedium>,
    <LabelMedium>{t('tipsManagement:table.header.payableToDay.label')}</LabelMedium>,
    <LabelMedium>{t('tipsManagement:table.header.paidThisDay.label')}</LabelMedium>,
    <LabelMedium>{t('tipsManagement:table.header.offerID.label')}</LabelMedium>,
    <LabelMedium>{t('tipsManagement:table.header.paymentInfo.label')}</LabelMedium>,
  ];

  const handleOfferIDClick = (selectedOffer: number | undefined) => {
    dispatch(fetchTippedEmployeeBalanceOfferById({ offerID: String(selectedOffer) }));
    dispatch(setModal({
      name: ModalNames.EMPLOYEE_TPO_OFFER_PER_LOCATION_DETAILS_MODAL,
      isOpen: true,
    }));
  };

  const drawsAmountSum = filteredDraws?.reduce((acc, draw) => {
    const amountElement = draw?.subElements.find((subElement: any) => subElement.type === SubElementTypeEnum.TCO_REQUEST);
    if (amountElement
      && (amountElement.status === SubElementStatusType.VOIDED
        || amountElement.status === SubElementStatusType.DECLINED
        || amountElement.status === SubElementStatusType.NOT_PAID)) {
      acc.sum += 0;
    } else if (amountElement) {
      acc.sum += unScalePrice(amountElement?.amount?.value, amountElement?.amount?.scale);
    } else {
      acc.sum += 0;
    }

    return acc;
  }, { sum: 0 });

  return (
    <>
      <Block marginTop="32px" marginLeft="-24px">
        <Loader active={pending} />
        <Grid>
          <Cell
            span={[12]}
            align={ALIGNMENT.center}
            overrides={{
              Cell: {
                style: {
                  marginBottom: '20px',
                },
              },
            }}
          >
            {filteredDraws && filteredDraws.length > 0 ? (
              <>
                <AppGridTable
                  tableStyle={PAYMENTS_TABLE_STYLES}
                  tableGridTemplateColumns={PAYMENTS_TABLE_STYLES.gridTemplateColumns}
                  headingCells={headingCells}
                  rows={filteredDraws?.map((payment: any) => {
                    const statusStyles = EMPLOYEE_TPO_STATUS_TO_TAG_CONFIGS[payment.offer.tcoStatus as keyof typeof EMPLOYEE_TPO_STATUS_TO_TAG_CONFIGS];
                    const tpoSubElement = extractAmountItem(payment?.subElements);
                    const tpoSubElementStatusNotOK = tpoSubElement?.status === SubElementStatusType.VOIDED
                      || tpoSubElement?.status === SubElementStatusType.DECLINED
                      || tpoSubElement?.status === SubElementStatusType.NOT_PAID;

                    return (
                      {
                        id: payment?.offer?.id,
                        cells: [
                          <ParagraphSmall color={theme.colors.contentSecondary}>
                            { payment?.offer?.tcoStatus === 'OK' ? (
                              <FontAwesomeIcon
                                className={css({ color: '#048848' })}
                                icon={faCheck}
                              />
                            ) : (
                              <FontAwesomeIcon
                                className={css({ color: '#FFC043' })}
                                icon={faCircleExclamation}
                              />
                            )}
                          </ParagraphSmall>,
                          <ParagraphSmall color={tpoSubElementStatusNotOK ? '#AFAFAF' : theme.colors.contentSecondary}>
                            {moment(payment.drawRequestedDate).format(dateFormat)}
                          </ParagraphSmall>,
                          <ParagraphSmall color={tpoSubElementStatusNotOK ? '#AFAFAF' : theme.colors.contentSecondary}>
                            {priceFormatter().format(unScalePrice(payment?.offer?.earningsToDate?.value))}
                          </ParagraphSmall>,
                          <ParagraphSmall color={tpoSubElementStatusNotOK ? '#AFAFAF' : theme.colors.contentSecondary}>
                            {priceFormatter().format(unScalePrice(payment?.offer?.ewaAvailable?.value))}
                          </ParagraphSmall>,
                          <ParagraphSmall color={tpoSubElementStatusNotOK ? '#AFAFAF' : theme.colors.contentSecondary}>
                            {priceFormatter().format(unScalePrice(tpoSubElement?.amount.value || 0))}
                          </ParagraphSmall>,
                          <ParagraphSmall
                            onClick={() => handleOfferIDClick(payment?.offer?.id)}
                            color="#CC0033"
                            overrides={{
                              Block: {
                                style: {
                                  cursor: 'pointer',
                                  textDecorationLine: 'underline',
                                },
                              },
                            }}
                          >
                            {payment?.offer?.id || emptyPlaceholder}
                          </ParagraphSmall>,
                          <Tag
                            closeable={false}
                            variant={VARIANT.solid}
                            overrides={{
                              Root: {
                                style: {
                                  backgroundColor: statusStyles.tagColor,
                                  position: 'absolute',
                                },
                              },
                              Text: {
                                style: {
                                  maxWidth: 'unset',
                                },
                              },
                            }}
                          >
                            <LabelSmall
                              color={statusStyles.labelColor}
                              width="fit-content"
                            >
                              {t(
                                `tipsManagement:employee.payments.statuses.${tpoSubElement?.status}`,
                                {
                                  recipientDetails: `${tpoSubElement?.recipient?.accountNickname || ''} ${tpoSubElement?.recipient?.accountLastFour || ''}`,
                                  TPOOfferStatus: `${t(`tipsManagement:statuses.${payment.offer.tcoStatus}`)}`,
                                },
                              )}
                            </LabelSmall>
                          </Tag>,
                        ],
                      }
                    );
                  })}
                />
                {payPeriod && payPeriod.length > 0 ? (
                  <Block
                    marginBottom="8px"
                  >
                    <Grid gridMargins={0}>
                      <Cell span={12}>
                        <Card
                          overrides={{
                            Root: {
                              style: {
                                backgroundColor: '#EEEEEE',
                                borderRadius: '0',
                              },
                            },
                          }}
                        >
                          <LabelMedium>
                            {t('employee.paidTips.label')}
                            {': '}
                            {priceFormatter().format(drawsAmountSum?.sum)}
                          </LabelMedium>
                        </Card>
                      </Cell>
                    </Grid>
                  </Block>
                )
                  : (
                    <Block
                      display="flex"
                      width="100%"
                      alignItems="center"
                      justifyContent="center"
                      justifyItems="center"
                      marginBottom="16px"
                    >
                      <Pagination
                        size={SIZE.compact}
                        numPages={numPages}
                        currentPage={pageNumber}
                        overrides={paginationTransparentOverrides}
                        onPageChange={handlePageChange}
                      />
                    </Block>
                  )}
              </>
            ) : (
              <Block
                marginTop="8px"
                marginBottom="8px"
              >
                <Grid gridMargins={0}>
                  <Cell span={12}>
                    <Card>
                      {t('tipsManagement:employee.payments.noResults')}
                    </Card>
                  </Cell>
                </Grid>
              </Block>
            )}
          </Cell>
        </Grid>
      </Block>
      {checkIsModalOpen(modals, ModalNames.EMPLOYEE_TPO_OFFER_PER_LOCATION_DETAILS_MODAL) && <TippedEmployeeTPOPaymentsOfferModal />}
    </>
  );
};

export default memo(EmployeeDetailsTPODrawsTable);
