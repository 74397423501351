import moment from 'moment';
import { RecoverFundsValuesType } from 'screens/Treasury/TreasuryWorkerAccounts/WorkerAccountTransactions/WorkerAccountTransactionsRecoverFundsModal';
import { RepaymentsValuesType, SaveRepaymentRequestType } from 'types/RepaymentTypes';
import { RecoverFundsRequestType } from 'types/WorkerAccountTypes';
import { scalePrice } from 'utils/priceScale';

export const saveRepaymentsMapper = (
  currencyCode: string,
  scale: number,
  values: RepaymentsValuesType,
): SaveRepaymentRequestType => ({
  transactionId: values?.referenceID,
  amount: {
    currencyCode,
    scale,
    value: scalePrice(values?.paymentAmount),
  },
  dateReceived: moment(values?.dateReceived[0]).format('YYYY-MM-DD'),
});

export const recoverFundsMapper = (
  currencyCode: string,
  scale: number,
  values: RecoverFundsValuesType,
): RecoverFundsRequestType => ({
  amount: {
    currencyCode,
    scale,
    value: Math.round(scalePrice(values?.amount)),
  },
  zendeskTicket: values?.zendeskTicketReference,
  recoverFundsDetails: values?.recoverFundsDetails,
});

export const other = undefined;
