import { TransactionsStatus } from 'types/MasterAccountTypes';
import request from './request';

export const fetchMasterAccountBalanceRequest = (
  token: string,
) => request({
  token,
  url: 'master-account/balance',
});

export const fetchMasterAccountTransactionsRequest = (
  token: string,
  page: string,
  transactionStatus?: TransactionsStatus,
  transactionType?: string,
  transactionNumber?: string,
  startDate?: string,
  endDate?: string,
) => {
  const queryParams = new URLSearchParams({
    page,
  });
  if (transactionStatus) {
    queryParams.set('transactionStatus', transactionStatus);
  }
  if (transactionType) {
    queryParams.set('transactionType', transactionType);
  }
  if (transactionNumber) {
    queryParams.set('transactionNumber', transactionNumber);
  }
  if (startDate) {
    queryParams.set('fromDate', startDate);
  }
  if (endDate) {
    queryParams.set('toDate', endDate);
  }
  return request({
    token,
    url: `master-account/transactions?${queryParams.toString()}`,
  });
};
