export const employeeDetailsInitialValues = {
  payrollId: '',
  firstName: '',
  lastName: '',
  birthDate: '',
  ssn: '',
  mobileNumber: '',
  address: '',
  unit: '',
  city: '',
  state: [{ value: '' }],
  zip: '',
};

export const employeePaymentDetailsInitialValues = {
  funds: '',
  proxyNumber: '',
  paymentDescription: '',
  internalNotes: '',
};
