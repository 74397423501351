import { WorkerIDType } from 'types/WorkerTypes';
import { MoneyDtoType } from './CommonTypes';
import {
  MerchantType, TransactionOrigin, TransactionSourceType, TransactionsStatus,
} from './MasterAccountTypes';

export type FetchWorkerAccountExternalParamsType = {
  workerID: WorkerIDType
};

export type fetchWorkerAccountBalancePropsType = {
  workerID: WorkerIDType
};
export type FetchWorkerAccountTransactionsPropsType = {
  workerID?: WorkerIDType
  startDate: string
  endDate: string
  transactionStatus?: TransactionsStatus
  transactionType?: string
  transactionNumber?: string
}

export type RecoverFundsRequestType = {
  amount: MoneyDtoType
  zendeskTicket: string
  recoverFundsDetails?: string
}

export type RecoverFundsParamsType = {
  workerID: WorkerIDType
  data: RecoverFundsRequestType
}

export enum AccountType {
  CARD = 'CARD',
  EXTERNAL_CARD = 'EXTERNAL_CARD',
  EXTERNAL_BANK_ACCOUNT = 'EXTERNAL_BANK_ACCOUNT',
}

export enum BankAccountStatus {
  UNKNOWN = 'UNKNOWN',
  ACTIVE = 'ACTIVE',
  BLOCKED = 'BLOCKED',
  INACTIVE = 'INACTIVE',
}

export enum VerificationStatus {
  UNKNOWN = 'UNKNOWN',
  PENDING = 'PENDING',
  FAILED = 'FAILED',
  VERIFIED = 'VERIFIED',
  PRE_VERIFIED = 'PRE_VERIFIED',
}

export type ExternalAccountType = {
  id: string,
  nickname: string,
  lastFour: string,
  type: AccountType,
  status: BankAccountStatus,
  verificationStatus: VerificationStatus
}

export type WorkerTransactionsValueType = {
  isoMessageType?: string,
  source?: TransactionSourceType,
  id?: string,
  workerId?: string,
  type?: string,
  status: TransactionsStatus,
  amount: number,
  currencyCode?: string,
  merchant?: MerchantType,
  transactionNumber?: string,
  transactionDate?: string,
  origin?: TransactionOrigin,
  ledgerId?: string,
  subElementId?: string,
  description?: string
}

export type WorkerAccountTransactionsDetailsModalPropsType = {
  transaction?: WorkerTransactionsValueType
}
