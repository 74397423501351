import {
  SaveOrganizationConfigsRequestType,
  ConfigType,
  EditEmployeeDetailsRequestType,
} from 'types/OrganizationTypes';
import {
  EmployeeProfileResponseType,
  EmployeeDetailsProfileValuesType,
  EmployeeResponseType,
  EmployeeValuesType,
  EmployeeDetailsOnDemandPayValuesType,
  PayRatesResponseType,
  PayRatesDataValuesType,
  PayRateValuesType,
} from 'types/EmployeeTypes';
import { scalePrice, unScalePrice } from 'utils/priceScale';
import { TFunction } from 'react-i18next';

export const saveEmployeesConfigsMapper = (
  values: EmployeeValuesType,
): SaveOrganizationConfigsRequestType => (
  [
    {
      configKeyName: ConfigType.OFFER_PERCENT,
      configValue: values?.offerPercentPerEmployee?.toString() || '',
    },
    {
      configKeyName: ConfigType.TPO_PERCENT,
      configValue: values?.tpoOfferPercentPerEmployee?.toString() || '',
    },
  ]
);

export const saveEmployeeDetailsMapper = (
  values: EmployeeValuesType,
  employee: EmployeeResponseType,
): EditEmployeeDetailsRequestType => ({
  ...employee,
  status: values?.enrollmentStatus && values?.enrollmentStatus[0]?.value,
  lowerEarningsThreshold: { ...employee.lowerEarningsThreshold, value: scalePrice(values.lowerEarningsThreshold) },
  ewaTypeOverridden: values?.employeeType[0].value || null,
});

export const saveEmployeeDetailsProfileMapper = (
  values: EmployeeDetailsProfileValuesType,
  employee: EmployeeResponseType,
): EditEmployeeDetailsRequestType => ({
  ...employee,
  status: values?.enrollmentStatus && values?.enrollmentStatus[0]?.value,
});

export const saveEmployeeDetailsOnDemandPayMapper = (
  values: EmployeeDetailsOnDemandPayValuesType,
  employee: EmployeeResponseType,
): EditEmployeeDetailsRequestType => ({
  ...employee,
  lowerEarningsThreshold: { ...employee.lowerEarningsThreshold, value: scalePrice(values.lowerEarningsThreshold) },
  ewaTypeOverridden: values?.employeeType[0].value || null,
});

export const employeeDetailsMapper = ({
  id,
  organization,
  status,
  payrollId,
  lowerEarningsThreshold,
  // assignments,
}: EmployeeResponseType): EmployeeValuesType => ({
  id,
  organization,
  lowerEarningsThreshold: unScalePrice(lowerEarningsThreshold?.value),
  enrollmentStatus: [{ value: status?.toString() || '' }],
  payrollEmployeeId: payrollId?.toString() || '',
  linkedAccounts: [],
  locations: [],
  // offerPercentPerEmployee: assignments?.find((i) => i.primaryLocation)?.location?.offerPercent || 0,
});

export const employeeDetailsProfileMapper = ({
  id,
  organization,
  status,
  payrollId,
  enrollmentDate,
}: EmployeeProfileResponseType): EmployeeDetailsProfileValuesType => ({
  id,
  organization,
  enrollmentStatus: [{ value: status?.toString() || '' }],
  payrollEmployeeId: payrollId?.toString() || '',
  linkedAccounts: [],
  locations: [],
  enrollmentDate,
});

export const employeeDetailsOnDemandPayMapper = (
  {
    id,
    organization,
    lowerEarningsThreshold,
    ewaTypeOverridden,
    ewaType,
  } : EmployeeResponseType,
  t: TFunction,
): EmployeeValuesType => ({
  id,
  organization,
  lowerEarningsThreshold: unScalePrice(lowerEarningsThreshold?.value),
  employeeType: [
    {
      value: ewaTypeOverridden || ewaType,
      label: t(`employees:employeeDetails.employeeType.${ewaTypeOverridden || ewaType}`),
    }],
});

export const employmentPayRateDataMapper = ({
  id,
  organization,
  contractType,
  ewaType,
  ewaTypeOverridden,
  contractedHours,
  contractedHoursOverridden,
  workDays,
}: PayRatesResponseType): PayRatesDataValuesType => ({
  id,
  organization,
  contractType,
  ewaType: ewaTypeOverridden || ewaType,
  ewaTypeOverridden,
  contractedHours: contractedHoursOverridden || contractedHours,
  contractedHoursOverridden,
  workDays,
});

export const employmentPayRatesMapper = ({
  payRates,
  payRateOverridden,
  payRatesOverridden,
  // workDays,
}: PayRatesResponseType): PayRateValuesType => (
  {
    payRatePeriod: payRateOverridden
      ? unScalePrice(
        payRatesOverridden.find((rate:any) => rate.rateType === 'PERIOD')?.amount.value,
        payRatesOverridden.find((rate:any) => rate.rateType === 'PERIOD')?.amount.scale,
      )
      : unScalePrice(
        payRates.find((rate:any) => rate.rateType === 'PERIOD')?.amount.value,
        payRates.find((rate:any) => rate.rateType === 'PERIOD')?.amount.scale,
      ),
    payRateYearly: payRateOverridden
      ? unScalePrice(
        payRatesOverridden.find((rate:any) => rate.rateType === 'YEARLY')?.amount.value,
        payRatesOverridden.find((rate:any) => rate.rateType === 'YEARLY')?.amount.scale,
      )
      : unScalePrice(
        payRates.find((rate:any) => rate.rateType === 'YEARLY')?.amount.value,
        payRates.find((rate:any) => rate.rateType === 'YEARLY')?.amount.scale,
      ),
    payRateHourly: payRateOverridden
      ? unScalePrice(
        payRatesOverridden.find((rate:any) => rate.rateType === 'HOURLY')?.amount.value,
        payRatesOverridden.find((rate:any) => rate.rateType === 'HOURLY')?.amount.scale,
      )
      : unScalePrice(
        payRates.find((rate:any) => rate.rateType === 'HOURLY')?.amount.value,
        payRates.find((rate:any) => rate.rateType === 'HOURLY')?.amount.scale,
      ),
  });
