import { borderRadius } from 'theme';
import { StyleObject } from 'styletron-react';

export const blockOverrides = {
  Block: {
    style: {
      background: '#F3F3F3',
    },
  },
};

export const tableStyles = {
  border: '1px solid #ccc',
  overflow: 'auto',
};

export const tableHeaderStyles = {
  paddingTop: '8px',
  paddingBottom: '8px',
  borderBottom: '1px solid #ccc',
  minWidth: '960px',
  overflow: 'hidden',
  'box-sizing': 'border-box',
};

export const footerStyles = {
  paddingTop: '8px',
  paddingBottom: '8px',
  minWidth: '960px',
  overflow: 'hidden',
  'box-sizing': 'border-box',
};

export const accordionAllocationListItemOverrides = {
  Content: {
    style: {
      backgroundColor: '#fff',
      position: 'relative',
      zIndex: 0,
    } as StyleObject,
  },
  Header: {
    style: {
      backgroundColor: 'transparent',
    },
  },
  ToggleIcon: {
    style: {
      display: 'flex',
      width: '16px',
      height: '16px',
      marginLeft: 'auto',
      marginTop: '-70px',
      zIndex: 1000,
      position: 'relative',
    } as StyleObject,
  },
};

export const accordionEmployeeListItemOverrides = {
  Content: {
    style: {
      backgroundColor: '#fff',
    },
  },
  ToggleIcon: {
    style: {
      display: 'flex',
      width: '16px',
      height: '16px',
      marginLeft: 'auto',
      marginTop: '-58px',
      zIndex: 1000,
      position: 'relative',
    } as StyleObject,
  },
};

export const checkboxOverrides = {
  Root: {
    style: {
      display: 'inline-flex',
      marginBottom: '5px',
      verticalAlign: 'middle',
    },
  },
};

export const outerContainerStyles = {
  marginTop: '32px',
  overflow: 'hidden',
  position: 'relative',
} as StyleObject;

export const listContainerStyles = {
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  position: 'relative',
  minHeight: '120px',
} as StyleObject;

export const allocationsListContainerStyles = {
  width: '100%',
  height: '100%',
  position: 'relative',
  minWidth: '960px',
  overflow: 'hidden',
  zIndex: 0,
} as StyleObject;

export const negativeStyles = {
  color: '#dc3131',
};

export const containerStyles = {
  width: '100%',
  minHeight: '75vh',
  position: 'relative',
  overflow: 'hidden',
  zIndex: 100,
  'box-sizing': 'border-box',
} as StyleObject;

export const listStyles = {
  padding: '0px',
};

export const listItemStyles = {
  background: '#FFFFFF',
  'list-style': 'none',
  padding: '8px',
  borderBottomLeftRadius: borderRadius,
  borderBottomRightRadius: borderRadius,
  borderTopLeftRadius: borderRadius,
  borderTopRightRadius: borderRadius,
};

export const customerListItemNameAndStatusesStyles = {
  display: 'flex',
  'flex-direction': 'row',
  'justify-content': 'space-between',
  alignItems: 'center',
  padding: '4px',
  '@media screen and (max-width: 1200px)': {
    'flex-direction': 'column',
  },
};

export const customerListItemTitleStyles = {
  display: 'flex',
  'flex-direction': 'row',
  'justify-content': 'space-between',
  alignItems: 'center',
  padding: '16px 4px 16px 16px',
  '@media screen and (max-width: 1200px)': {
    alignSelf: 'start',
    'overflow-wrap': 'break-word',
    'word-break': 'break-all',
    padding: '16px',
  },
};

export const statusAndMenuStyle = {
  '@media screen and (max-width: 1200px)': {
    alignSelf: 'flex-end',
    paddingBottom: '16px',
  },
};

export const listItemInfoStyles = {
  display: 'flex',
  'flex-direction': 'row',
  'justify-content': 'space-between',
  alignItems: 'center',
  padding: '0px 4px 16px 20px',
};

export const detailsStyles = {
  marginRight: '16px',
  display: 'inline-block',
  marginTop: '4px',
};

const STATUS_COLOR_MAP: {[index: string]: string} = {
  NEW: 'rgb(146 198 243)',
  SENT_TO_PAYROLL: '#E0F1E5',
  PAID_IN_FULL: 'rgb(153 243 146)',
  PARTIALLY_PAID: 'rgb(241 243 146)',
  CANCELED: '#E0F1E5',
  FAILED: '#E0F1E5',
};

export const statusBadgeStyles = (status: string) => ({
  background: STATUS_COLOR_MAP[status],
  borderBottomLeftRadius: borderRadius,
  borderBottomRightRadius: borderRadius,
  borderTopLeftRadius: borderRadius,
  borderTopRightRadius: borderRadius,
  padding: '7px',
  'margin-left': '10px',
  color: '#4A5456',
  'font-weight': '700',
  'font-size': '14px',
});

export const exportIconStyle = {
  cursor: 'pointer',
  padding: '0 10px',
  color: 'rgb(187, 0, 47)',
  '@media screen and (max-width: 1200px)': {
    marginTop: '16px',
  },
};
