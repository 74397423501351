import { StyleObject } from 'styletron-react';
import { borderRadius } from 'theme';
import {
  EditAdministratorFormType,
  CreateAdministratorFormType,
} from 'types/AdministratorsTypes';

export const blockOverrides = {
  Block: {
    style: {
      marginTop: '8px',
      marginBottom: '8px',
      lineHeight: '30px',
      fontWeight: 500,
      color: '#5F6368',
      fontSize: '12px',
    },
  },
};
export const containerStyles = {
  width: '100%',
  minHeight: '70vh',
  position: 'relative',
  overflow: 'hidden',
  zIndex: 100,
  'box-sizing': 'border-box',
  overFlow: 'hidden',
} as StyleObject;

export const listStyles = {
  padding: '0px',
};

export const listItemStyles = {
  background: '#FFFFFF',
  'list-style': 'none',
  padding: '8px',
  borderBottomLeftRadius: borderRadius,
  borderBottomRightRadius: borderRadius,
  borderTopLeftRadius: borderRadius,
  borderTopRightRadius: borderRadius,
};

export const listItemNameAndStatuses = {
  display: 'flex',
  'flex-direction': 'row',
  'justify-content': 'space-between',
  alignItems: 'center',
  padding: '8px',
};

export const listItemInfoStyles = {
  display: 'flex',
  'flex-direction': 'row',
  'justify-content': 'space-between',
  alignItems: 'center',
  padding: '0px 4px 16px 20px',
};

export const detailsStyles = {
  display: 'inline-block',
  marginRight: '16px',
  marginTop: '4px',
};

export const rolePropToRoleValue: {
  [index: string]: string,
} = {
  ewaManager: 'EWA_MANAGER',
  ewaClientManager: 'EWA_CLIENT_MANAGER',
  treasuryManager: 'TREASURY_MANAGER',
  treasuryReader: 'TREASURY_READER',
  fboManager: 'FBO_MANAGER',
  fboReader: 'FBO_READER',
  tipsReader: 'TIPS_READER',
  ewaClientLocationManager: 'EWA_CLIENT_LOCATION_MANAGER',
  tipsClientManager: 'TIPS_CLIENT_MANAGER',
  fddClientManager: 'FDD_CLIENT_MANAGER',
  ewaReport: 'EWA_REPORT',
};

export const roleValueToRoleProp: {
  [index: string]: string,
} = {
  EWA_MANAGER: 'ewaManager',
  EWA_CLIENT_MANAGER: 'ewaClientManager',
  TREASURY_MANAGER: 'treasuryManager',
  TREASURY_READER: 'treasuryReader',
  FBO_MANAGER: 'fboManager',
  FBO_READER: 'fboReader',
  TIPS_READER: 'tipsReader',
  EWA_CLIENT_LOCATION_MANAGER: 'ewaClientLocationManager',
  TIPS_CLIENT_MANAGER: 'tipsClientManager',
  FDD_CLIENT_MANAGER: 'fddClientManager',
  EWA_REPORT: 'ewaReport',
};

export const POSSIBLE_ADMIN_ROLE_VALUES = [
  'ewaManager',
  'treasuryManager',
  'fboManager',
  'treasuryReader',
  'fboReader',
  'fddClientManager',
] as string[];

export const checkForAdminRules = (values: EditAdministratorFormType | CreateAdministratorFormType) => Object
  .keys(values)
  .reduce((acc, key) => {
    const checkedValue = values[key as keyof typeof values];

    // Check if some role is checked
    if ([...POSSIBLE_ADMIN_ROLE_VALUES, 'ewaClientManager']?.includes(key) && checkedValue) {
      // Check if checked role is `ewaClientManager` and organization is missing
      if ((key === 'ewaClientManager' || key === 'tipsClientManager' || key === 'tipsReader' || key === 'fddClientManager')
      && !(values?.organizationID && values?.organizationID?.length > 0 && values?.organizationID?.[0].value !== '')) return acc;

      acc.hasRules = !!checkedValue;
    }

    // Check if some role is checked
    if ([...POSSIBLE_ADMIN_ROLE_VALUES, 'ewaClientLocationManager']?.includes(key) && checkedValue) {
      // Check if checked role is `ewaClientLocationManager` and organization is missing
      if ((key === 'ewaClientLocationManager' || key === 'tipsClientManager')
       && (!(values?.organizationID && values?.organizationID?.length > 0 && values?.organizationID?.[0].value !== '')
       || (!(values?.locationID && values?.locationID?.length > 0 && values?.locationID?.[0].value !== '')))) return acc;

      acc.hasRules = !!checkedValue;
    }

    return acc;
  }, { hasRules: false });
