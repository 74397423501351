import {
  ALIGNMENT,
  Cell,
  Grid,
} from 'baseui/layout-grid';
import { LabelMedium } from 'baseui/typography';
import AppInput from 'components/Form/AppInput';
import {
  locationConfigMapper,
  locationMapper,
  saveLocationConfigMapper,
} from 'dataMappers/locationsDataMapper';
import { organizationConfigMapper } from 'dataMappers/organizationsDataMappers';
import {
  FormikHandlers,
  FormikHelpers,
  FormikState,
  useFormik,
} from 'formik';
import { locationInitialValues } from 'initialValues/LocationsInitialValues';
import {
  createContext,
  memo,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  useAppDispatch,
  useAppSelector,
} from 'store/hooks';
import {
  editLocationConfigs,
  fetchLocations,
  locationConfigSelector,
  locationCreatedSelector,
  resetSelectedLocation,
  selectedLocationSelector,
  locationEditedSuccessfully,
  resetLocationSuccessfulOperation,
} from 'store/slices/locations';
import {
  defaultConfigSelector,
  organizationConfigSelector,
  organizationSelector,
} from 'store/slices/organizations';
import AppCheckbox from 'components/Form/AppCheckbox';
import {
  LocationConfigValuesType,
  LocationValuesType,
} from 'types/LocationTypes';
import AppTooltip from 'components/Form/AppTooltip';
import { TETHER_PLACEMENT } from 'baseui/layer';
import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import AppTextarea from 'components/Form/AppTextarea';
import AppSelect from 'components/Form/AppSelect';
import { ModalButton } from 'baseui/modal';
import {
  KIND,
  SIZE,
} from 'baseui/button';
import { errorSelector } from 'store/slices/error';
import {
  ModalNames,
  setModal,
} from 'store/slices/modals';
import { locationValidationSchema as validationSchema } from 'validation/addOrganizationSchema';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWarning } from '@fortawesome/free-solid-svg-icons';
import useIsFormChanged from 'hooks/useIsFormChanged';
import { colors } from 'theme';
import { notificationSelector } from 'store/slices/notification';
import {
  ConfigType,
  OrganizationConfigResponseType,
} from 'types/OrganizationTypes';
import { SubElementEWATypeEnum } from 'types/EmployeeTypes';
import { unScalePrice } from 'utils/priceScale';
import {
  controlOverrides,
  gridOverrides,
} from '../../OrganizationFormHelpers';

export const LocationFormContext = createContext(
  {} as FormikState<LocationValuesType> & FormikHelpers<LocationValuesType> & FormikHandlers,
);

export const warningIconStyle = {
  padding: '0 10px',
  color: 'rgb(187, 0, 47)',
  '@media screen and (max-width: 1200px)': {
    marginTop: '16px',
  },
};

const OrganizationFormLocationModalEWA = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['organizations', 'errors', 'common', 'locations']);
  const defaultConfig = useAppSelector(defaultConfigSelector);
  const locationConfig = useAppSelector(locationConfigSelector);
  const organizationConfig = useAppSelector(organizationConfigSelector);
  const organizationConfigValues = organizationConfig && organizationConfigMapper(organizationConfig);
  const location = useAppSelector(selectedLocationSelector);
  const [css] = useStyletron();
  const organization = useAppSelector(organizationSelector);
  const { id: organizationID } = organization || {};
  const error = useAppSelector(errorSelector);
  const modalName = ModalNames.LOCATION_FORM_MODAL;
  const notificationToast = useAppSelector(notificationSelector);
  const locationCreated = useAppSelector(locationCreatedSelector);
  const locationEdited = useAppSelector(locationEditedSuccessfully);

  // eslint-disable-next-line max-len
  const employeeTypesOrgLevel = (organizationConfig?.find((item: OrganizationConfigResponseType) => item.configKeyName === ConfigType.SUPPORTED_EMPLOYMENT_EWA_TYPES)?.configValue)
    ?? defaultConfig?.SUPPORTED_EMPLOYMENT_EWA_TYPES;

  const initialValues = {
    ...locationInitialValues,
    offerPercentPerEmployee: Number(defaultConfig?.OFFER_PERCENT),
    tpoMaxEarningsPerBusinessDay: unScalePrice(Number(defaultConfig?.TPO_MAX_EARNINGS_PER_BUSINESS_DAY)),
    tpoMaxEarningsPerPeriod: unScalePrice(Number(defaultConfig?.TPO_MAX_EARNINGS_PER_PERIOD)),
    ...(organizationConfigValues),
    ...(locationConfig && locationConfigMapper(locationConfig)),
  };

  const proRataType = [
    {
      value: 'CALENDAR_DAYS',
      label: t('locations:proRataType.calendarDays.option.label'),
    },
  ];

  const orgConfigCheckPercent = typeof organizationConfigValues?.offerPercentPerEmployee === 'number'
    ? organizationConfigValues.offerPercentPerEmployee : defaultConfig?.OFFER_PERCENT;

  const locationTPOMaxEarningsBusinessDay = locationConfig?.find((item) => item.configKeyName === ConfigType.TPO_MAX_EARNINGS_PER_BUSINESS_DAY)?.configValue
    ? locationConfig?.find((item) => item.configKeyName === ConfigType.TPO_MAX_EARNINGS_PER_BUSINESS_DAY)?.configValue
    : defaultConfig?.TPO_MAX_EARNINGS_PER_BUSINESS_DAY;

  const locationTPOMaxEarningsPayPeriod = locationConfig?.find((item) => item.configKeyName === ConfigType.TPO_MAX_EARNINGS_PER_PERIOD)?.configValue
    ? locationConfig?.find((item) => item.configKeyName === ConfigType.TPO_MAX_EARNINGS_PER_PERIOD)?.configValue
    : defaultConfig?.TPO_MAX_EARNINGS_PER_PERIOD;

  const onSubmit = (
    values: LocationValuesType & LocationConfigValuesType,
  ) => {
    organizationID && location?.id
      && dispatch(editLocationConfigs({
        organizationID,
        locationID: location.id.toString(),
        configs: saveLocationConfigMapper(values),
      }));
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
    initialTouched: {
      storeNumber: true,
      alphaNumericStoreNumber: true,
      conceptID: true,
    },
  });

  const {
    values,
    handleSubmit,
    validateForm,
    isSubmitting,
    setSubmitting,
    setValues,
    isValid,
  } = formik;

  const handleSubmitExtended = () => {
    validateForm(values)
      .then((err: any) => {
        if (Object.keys(err).length === 0 && err.constructor === Object) {
          handleSubmit();
        }
      });
  };

  const setIsModalOpen = (
    isOpen: boolean,
  ) => {
    dispatch(setModal({
      name: modalName,
      isOpen,
    }));
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    dispatch(resetSelectedLocation());
    (locationCreated || locationEdited) && dispatch(fetchLocations({ organizationID })).then(() => {
      dispatch(resetLocationSuccessfulOperation());
    });
  };

  const { isFormChanged, setDefaultValues } = useIsFormChanged(values);

  useEffect(() => {
    if (error?.message || error?.messageKey) {
      setSubmitting(false);
      handleModalClose();
    }
  }, [error]);

  useEffect(() => {
    if (location) {
      const combinedValues = {
        ...locationMapper(location),
        ...(locationConfig && locationConfigMapper(locationConfig)),
        offerPercentPerEmployee: Number(orgConfigCheckPercent),
        tpoMaxEarningsPerBusinessDay: unScalePrice(Number(locationTPOMaxEarningsBusinessDay)),
        tpoMaxEarningsPerPeriod: unScalePrice(Number(locationTPOMaxEarningsPayPeriod)),
        hourlyPaidEmployeesEnable: employeeTypesOrgLevel?.includes(SubElementEWATypeEnum.HOURLY_PAID) ? values.hourlyPaidEmployeesEnable : false,
        salariedEmployeesEnable: employeeTypesOrgLevel?.includes(SubElementEWATypeEnum.SALARIED_NOT_CLOCKING) ? values.salariedEmployeesEnable : false,
        regularlyPaidEmployeesEnable: employeeTypesOrgLevel?.includes(SubElementEWATypeEnum.REGULARLY_PAID_HOURLY)
          ? values.regularlyPaidEmployeesEnable : false,
      };
      setValues(combinedValues);
      setDefaultValues(combinedValues);
    }
  }, [location, locationConfig]);

  useEffect(() => {
    if (notificationToast?.[1]?.isOpen) {
      setSubmitting(false);
    }
  }, [notificationToast]);

  return (
    <form>
      <LocationFormContext.Provider value={formik}>
        {isFormChanged && (
          <Block
            overrides={{
              Block: {
                style: {
                  marginLeft: '14px',
                  marginRight: '0px',
                  marginBottom: '14px',
                  marginTop: '0px',
                  display: 'flex',
                  justifyContent: 'center',
                },
              },
            }}
          >
            <FontAwesomeIcon
              icon={faWarning}
              className={css(warningIconStyle)}
              size="lg"
            />
            <LabelMedium color={colors.primary}>
              {t('common:unsavedChanges')}
            </LabelMedium>
          </Block>
        )}
        <Grid
          gridColumns={12}
          gridMargins={16}
        >
          <Cell
            span={12}
            align={ALIGNMENT.start}
          >
            <LabelMedium>{t('organizations:program.enabled')}</LabelMedium>
          </Cell>

          <AppCheckbox
            name="ewaEnabled"
            label={t('organizations:program.labelEnabled')}
            cellSpan={12}
            context={LocationFormContext}
          />
        </Grid>

        <Grid
          gridColumns={12}
          gridMargins={16}
        >
          <AppInput
            name="offerPercentPerEmployee"
            label={t('locations:offerPercent.label')}
            cellSpan={[12, 6]}
            context={LocationFormContext}
            inputProps={{
              endEnhancer: '%',
              type: 'number',
              disabled: true,
              min: 0,
              max: orgConfigCheckPercent,
              autoComplete: 'off',
            }}
          />
        </Grid>

        <Grid
          gridColumns={12}
          gridMargins={16}
        >
          <Cell
            span={12}
            align={ALIGNMENT.start}
          >
            <LabelMedium>{t('locations:location.employeeTypes.header')}</LabelMedium>
            {employeeTypesOrgLevel?.includes(SubElementEWATypeEnum.HOURLY_PAID) && (
            <Grid
              gridColumns={12}
              gridGutters={0}
              gridMargins={24}
              overrides={gridOverrides}
            >
              <Cell
                span={[12, 6, 5]}
                overrides={{
                  Cell: {
                    style: {
                      paddingTop: '18px',
                    },
                  },
                }}
              >
                <LabelMedium>
                  {t('locations:employeeType.hourlyPaid.label')}
                  <AppTooltip
                    title={t('locations:employeeType.hourlyPaid.tooltip.title')}
                    content={t('locations:employeeType.hourlyPaid.tooltip.content')}
                    placement={TETHER_PLACEMENT.right}
                  />
                </LabelMedium>
              </Cell>
              <Cell skip={[0, 1, 2]} />
              <AppCheckbox
                name="hourlyPaidEmployeesEnable"
                label={t('common:enable')}
                cellSpan={[12, 4, 4]}
                context={LocationFormContext}
                disabled={!values.ewaEnabled}
                formControlProps={{ overrides: controlOverrides }}
              />
            </Grid>
            )}
            {employeeTypesOrgLevel?.includes(SubElementEWATypeEnum.SALARIED_NOT_CLOCKING) && (
            <Grid
              gridColumns={12}
              gridGutters={0}
              gridMargins={24}
              overrides={gridOverrides}
            >
              <Cell
                span={[12, 6, 5]}
                overrides={{
                  Cell: {
                    style: {
                      paddingTop: '18px',
                    },
                  },
                }}
              >
                <LabelMedium>
                  {t('locations:employeeType.salaried.label')}
                  <AppTooltip
                    title={t('locations:employeeType.salaried.tooltip.title')}
                    content={t('locations:employeeType.salaried.tooltip.content')}
                    placement={TETHER_PLACEMENT.right}
                  />

                </LabelMedium>
              </Cell>
              <Cell skip={[0, 1, 2]} />
              <AppCheckbox
                name="salariedEmployeesEnable"
                label={t('common:enable')}
                cellSpan={[12, 4, 4]}
                context={LocationFormContext}
                disabled={!values.ewaEnabled}
                formControlProps={{ overrides: controlOverrides }}
              />
            </Grid>
            )}
            {employeeTypesOrgLevel?.includes(SubElementEWATypeEnum.REGULARLY_PAID_HOURLY) && (
            <Grid
              gridColumns={12}
              gridGutters={0}
              gridMargins={24}
              overrides={gridOverrides}
            >
              <Cell
                span={[12, 6, 5]}
                overrides={{
                  Cell: {
                    style: {
                      paddingTop: '18px',
                    },
                  },
                }}
              >
                <LabelMedium>
                  {t('locations:employeeType.regularlyPaid.label')}
                  <AppTooltip
                    title={t('locations:employeeType.regularlyPaid.tooltip.title')}
                    content={t('locations:employeeType.regularlyPaid.tooltip.content')}
                    placement={TETHER_PLACEMENT.right}
                  />
                </LabelMedium>
              </Cell>
              <Cell skip={[0, 1, 2]} />
              <AppCheckbox
                name="regularlyPaidEmployeesEnable"
                label={t('common:enable')}
                cellSpan={[12, 4, 4]}
                context={LocationFormContext}
                disabled={!values.ewaEnabled}
                formControlProps={{ overrides: controlOverrides }}
              />
            </Grid>
            )}
            {(values.salariedEmployeesEnable || values.regularlyPaidEmployeesEnable)
            && (
              <Grid
                gridColumns={12}
                gridGutters={0}
                gridMargins={24}
                overrides={gridOverrides}
              >
                <AppSelect
                  clearable
                  showStar
                  name="offerProRataMethod"
                  label={t('locations:proRataType.label')}
                  options={proRataType}
                  cellSpan={[12, 6]}
                  context={LocationFormContext}
                  selectProps={{
                    disabled: !values.ewaEnabled,
                    clearable: false,
                  }}
                />
              </Grid>
            )}
          </Cell>

          <Cell
            span={12}
            align={ALIGNMENT.start}
            overrides={{
              Cell: {
                style: {
                  marginTop: '8px',
                },
              },
            }}
          >
            <LabelMedium>{t('locations:location.shiftSettings.header')}</LabelMedium>

            <Grid
              gridColumns={12}
              gridGutters={0}
              gridMargins={24}
              overrides={gridOverrides}
            >
              <Cell
                span={[12, 6, 5]}
                overrides={{
                  Cell: {
                    style: {
                      paddingTop: '18px',
                    },
                  },
                }}
              >
                <LabelMedium>{t('locations:shiftSettings.tippedShifts.label')}</LabelMedium>
              </Cell>
              <Cell skip={[0, 1, 2]} />
              <AppCheckbox
                name="excludeTippedShifts"
                label={t('common:exclude')}
                cellSpan={[12, 4, 4]}
                context={LocationFormContext}
                disabled={!values.ewaEnabled}
                formControlProps={{ overrides: controlOverrides }}
              />
            </Grid>

            <Grid
              gridColumns={12}
              gridGutters={0}
              gridMargins={24}
              overrides={gridOverrides}
            >
              <Cell
                span={[12, 6, 5]}
                overrides={{
                  Cell: {
                    style: {
                      paddingTop: '18px',
                    },
                  },
                }}
              >
                <LabelMedium className="ellipsis">{t('locations:shiftSettings.shiftsForSelectedJobCodes.label')}</LabelMedium>
              </Cell>
              <Cell skip={[0, 1, 2]} />
              <AppCheckbox
                name="jobCodeExclusions"
                label={t('common:exclude')}
                cellSpan={[12, 4, 4]}
                context={LocationFormContext}
                disabled={!values.ewaEnabled}
                formControlProps={{ overrides: controlOverrides }}
                checkboxProps={{
                  onChange: (option) => {
                    const isChecked = option.currentTarget.checked;
                    setValues({
                      ...values,
                      jobCodeExclusions: isChecked,
                      jobCodeExclusionsValue: isChecked ? values.jobCodeExclusionsValue : null,
                    });
                  },
                }}
              />
              {values?.jobCodeExclusions
                && (
                  <AppTextarea
                    showStar
                    name="jobCodeExclusionsValue"
                    label={t('organizations:jobCodeExclusions.label')}
                    cellSpan={[12, 12, 8]}
                    context={LocationFormContext}
                    textareaProps={{
                      id: 'jobCodeExclusionsValue',
                      autoComplete: 'off',
                      disabled: !values.ewaEnabled,
                    }}
                  />
                )}
            </Grid>
          </Cell>
        </Grid>
        <br />
        <Grid
          gridColumns={12}
          gridMargins={16}
        >
          <Cell
            span={12}
          >
            <Block
              display="flex"
              justifyContent="flex-end"
            >
              <ModalButton
                size={SIZE.compact}
                type="button"
                disabled={isSubmitting}
                kind={KIND.secondary}
                onClick={handleModalClose}
                overrides={{
                  BaseButton: {
                    props: {
                      id: 'Modal-footer-close',
                    },
                  },
                }}
              >
                {t('common:cancel')}
              </ModalButton>

              <ModalButton
                size={SIZE.compact}
                disabled={isSubmitting || !isFormChanged || !isValid}
                kind={KIND.primary}
                type="button"
                onClick={handleSubmitExtended}
                overrides={{
                  BaseButton: {
                    props: {
                      id: 'Modal-footer-action',
                    },
                  },
                }}
              >
                {t('common:save')}
              </ModalButton>
            </Block>
          </Cell>
        </Grid>
      </LocationFormContext.Provider>
    </form>
  );
};

export default memo(OrganizationFormLocationModalEWA);
