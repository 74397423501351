import { useState, useEffect } from 'react';

const removeKeys: any = (obj: any, keys: string[]) => {
  if (Array.isArray(obj)) return obj.map((item) => removeKeys(item, keys));

  if (!(obj instanceof Date) && typeof obj === 'object' && obj !== null) {
    return Object
      .keys(obj)
      .reduce((acc, key) => {
        if (!keys.includes(key)) {
          return {
            ...acc,
            [key]: removeKeys(obj[key], keys),
          };
        }
        return acc;
      }, {});
  }

  if (typeof obj === 'number') {
    return obj.toString();
  }

  return obj;
};

export const useIsFormChanged = (values: any) => {
  const excludeKeys = ['label'];
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [defaultData, setDefaultData] = useState(JSON.stringify(removeKeys(values, excludeKeys)));

  useEffect(() => {
    setIsFormChanged(false);
    setDefaultData('');

    return () => {
      setIsFormChanged(false);
      setDefaultData('');
    };
  }, []);

  useEffect(() => {
    setIsFormChanged(defaultData !== JSON.stringify(removeKeys(values, excludeKeys)));
  }, [values]);

  const setDefaultValues = (valuesParam: any) => {
    setDefaultData(JSON.stringify(removeKeys(valuesParam, excludeKeys)));
    setIsFormChanged(false);
  };

  return {
    isFormChanged,
    setDefaultValues,
    defaultData,
  };
};

export default useIsFormChanged;
