import {
  memo,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useStyletron } from 'styletron-react';
import AccordionSection from 'components/AccordionSection/AccordionSection';
import Loader from 'components/Loader';
import {
  ALIGNMENT,
  Grid,
} from 'baseui/layout-grid';
import { Block } from 'baseui/block';
import { SIZE } from 'baseui/input';
import {
  OnChangeParams,
  Select,
  Value,
} from 'baseui/select';
import CellFormControl from 'components/CellFormControl';
import {
  EmployeeIDType,
  EmployeeResponseType,
} from 'types/EmployeeTypes';
import { useParams } from 'react-router-dom';
import {
  useAppDispatch,
  useAppSelector,
} from 'store/hooks';
import {
  employeesTPODrawsAreListFetchedSelector,
  employeesTPODrawsNumPagesSelector,
  employeesTPODrawsPageNumberSelector,
  employeesTPODrawsPendingListSelector,
  employeesTPODrawsTotalSizeSelector,
  employeeTPODrawsSelector,
  fetchEmployeeDetailsTPODraws,
} from 'store/slices/employees';
import {
  fetchPayrollPeriods,
  payGroupPayrollPeriodsSelector,
  payGroupPendingPayrollPeriodsListSelector,
  resetPayGroupPayRollPeriods,
} from 'store/slices/payGroups';
import { loggedOrganizationSelector } from 'store/slices/loggedOrganization';
import moment from 'moment';
import {
  containerStyles,
  statusOptions,
} from '../../EmployeesHelpers';
import EmployeeDetailsTPODrawsTable from './EmployeeDetailsTPODrawsTable';

export type EmployeeDetailsTPOHistorySectionPropsType = {
    expanded?: boolean,
    selectedEmployee?: EmployeeResponseType,
  }

const selectContainerStyle = {
  height: '36px',
  display: 'flex',
  alignItems: 'center',
};

const EmployeeDetailsTPOHistorySection = ({
  expanded,
  selectedEmployee,
}: EmployeeDetailsTPOHistorySectionPropsType) => {
  const dispatch = useAppDispatch();
  const [css] = useStyletron();
  const { t } = useTranslation(['common', 'employees']);
  const dateFormat = t('dateFormats:standard');
  const pendingList = useAppSelector(employeesTPODrawsPendingListSelector);
  const { employeeID }: { employeeID: EmployeeIDType } = useParams<{ employeeID: string }>();
  const loggedOrganization = useAppSelector(loggedOrganizationSelector);
  const pageNumber = useAppSelector(employeesTPODrawsPageNumberSelector);
  const payrollPeriods = useAppSelector(payGroupPayrollPeriodsSelector);
  const pendingPayrollPeriods = useAppSelector(payGroupPendingPayrollPeriodsListSelector);
  const areListFetched = useAppSelector(employeesTPODrawsAreListFetchedSelector);
  const [payrollPeriod, setPayrollPeriod] = useState<Value>();
  const [drawStatus, setDrawStatus] = useState<Value>();
  const draws = useAppSelector(employeeTPODrawsSelector);
  const numPages = useAppSelector(employeesTPODrawsNumPagesSelector);
  const totalSize = useAppSelector(employeesTPODrawsTotalSizeSelector);

  const fetchPayrollPeriodsByPaygroup = () => {
    const payGroupID = selectedEmployee?.assignments?.find((i) => i.primaryLocation)?.location?.payGroup.id;
    if (payGroupID) {
      dispatch(fetchPayrollPeriods({
        organizationID: loggedOrganization?.id,
        payGroupID: String(payGroupID),
      }));
    }
  };

  const handleOnExpand = () => {
    if (employeeID && !areListFetched) {
      dispatch(fetchEmployeeDetailsTPODraws({
        employeeID,
        filter: {
          pageNumber: pageNumber.toString(),
          type: 'TCO',
          includeOfferDetails: 'true',
        },
      }));
      !payrollPeriods && fetchPayrollPeriodsByPaygroup();
    }
  };

  const handleStatusChange = ({
    value,
  }: OnChangeParams) => {
    setDrawStatus(value);
    let filter: any = {
      pageNumber: '1',
      type: 'TCO',
      includeOfferDetails: 'true',
    };
    if (value && value?.[0]?.value) {
      filter = { ...filter, statuses: value[0]?.value };
    }
    if (payrollPeriod && payrollPeriod?.[0]) {
      filter = { ...filter, payrollPeriodId: payrollPeriod?.[0].id };
    }
    dispatch(fetchEmployeeDetailsTPODraws({ employeeID, filter }));
  };

  const handlePayrollPeriodChange = ({
    value,
  }: OnChangeParams) => {
    setPayrollPeriod(value);
    let filter: any = {
      pageNumber: '1',
      type: 'TCO',
      includeOfferDetails: 'true',
    };
    if (value && value?.[0]?.id) {
      filter = {
        ...filter,
        payrollPeriodId: value[0]?.id,
      };
    }
    if (drawStatus && drawStatus?.[0]) {
      filter = { ...filter, statuses: drawStatus?.[0].value };
    }
    dispatch(fetchEmployeeDetailsTPODraws({ employeeID, filter }));
  };

  const handlePageChange = ({ nextPage }: { nextPage: number }) => {
    const page = Math.min(Math.max(nextPage, 1), totalSize).toString();
    let filter: any = {
      pageNumber: page,
      type: 'TCO',
      includeOfferDetails: 'true',
    };
    if (payrollPeriod && payrollPeriod?.[0]) {
      filter = { ...filter, payrollPeriodId: payrollPeriod[0]?.id };
    }
    if (drawStatus && drawStatus?.[0]) {
      filter = { ...filter, statuses: drawStatus?.[0].value };
    }
    dispatch(fetchEmployeeDetailsTPODraws({
      employeeID,
      filter,
    }));
  };

  useEffect(() => () => {
    dispatch(resetPayGroupPayRollPeriods());
  }, []);

  return (
    <AccordionSection
      expanded={expanded}
      title={t('employees:tpoHistory.section.header')}
      disabled={false}
      showStatus={false}
      onExpand={handleOnExpand}
    >
      <div className={css(containerStyles)}>
        <Loader active={pendingList} />

        <Block
          marginBottom="16px"
        >
          <Grid
            gridColumns={12}
            gridGutters={24}
            align={ALIGNMENT.center}
            gridMargins={36}
            gridGaps={8}
          >
            <CellFormControl
              cellSpan={[12, 6, 2.5]}
              cellAlign={ALIGNMENT.center}
              label={t('employees:draws.statusLabel')}
            >
              <Select
                size={SIZE.compact}
                maxDropdownHeight="300px"
                placeholder={t('common:select')}
                type="select"
                clearable
                options={statusOptions}
                labelKey="value"
                valueKey="id"
                onChange={handleStatusChange}
                value={drawStatus as Value}
                overrides={{
                  ControlContainer: {
                    style: selectContainerStyle,
                    props: {
                      'data-testid': 'employeeTPOHistorySection-status-select',
                      id: 'employeeTPOHistorySection-status-select-id',
                    },
                  },
                }}
              />
            </CellFormControl>

            <CellFormControl
              cellSpan={[12, 6, 2.5]}
              cellAlign={ALIGNMENT.center}
              label={t('employees:table.payPeriod')}
            >
              <Select
                size={SIZE.compact}
                maxDropdownHeight="300px"
                placeholder={t('common:select')}
                disabled={pendingPayrollPeriods}
                isLoading={pendingPayrollPeriods}
                type="select"
                clearable
                options={payrollPeriods?.map(({ startDate, endDate, id }) => ({
                  name: `${moment(startDate).format(dateFormat)} - ${moment(endDate).format(dateFormat)}`,
                  id,
                }))}
                labelKey="name"
                valueKey="id"
                onChange={handlePayrollPeriodChange}
                value={payrollPeriod as Value}
                overrides={{
                  ControlContainer: {
                    style: selectContainerStyle,
                    props: {
                      'data-testid': 'employeeTPOHistorySection-payrollPeriod-select',
                      id: 'employeeTPOHistorySection-payrollPeriod-select-id',
                    },
                  },
                }}
                noResultsMsg={t('common:noResults')}
              />
            </CellFormControl>
          </Grid>
        </Block>
        <EmployeeDetailsTPODrawsTable
          filteredDraws={draws}
          payPeriod={payrollPeriod}
          handlePageChange={handlePageChange}
          pageNumber={pageNumber}
          numPages={numPages}
        />
      </div>
    </AccordionSection>
  );
};

export default memo(EmployeeDetailsTPOHistorySection);
