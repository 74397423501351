import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchMasterAccountTransactionsRequest } from 'bankingApi/masterAccountAPI';
import { fetchBankFilesRequest } from 'bankingApi/bankFilesAPI';
import {
  fetchWorkerAccountTransactionsRequest,
  createTransactionRequest,
  recoverFundsRequest,
} from 'bankingApi/workerAccountAPI';
import {
  FetchMasterAccountTransactionsPropsType,
  MasterTransactionsValueType,
} from 'types/MasterAccountTypes';
import { NewTransactionValuesType } from 'screens/Treasury/NewTransactionModal';
import {
  FetchWorkerAccountTransactionsPropsType,
  RecoverFundsParamsType,
  WorkerTransactionsValueType,
} from 'types/WorkerAccountTypes';
import {
  BankFileType,
  BankFilesFilterType,
} from 'types/BankFilesTypes';
import { RootState } from '..';

export const initialState = {
  masterAccount: {
    pendingList: false,
    list: [] as MasterTransactionsValueType[],
    morePages: false,
    areFetched: false,
    page: 1,
  },
  workerAccount: {
    pendingList: false,
    list: [] as WorkerTransactionsValueType[],
    morePages: false,
    areFetched: false,
    page: 1,
  },
  pendingBankFiles: false,
  bankFiles: [] as BankFileType[],
};

export const fetchBankFiles = createAsyncThunk(
  'masterAccount/bankFiles',
  async (params: BankFilesFilterType, { getState, rejectWithValue }): Promise<any> => {
    const { user: { accessToken } } = getState() as RootState;
    try {
      return await fetchBankFilesRequest(accessToken, params);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchMasterAccountTransactionsOnLoadMore = createAsyncThunk(
  'masterAccount/transactionsOnLoadMore',
  async (params: FetchMasterAccountTransactionsPropsType, { getState, rejectWithValue }): Promise<any> => {
    const { user: { accessToken }, transactions: { masterAccount } } = getState() as RootState;
    const {
      transactionStatus,
      transactionType,
      transactionNumber,
      startDate,
      endDate,
    } = params;

    const page = (Number(masterAccount.page) + 1).toString();

    try {
      return await fetchMasterAccountTransactionsRequest(
        accessToken,
        page,
        transactionStatus,
        transactionType,
        transactionNumber,
        startDate,
        endDate,
      );
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchWorkerAccountTransactionsOnLoadMore = createAsyncThunk(
  'workerAccount/transactionsOnLoadMore',
  async (params: FetchWorkerAccountTransactionsPropsType, { getState, rejectWithValue }): Promise<any> => {
    const { user: { accessToken }, transactions: { workerAccount } } = getState() as RootState;
    const {
      workerID,
      transactionStatus,
      transactionType,
      transactionNumber,
      startDate,
      endDate,
    } = params;

    const page = (Number(workerAccount.page) + 1).toString();

    try {
      return await fetchWorkerAccountTransactionsRequest(
        accessToken,
        workerID,
        page,
        transactionStatus,
        transactionType,
        transactionNumber,
        startDate,
        endDate,
      );
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);
export const fetchMasterAccountTransactions = createAsyncThunk(
  'masterAccount/transactions',
  async (params: FetchMasterAccountTransactionsPropsType, { getState, rejectWithValue }): Promise<any> => {
    const { user: { accessToken }, transactions: { masterAccount } } = getState() as RootState;
    const {
      transactionStatus,
      transactionType,
      transactionNumber,
      startDate,
      endDate,
    } = params;

    const page = masterAccount.page.toString();
    try {
      return await fetchMasterAccountTransactionsRequest(
        accessToken,
        page,
        transactionStatus,
        transactionType,
        transactionNumber,
        startDate,
        endDate,
      );
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchWorkerAccountTransactions = createAsyncThunk(
  'workerAccount/transactions',
  async (params: FetchWorkerAccountTransactionsPropsType, { getState, rejectWithValue }): Promise<any> => {
    const { user: { accessToken }, transactions: { workerAccount } } = getState() as RootState;
    const {
      workerID,
      transactionStatus,
      transactionType,
      transactionNumber,
      startDate,
      endDate,
    } = params;

    const page = workerAccount.page.toString();

    try {
      return await fetchWorkerAccountTransactionsRequest(
        accessToken,
        workerID,
        page,
        transactionStatus,
        transactionType,
        transactionNumber,
        startDate,
        endDate,
      );
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const createTransaction = createAsyncThunk(
  'workerAccount/createTransaction',
  async (params: NewTransactionValuesType, { getState, rejectWithValue }): Promise<any> => {
    const { user: { accessToken } } = getState() as RootState;

    try {
      return await createTransactionRequest(accessToken, params);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const recoverFunds = createAsyncThunk(
  'workerAccount/recoverFunds',
  async (params: RecoverFundsParamsType, { getState, rejectWithValue }): Promise<any> => {
    const { workerID, data } = params;
    const storeState = getState() as RootState;

    try {
      return await recoverFundsRequest(storeState.user.accessToken, workerID, data);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

const transactionsSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    resetTransactions: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMasterAccountTransactionsOnLoadMore.pending, (state) => {
      state.masterAccount.pendingList = true;
    });

    builder.addCase(fetchMasterAccountTransactionsOnLoadMore.fulfilled, (state, { payload: { values, morePages } }) => {
      state.masterAccount.list = state.masterAccount.list.concat(values);
      state.masterAccount.pendingList = false;
      state.masterAccount.page += 1;
      state.masterAccount.areFetched = true;
      state.masterAccount.morePages = !!morePages;
    });

    builder.addCase(fetchMasterAccountTransactionsOnLoadMore.rejected, (state) => {
      state.masterAccount.pendingList = false;
    });

    builder.addCase(fetchWorkerAccountTransactionsOnLoadMore.pending, (state) => {
      state.workerAccount.pendingList = true;
    });

    builder.addCase(fetchWorkerAccountTransactionsOnLoadMore.fulfilled, (state, { payload: { values, morePages } }) => {
      state.workerAccount.list = state.workerAccount.list.concat(values);
      state.workerAccount.pendingList = false;
      state.workerAccount.page += 1;
      state.workerAccount.areFetched = true;
      state.workerAccount.morePages = !!morePages;
    });

    builder.addCase(fetchWorkerAccountTransactionsOnLoadMore.rejected, (state) => {
      state.workerAccount.pendingList = false;
    });

    builder.addCase(fetchMasterAccountTransactions.pending, (state) => {
      state.masterAccount.pendingList = true;
      state.masterAccount.areFetched = false;
      state.masterAccount.page = initialState.masterAccount.page;
    });

    builder.addCase(fetchMasterAccountTransactions.fulfilled, (state, { payload: { values, morePages } }) => {
      state.masterAccount.list = values;
      state.masterAccount.pendingList = false;
      state.masterAccount.page = initialState.masterAccount.page;
      state.masterAccount.areFetched = true;
      state.masterAccount.morePages = !!morePages;
    });

    builder.addCase(fetchMasterAccountTransactions.rejected, (state) => {
      state.masterAccount.pendingList = false;
    });

    builder.addCase(fetchWorkerAccountTransactions.pending, (state) => {
      state.workerAccount.pendingList = true;
      state.workerAccount.areFetched = false;
      state.workerAccount.page = initialState.workerAccount.page;
    });

    builder.addCase(fetchWorkerAccountTransactions.fulfilled, (state, { payload: { values, morePages } }) => {
      state.workerAccount.list = values;
      state.workerAccount.pendingList = false;
      state.workerAccount.page = initialState.workerAccount.page;
      state.workerAccount.areFetched = true;
      state.workerAccount.morePages = !!morePages;
    });

    builder.addCase(fetchWorkerAccountTransactions.rejected, (state) => {
      state.workerAccount.pendingList = false;
    });

    builder.addCase(fetchBankFiles.pending, (state) => {
      state.pendingBankFiles = true;
    });

    builder.addCase(fetchBankFiles.fulfilled, (state, { payload: { values } }) => {
      state.bankFiles = values;
      state.pendingBankFiles = false;
    });

    builder.addCase(fetchBankFiles.rejected, (state) => {
      state.pendingBankFiles = false;
    });
  },
});

export const {
  resetTransactions,
} = transactionsSlice.actions;
export const masterAccountTransactionsSelector = (state: RootState): MasterTransactionsValueType[] => state.transactions.masterAccount.list;
export const masterAccountTransactionsPendingListSelector = (state: RootState): boolean => state.transactions.masterAccount.pendingList;
export const masterAccountTransactionsAreFetchedSelector = (state: RootState): boolean => state.transactions.masterAccount.areFetched;
export const masterAccountTransactionsMorePagesSelector = (state: RootState): boolean => state.transactions.masterAccount.morePages;
export const workerAccountTransactionsSelector = (state: RootState): WorkerTransactionsValueType[] => state.transactions.workerAccount.list;
export const workerAccountTransactionsPendingListSelector = (state: RootState): boolean => state.transactions.workerAccount.pendingList;
export const workerAccountTransactionsAreFetchedSelector = (state: RootState): boolean => state.transactions.workerAccount.areFetched;
export const workerAccountTransactionsMorePagesSelector = (state: RootState): boolean => state.transactions.workerAccount.morePages;
export const bankFilesSelector = (state: RootState): BankFileType[] => state.transactions.bankFiles;
export const pendingBankFilesSelector = (state: RootState): boolean => state.transactions.pendingBankFiles;

export default transactionsSlice.reducer;
