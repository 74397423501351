import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  createRepaymentRequest,
  createTPOBatchAllocationRequest,
  fetchAllRepaymentsRequest,
  fetchRepaymentRequest,
  updateRepaymentRequest,
} from 'api/repaymentsAPI';
import {
  FetchRepaymentsParamsType,
  RepaymentIDType,
  RepaymentUpdateType,
  SaveRepaymentsParamsType,
  KeyType,
  RepaymentType,
  CreateTPOBatchAllocationRequestType,
  FetchRepaymentParamsType,
} from 'types/RepaymentTypes';
import { resetNotification } from 'store/events';
import { OrganizationIDType } from 'types/OrganizationTypes';
import { RootState } from '..';

export type SubElementsAmount = {
  value: number
  scale: number
  currencyCode: string
}

export const initialState = {
  pendingList: false,
  pending: false,
  list: [] as RepaymentType[],
  pageNumber: 1 as number,
  pageSize: 50 as number,
  totalSize: 0 as number,
  numPages: 1 as number,
  repayment: {} as RepaymentType,
  successfullySaved: false,
  searchStatus: [] as any,
  searchSortBy: [{ value: KeyType.dateReceived }] as any,
  paymentAllocation: {
    searchPayGroup: [] as any,
    searchPayPeriod: [] as any,
  },
  tpoRepaymentAllocation: {
    pending: false,
    successfullySaved: false,
  },
};

export const fetchRepayments = createAsyncThunk(
  'repayments',
  async (params: FetchRepaymentsParamsType, { getState, rejectWithValue }): Promise<any> => {
    const { organizationID, pageNumber } = params;
    const storeState = getState() as RootState;

    try {
      const result = await fetchAllRepaymentsRequest(
        storeState.user.accessToken,
        organizationID,
        pageNumber.toString(),
      );
      return result;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchRepayment = createAsyncThunk(
  'repayments/ID',
  async (
    params: FetchRepaymentParamsType,
    { getState, rejectWithValue },
  ): Promise<any> => {
    const { organizationId, repaymentId } = params;
    const storeState = getState() as RootState;

    try {
      return await fetchRepaymentRequest(
        storeState.user.accessToken,
        organizationId,
        repaymentId,
      );
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const createRepayment = createAsyncThunk(
  'repayments/create',
  async (params: SaveRepaymentsParamsType, { getState, rejectWithValue }): Promise<any> => {
    const { organizationID, data } = params;
    const storeState = getState() as RootState;

    try {
      return await createRepaymentRequest(storeState.user.accessToken, organizationID, data);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);
export const updateRepayment = createAsyncThunk(
  'repayments/update',
  async (
    params: { organizationID: OrganizationIDType, id: RepaymentIDType, data: RepaymentUpdateType },
    { getState, rejectWithValue, dispatch },
  ): Promise<any> => {
    const { id, data, organizationID } = params;
    const storeState = getState() as RootState;

    try {
      const result = await updateRepaymentRequest(storeState.user.accessToken, organizationID, id, data);
      await dispatch(fetchRepayments({ organizationID, pageNumber: '1' }));
      return result;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const createTPORepaymentAllocation = createAsyncThunk(
  'repayments/tpo/allocation',
  async (
    params: { organizationID: OrganizationIDType, id: RepaymentIDType, data: CreateTPOBatchAllocationRequestType },
    { getState, rejectWithValue, dispatch },
  ): Promise<any> => {
    const { organizationID, id, data } = params;
    const storeState = getState() as RootState;

    try {
      const result = await createTPOBatchAllocationRequest(storeState.user.accessToken, organizationID, id, data);
      await dispatch(fetchRepayments({ organizationID, pageNumber: '1' }));
      return result;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

const repaymentsSlice = createSlice({
  name: 'repayments',
  initialState,
  reducers: {
    setRepayment: (state, action) => {
      state.repayment = action.payload;
    },
    resetRepayments: (state) => {
      state.list = initialState.list;
    },
    resetRepaymentSuccessfullySaved: (state) => {
      state.successfullySaved = initialState.successfullySaved;
    },
    setRepaymentSuccessfullySaved: (state, action) => {
      state.successfullySaved = action.payload;
    },
    setSearchStatus: (state, action) => {
      state.searchStatus = action.payload;
    },
    setSearchSortBy: (state, action) => {
      state.searchSortBy = action.payload;
    },
    setPaymentAllocationSearchPayGroup: (state, action) => {
      state.paymentAllocation.searchPayGroup = action.payload;
    },
    setPaymentAllocationSearchPayPeriod: (state, action) => {
      state.paymentAllocation.searchPayPeriod = action.payload;
    },
    resetRepaymentsSearch: (state) => {
      state.searchStatus = initialState.searchStatus;
      state.searchSortBy = initialState.searchSortBy;
    },
    resetPaymentAllocationSearchPayGroup: (state) => {
      state.paymentAllocation.searchPayGroup = initialState.paymentAllocation.searchPayGroup;
    },
    resetPaymentAllocationSearchPayPeriod: (state) => {
      state.paymentAllocation.searchPayPeriod = initialState.paymentAllocation.searchPayPeriod;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRepayments.pending, (state) => {
      state.pendingList = true;
      state.list = initialState.list;
    });

    builder.addCase(fetchRepayments.fulfilled, (state, action) => {
      state.list = action.payload.values;
      state.pendingList = false;
      state.pageNumber = action.payload.pageNumber;
      state.pageSize = action.payload.pageSize;
      state.totalSize = action.payload.totalSize;
      state.numPages = (Math.ceil(action.payload.totalSize / initialState.pageSize));
    });

    builder.addCase(fetchRepayments.rejected, (state) => {
      state.list = initialState.list;
      state.pendingList = false;
    });

    builder.addCase(resetNotification, (state) => {
      state.successfullySaved = initialState.successfullySaved;
    });

    builder.addCase(createRepayment.pending, (state) => {
      state.pending = true;
      state.successfullySaved = false;
    });

    builder.addCase(createRepayment.fulfilled, (state) => {
      state.successfullySaved = true;
      state.pending = false;
    });

    builder.addCase(createRepayment.rejected, (state) => {
      state.successfullySaved = false;
      state.pending = false;
    });

    builder.addCase(createTPORepaymentAllocation.pending, (state) => {
      state.tpoRepaymentAllocation.pending = true;
      state.tpoRepaymentAllocation.successfullySaved = false;
    });

    builder.addCase(createTPORepaymentAllocation.fulfilled, (state) => {
      state.tpoRepaymentAllocation.successfullySaved = true;
      state.tpoRepaymentAllocation.pending = false;
    });

    builder.addCase(createTPORepaymentAllocation.rejected, (state) => {
      state.tpoRepaymentAllocation.successfullySaved = false;
      state.tpoRepaymentAllocation.pending = false;
    });
  },
});

export const {
  setRepayment,
  resetRepayments,
  setRepaymentSuccessfullySaved,
  resetRepaymentSuccessfullySaved,
  setSearchStatus,
  setSearchSortBy,
  resetRepaymentsSearch,
  setPaymentAllocationSearchPayGroup,
  setPaymentAllocationSearchPayPeriod,
  resetPaymentAllocationSearchPayGroup,
  resetPaymentAllocationSearchPayPeriod,
} = repaymentsSlice.actions;
export const repaymentsSelector = (state: RootState): RepaymentType[] => state.repayments.list;
export const repaymentSelector = (state: RootState): RepaymentType => state.repayments.repayment;
export const successfullySavedSelector = (state: RootState) => state.repayments.successfullySaved;
export const repaymentsPendingSelector = (state: RootState) => state.repayments.pending;
export const repaymentsPendingListSelector = (state: RootState) => state.repayments.pendingList;
export const repaymentsPageNumberSelector = (state: RootState) => state.repayments.pageNumber;
export const repaymentsPageSizeSelector = (state: RootState) => state.repayments.pageSize;
export const repaymentsTotalSizeSelector = (state: RootState) => state.repayments.totalSize;
export const repaymentsNumPagesSelector = (state: RootState) => state.repayments.numPages;
export const repaymentsSearchStatusSelector = (state: RootState) => state.repayments.searchStatus;
export const repaymentsSearchSortBySelector = (state: RootState) => state.repayments.searchSortBy;
export const repaymentsPaymentAllocationSearchPayGroupSelector = (state: RootState) => state.repayments.paymentAllocation.searchPayGroup;
export const repaymentsPaymentAllocationSearchPayPeriodSelector = (state: RootState) => state.repayments.paymentAllocation.searchPayPeriod;

export const repaymentsTPOAllocationSuccessfullySavedSelector = (state: RootState) => state.repayments.tpoRepaymentAllocation.successfullySaved;
export const repaymentsTPOAllocationPendingSelector = (state: RootState) => state.repayments.tpoRepaymentAllocation.pending;

export default repaymentsSlice.reducer;
