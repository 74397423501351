import {
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit';
import {
  fetchOrganizationMasterAccountPaycardBalanceRequest,
  fetchOrganizationPaycardAccountDetailsRequest,
  fetchOrganizationPaycardEligibleLocationsRequest,
  fetchOrganizationPaycardSettingsRequest,
  fetchOrganizationPaycardsRequest,
  fetchOrganizationsPaycardFundingAccountsRequest,
  fetchOrganizationsPaycardProviderProgramsRequest,
  fetchOrganizationsPaycardProviderRequest,
  fetchPaycardManagementInventoryRequest,
  loadFundsRequest,
  registerPaycardRequest,
  setOrganizationPaycardSettingsRequest,
} from 'bankingApi/paycardAPI';
import { RootState } from 'store';
import {
  CardStockLocationItem,
  EmployeeSearchKeyType,
  FetChOrganizationPaycardsParamsType,
  FetchOrganizationMasterAccountPaycardBalanceParamsType,
  FetchOrganizationPaycardAccountParamsType,
  FetchOrganizationPaycardSettings,
  FetchOrganizationPaycardsInventoryParamsType,
  FetchOrganizationsPaycardOptionsParamsType,
  FetchOrganizationsPaycardProviderParamsType,
  LoadFundsParamsType,
  OrganizationPaycardEligibleLocation,
  OrganizationPaycardMasterAccountBalanceType,
  OrganizationPaycardProviderListType,
  OrganizationPaycardProvidersType,
  OrganizationPaycardRegisterCardEmployee,
  OrganizationPaycardRegisterCardSelectedLocationType,
  OrganizationPaycardSettingsParamsType,
  PaycardItem,
  RegisterPaycardParamsType,
  PaycardProviderType,
  SetOrganizationPaycardSettingsParamsType,
  ValueItem,
} from 'types/PaycardManagementTypes';
import {
  resetOrganizationPaycardEvent,
} from 'store/events';
import { SearchDropDown } from 'types/CommonTypes';

export const initialState = {
  organizationSettings: {
    list: {} as OrganizationPaycardSettingsParamsType,
    pending: false,
    isListFetched: false,
  },
  locationConfigSettings: {
    pending: false,
    values: {} as OrganizationPaycardSettingsParamsType,
    rejected: false,
  },
  providerOrganizations: {
    list: [] as OrganizationPaycardProviderListType[],
    pending: false,
    rejected: false,
  },
  providerOrganizationsWithParentId: {
    list: [] as OrganizationPaycardProviderListType[],
    pending: false,
    rejected: false,
  },
  providerOrganizationPrograms: {
    personalizedCardsProgramsList: [] as PaycardProviderType[],
    nonPersonalizedCardsProgramsList: [] as PaycardProviderType[],
    pending: false,
    rejected: false,
  },
  providerOrganizationProgramsPaycardConfig: {
    personalizedCardsProgramsList: [] as PaycardProviderType[],
    nonPersonalizedCardsProgramsList: [] as PaycardProviderType[],
    pending: false,
    rejected: false,
  },
  fundingAccountIds: {
    list: [] as OrganizationPaycardProvidersType['nonPersonalizedCardsPrograms'],
    pending: false,
    rejected: false,
  },
  masterAccountBalance: {
    value: {} as OrganizationPaycardMasterAccountBalanceType,
    pending: false,
    fetchFailed: false,
  },
  cardStock: {
    searchLocation: [] as SearchDropDown[],
    list: [] as CardStockLocationItem[],
    pendingList: false,
    pageNumber: 1 as number,
    numPages: 1 as number,
    fetchFailed: false,
  },
  eligibleLocations: {
    list: [] as OrganizationPaycardEligibleLocation[],
    pending: false,
    fetchFailed: false,
  },
  paycards: {
    list: [] as PaycardItem[],
    pending: false,
    pageNumber: 1,
    numPages: 1,
    fetchFailed: false,
    searchOptionType: [] as SearchDropDown[],
    searchValue: '',
  },
  registerCard: {
    pending: false,
    data: {
      selectedLocation:
        [] as OrganizationPaycardRegisterCardSelectedLocationType[],
      employeesSearchBy: [{ value: EmployeeSearchKeyType.payrollNumber }] as [
        { value: EmployeeSearchKeyType.payrollNumber; label: 'Payroll ID' }
      ] as ValueItem[],
      employeesSearch: '',
      selectedEmployee: null as any,
      isAddEmployeeFormValid: false,
      employeeDetails: null as OrganizationPaycardRegisterCardEmployee | null,
      isPairCardFormValid: false,
    },
  },
  loadFunds: {
    paymentDetails: null as any | null,
    pending: false,
  },
};

export const fetchOrganizationPaycardSettings = createAsyncThunk(
  'paycards/organization/settings',
  async (params: FetchOrganizationPaycardSettings, { getState, rejectWithValue }): Promise<any> => {
    const { organizationId } = params;
    const storeState = getState() as RootState;

    try {
      return await fetchOrganizationPaycardSettingsRequest(storeState.user.accessToken, organizationId);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchLocationPaycardConfigSettings = createAsyncThunk(
  'paycards/location/settings',
  async (params: FetchOrganizationPaycardSettings, { getState, rejectWithValue }): Promise<any> => {
    const { organizationId } = params;
    const storeState = getState() as RootState;

    try {
      return await fetchOrganizationPaycardSettingsRequest(storeState.user.accessToken, organizationId);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchOrganizationsPaycardProvider = createAsyncThunk(
  'paycards/providers',
  async (params: undefined, { getState, rejectWithValue }): Promise<any> => {
    const storeState = getState() as RootState;

    try {
      return await fetchOrganizationsPaycardProviderRequest(storeState.user.accessToken);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchOrganizationsPaycardProviderWithParentId = createAsyncThunk(
  'paycards/providers/parentId',
  async (
    params: FetchOrganizationsPaycardProviderParamsType,
    { getState, rejectWithValue },
  ): Promise<any> => {
    const { parentId } = params;
    const storeState = getState() as RootState;

    try {
      return await fetchOrganizationsPaycardProviderRequest(
        storeState.user.accessToken,
        parentId,
      );
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchOrganizationsPaycardProviderPrograms = createAsyncThunk(
  'paycards/provider/programs',
  async (
    params: FetchOrganizationsPaycardOptionsParamsType,
    { getState, rejectWithValue },
  ): Promise<any> => {
    const { providerOrganizationId } = params;
    const storeState = getState() as RootState;

    try {
      return await fetchOrganizationsPaycardProviderProgramsRequest(
        storeState.user.accessToken,
        providerOrganizationId,
      );
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchPaycardConfigProviderPrograms = createAsyncThunk(
  'paycards/config/programs',
  async (
    params: FetchOrganizationsPaycardOptionsParamsType,
    { getState, rejectWithValue },
  ): Promise<any> => {
    const { providerOrganizationId } = params;
    const storeState = getState() as RootState;

    try {
      return await fetchOrganizationsPaycardProviderProgramsRequest(
        storeState.user.accessToken,
        providerOrganizationId,
      );
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchOrganizationsPaycardFundingAccounts = createAsyncThunk(
  'paycards/provider/accounts',
  async (
    params: FetchOrganizationsPaycardOptionsParamsType,
    { getState, rejectWithValue },
  ): Promise<any> => {
    const { providerOrganizationId } = params;
    const storeState = getState() as RootState;

    try {
      return await fetchOrganizationsPaycardFundingAccountsRequest(
        storeState.user.accessToken,
        providerOrganizationId,
      );
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const saveOrganizationPaycardSettings = createAsyncThunk(
  'paycards/organization/settings/save',
  async (params: SetOrganizationPaycardSettingsParamsType, { getState, rejectWithValue }): Promise<any> => {
    const { organizationId, data } = params;
    const storeState = getState() as RootState;

    try {
      return await setOrganizationPaycardSettingsRequest(storeState.user.accessToken, organizationId, data);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchOrganizationMasterAccountPaycardBalance = createAsyncThunk(
  'paycards/masterAccount/balance',
  async (params: FetchOrganizationMasterAccountPaycardBalanceParamsType, { getState, rejectWithValue }): Promise<any> => {
    const { organizationId } = params;
    const storeState = getState() as RootState;

    try {
      return await fetchOrganizationMasterAccountPaycardBalanceRequest(storeState.user.accessToken, organizationId);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchOrganizationPaycardsInventory = createAsyncThunk(
  'paycards/inventory',
  async (params: FetchOrganizationPaycardsInventoryParamsType, { getState, rejectWithValue }): Promise<any> => {
    const {
      organizationId,
      locationId,
      page,
      pageSize,
    } = params;
    const storeState = getState() as RootState;

    try {
      return await fetchPaycardManagementInventoryRequest(storeState.user.accessToken, organizationId, locationId, page, pageSize);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchOrganizationPaycards = createAsyncThunk(
  'paycards',
  async (params: FetChOrganizationPaycardsParamsType, { getState, rejectWithValue }): Promise<any> => {
    const {
      organizationId,
      pageNum,
      pageSize,
      filter,
    } = params;
    const storeState = getState() as RootState;

    try {
      return await fetchOrganizationPaycardsRequest(
        storeState.user.accessToken,
        organizationId,
        pageNum,
        pageSize,
        filter,
      );
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchPaycardAccountDetails = createAsyncThunk(
  'paycards/accountDetails',
  async (
    params: FetchOrganizationPaycardAccountParamsType,
    { getState, rejectWithValue },
  ): Promise<any> => {
    const { organizationId, proxyNumber } = params;
    const storeState = getState() as RootState;

    try {
      return await fetchOrganizationPaycardAccountDetailsRequest(
        storeState.user.accessToken,
        organizationId,
        proxyNumber,
      );
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchOrganizationPaycardEligibleLocations = createAsyncThunk(
  'paycards/organization/eligibleLocations',
  async (params: FetchOrganizationMasterAccountPaycardBalanceParamsType, { getState, rejectWithValue }): Promise<any> => {
    const { organizationId } = params;
    const storeState = getState() as RootState;

    try {
      return await fetchOrganizationPaycardEligibleLocationsRequest(storeState.user.accessToken, organizationId);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const registerPaycard = createAsyncThunk(
  'paycards/registerCard',
  async (params: RegisterPaycardParamsType, { getState, rejectWithValue }): Promise<any> => {
    const { organizationId, locationId, data } = params;
    const storeState = getState() as RootState;

    try {
      return await registerPaycardRequest(storeState.user.accessToken, organizationId, locationId, data);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const loadFunds = createAsyncThunk(
  'paycards/loadFunds',
  async (params: LoadFundsParamsType, { getState, rejectWithValue }): Promise<any> => {
    const { organizationId, proxyNumber, data } = params;
    const storeState = getState() as RootState;

    try {
      return await loadFundsRequest(storeState.user.accessToken, organizationId, proxyNumber, data);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

const paycardsSlice = createSlice({
  name: 'paycards',
  initialState,
  reducers: {
    setSearchLocation: (state, action) => {
      state.cardStock.searchLocation = action.payload;
    },
    resetSearchLocation: (state) => {
      state.cardStock.searchLocation = initialState.cardStock.searchLocation;
    },
    resetOrganizationPaycardSettings: (state) => {
      state.organizationSettings = initialState.organizationSettings;
    },
    resetOrganizationsPaycardProvider: (state) => {
      state.providerOrganizations = initialState.providerOrganizations;
    },
    resetOrganizationsPaycardProviderPrograms: (state) => {
      state.providerOrganizationPrograms = initialState.providerOrganizationPrograms;
    },
    setPaycardsSearchOption: (state, action) => {
      state.paycards.searchOptionType = action.payload;
    },
    setPaycardsSearchValue: (state, action) => {
      state.paycards.searchValue = action.payload;
    },
    resetPaycardsSearchOption: (state) => {
      state.paycards.searchOptionType = initialState.paycards.searchOptionType;
    },
    resetPaycardsSearchValue: (state) => {
      state.paycards.searchValue = initialState.paycards.searchValue;
    },
    setOrganizationsPaycardRegisterCardLocation: (state, action) => {
      state.registerCard.data.selectedLocation = action.payload;
    },
    resetOrganizationsPaycardRegisterCardLocation: (state) => {
      state.registerCard.data.selectedLocation = initialState.registerCard.data.selectedLocation;
    },
    setOrganizationsPaycardRegisterCardSelectedEmployee: (state, action) => {
      state.registerCard.data.selectedEmployee = action.payload;
    },
    resetOrganizationsPaycardRegisterCardSelectedEmployee: (state) => {
      state.registerCard.data.selectedEmployee = initialState.registerCard.data.selectedEmployee;
    },
    setEmployeeSearchByCriteria: (state, action) => {
      state.registerCard.data.employeesSearchBy = action.payload;
    },
    setEmployeeSearch: (state, action) => {
      state.registerCard.data.employeesSearch = action.payload;
    },
    resetEmployeeSearchAndCriteria: (state) => {
      state.registerCard.data.employeesSearch = initialState.registerCard.data.employeesSearch;
      state.registerCard.data.employeesSearchBy = initialState.registerCard.data.employeesSearchBy;
    },
    setIsAddEmployeeFormValid: (state, action) => {
      state.registerCard.data.isAddEmployeeFormValid = action.payload;
    },
    setOrganizationsPaycardRegisterCardEmployeeDetails: (state, action) => {
      state.registerCard.data.employeeDetails = action.payload;
    },
    resetOrganizationsPaycardRegisterCardEmployeeDetails: (state) => {
      state.registerCard.data.employeeDetails = initialState.registerCard.data.employeeDetails;
    },
    setIsPairCardFormValid: (state, action) => {
      state.registerCard.data.isPairCardFormValid = action.payload;
    },
    setOrganizationsPaycardRegisterCardPaymentDetails: (state, action) => {
      state.loadFunds.paymentDetails = action.payload;
    },
    resetOrganizationsPaycardRegisterCardPaymentDetails: (state) => {
      state.loadFunds.paymentDetails = initialState.loadFunds.paymentDetails;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchOrganizationPaycardSettings.pending, (state) => {
      state.organizationSettings.pending = true;
      state.organizationSettings.list = initialState.organizationSettings.list;
      state.organizationSettings.isListFetched = initialState.organizationSettings.isListFetched;
    });

    builder.addCase(fetchOrganizationPaycardSettings.fulfilled, (state, action) => {
      state.organizationSettings.pending = false;
      state.organizationSettings.list = action.payload;
      state.organizationSettings.isListFetched = true;
    });

    builder.addCase(fetchOrganizationPaycardSettings.rejected, (state) => {
      state.organizationSettings.pending = false;
      state.organizationSettings.isListFetched = false;
    });

    builder.addCase(fetchLocationPaycardConfigSettings.pending, (state) => {
      state.locationConfigSettings = {
        ...initialState.locationConfigSettings,
        pending: true,
      };
    });

    builder.addCase(fetchLocationPaycardConfigSettings.fulfilled, (state, action) => {
      state.locationConfigSettings = {
        ...initialState.locationConfigSettings,
        values: action.payload,
      };
    });

    builder.addCase(fetchLocationPaycardConfigSettings.rejected, (state) => {
      state.locationConfigSettings = {
        ...initialState.locationConfigSettings,
        rejected: true,
      };
    });

    builder.addCase(fetchOrganizationsPaycardProvider.pending, (state) => {
      state.providerOrganizations.pending = true;
      state.providerOrganizations.rejected = false;
      state.providerOrganizations.list = initialState.providerOrganizations.list;
    });

    builder.addCase(fetchOrganizationsPaycardProvider.fulfilled, (state, action) => {
      state.providerOrganizations.pending = false;
      state.providerOrganizations.rejected = false;
      state.providerOrganizations.list = action.payload.values;
    });

    builder.addCase(fetchOrganizationsPaycardProvider.rejected, (state) => {
      state.providerOrganizations.pending = false;
      state.providerOrganizations.rejected = true;
    });

    builder.addCase(fetchOrganizationsPaycardProviderWithParentId.pending, (state) => {
      state.providerOrganizationsWithParentId.pending = true;
      state.providerOrganizationsWithParentId.rejected = false;
      state.providerOrganizationsWithParentId.list = initialState.providerOrganizationsWithParentId.list;
    });

    builder.addCase(fetchOrganizationsPaycardProviderWithParentId.fulfilled, (state, action) => {
      state.providerOrganizationsWithParentId.pending = false;
      state.providerOrganizationsWithParentId.rejected = false;
      state.providerOrganizationsWithParentId.list = action.payload.values;
    });

    builder.addCase(fetchOrganizationsPaycardProviderWithParentId.rejected, (state) => {
      state.providerOrganizationsWithParentId.pending = false;
      state.providerOrganizationsWithParentId.rejected = true;
    });

    builder.addCase(fetchOrganizationsPaycardProviderPrograms.pending, (state) => {
      state.providerOrganizationPrograms.pending = true;
      state.providerOrganizationPrograms.rejected = false;
      state.providerOrganizationPrograms.personalizedCardsProgramsList = initialState.providerOrganizationPrograms.personalizedCardsProgramsList;
      state.providerOrganizationPrograms.nonPersonalizedCardsProgramsList = initialState.providerOrganizationPrograms.nonPersonalizedCardsProgramsList;
    });

    builder.addCase(fetchOrganizationsPaycardProviderPrograms.fulfilled, (state, action) => {
      state.providerOrganizationPrograms.pending = false;
      state.providerOrganizationPrograms.rejected = false;
      state.providerOrganizationPrograms.personalizedCardsProgramsList = action.payload.personalizedCardsPrograms;
      state.providerOrganizationPrograms.nonPersonalizedCardsProgramsList = action.payload.nonPersonalizedCardsPrograms;
    });

    builder.addCase(fetchOrganizationsPaycardProviderPrograms.rejected, (state) => {
      state.providerOrganizationPrograms.pending = false;
      state.providerOrganizationPrograms.rejected = true;
    });

    builder.addCase(fetchPaycardConfigProviderPrograms.pending, (state) => {
      state.providerOrganizationProgramsPaycardConfig = {
        ...initialState.providerOrganizationProgramsPaycardConfig,
        pending: true,
      };
    });

    builder.addCase(fetchPaycardConfigProviderPrograms.fulfilled, (state, action) => {
      state.providerOrganizationProgramsPaycardConfig = {
        ...initialState.providerOrganizationProgramsPaycardConfig,
        personalizedCardsProgramsList: action.payload.personalizedCardsPrograms,
        nonPersonalizedCardsProgramsList: action.payload.nonPersonalizedCardsPrograms,
      };
    });

    builder.addCase(fetchPaycardConfigProviderPrograms.rejected, (state) => {
      state.providerOrganizationProgramsPaycardConfig = {
        ...initialState.providerOrganizationProgramsPaycardConfig,
        rejected: true,
      };
    });

    builder.addCase(fetchOrganizationsPaycardFundingAccounts.pending, (state) => {
      state.fundingAccountIds.pending = true;
      state.fundingAccountIds.rejected = false;
      state.fundingAccountIds.list = initialState.fundingAccountIds.list;
    });

    builder.addCase(fetchOrganizationsPaycardFundingAccounts.fulfilled, (state, action) => {
      state.fundingAccountIds.pending = false;
      state.fundingAccountIds.rejected = false;
      state.fundingAccountIds.list = action.payload.values;
    });

    builder.addCase(fetchOrganizationsPaycardFundingAccounts.rejected, (state) => {
      state.fundingAccountIds.pending = false;
      state.fundingAccountIds.rejected = true;
    });

    builder.addCase(saveOrganizationPaycardSettings.pending, (state) => {
      state.organizationSettings.pending = true;
    });

    builder.addCase(saveOrganizationPaycardSettings.fulfilled, (state) => {
      state.organizationSettings.pending = false;
    });

    builder.addCase(saveOrganizationPaycardSettings.rejected, (state) => {
      state.organizationSettings.pending = false;
    });

    builder.addCase(resetOrganizationPaycardEvent, (state) => {
      state.providerOrganizationPrograms = initialState.providerOrganizationPrograms;
      state.providerOrganizations = initialState.providerOrganizations;
      state.organizationSettings = initialState.organizationSettings;
    });

    builder.addCase(fetchOrganizationMasterAccountPaycardBalance.pending, (state) => {
      state.masterAccountBalance.pending = true;
      state.masterAccountBalance.value = initialState.masterAccountBalance.value;
      state.masterAccountBalance.fetchFailed = initialState.masterAccountBalance.fetchFailed;
    });

    builder.addCase(fetchOrganizationMasterAccountPaycardBalance.fulfilled, (state, action) => {
      state.masterAccountBalance.pending = false;
      state.masterAccountBalance.value = action.payload;
      state.masterAccountBalance.fetchFailed = false;
    });

    builder.addCase(fetchOrganizationMasterAccountPaycardBalance.rejected, (state) => {
      state.masterAccountBalance.pending = false;
      state.masterAccountBalance.fetchFailed = true;
    });

    builder.addCase(fetchOrganizationPaycardsInventory.pending, (state) => {
      state.cardStock.pendingList = true;
      state.cardStock.fetchFailed = initialState.cardStock.fetchFailed;
    });

    builder.addCase(fetchOrganizationPaycardsInventory.fulfilled, (state, action) => {
      state.cardStock.pendingList = false;
      state.cardStock.list = action.payload.values;
      state.cardStock.pageNumber = action.payload.page;
      state.cardStock.numPages = action.payload.totalPages;
      state.cardStock.fetchFailed = false;
    });

    builder.addCase(fetchOrganizationPaycardsInventory.rejected, (state) => {
      state.cardStock.pendingList = false;
      state.cardStock.list = initialState.cardStock.list;
      state.cardStock.pageNumber = initialState.cardStock.pageNumber;
      state.cardStock.numPages = initialState.cardStock.numPages;
      state.cardStock.fetchFailed = true;
    });

    builder.addCase(fetchOrganizationPaycardEligibleLocations.pending, (state) => {
      state.eligibleLocations.pending = true;
      state.eligibleLocations.list = initialState.eligibleLocations.list;
      state.eligibleLocations.fetchFailed = initialState.eligibleLocations.fetchFailed;
    });

    builder.addCase(fetchOrganizationPaycardEligibleLocations.fulfilled, (state, action) => {
      state.eligibleLocations.pending = false;
      state.eligibleLocations.list = action.payload.values;
      state.eligibleLocations.fetchFailed = false;
    });

    builder.addCase(fetchOrganizationPaycardEligibleLocations.rejected, (state) => {
      state.eligibleLocations.pending = false;
      state.eligibleLocations.list = initialState.eligibleLocations.list;
      state.eligibleLocations.fetchFailed = true;
    });

    builder.addCase(fetchOrganizationPaycards.pending, (state) => {
      state.paycards = {
        ...state.paycards,
        pending: true,
        fetchFailed: false,
      };
    });

    builder.addCase(fetchOrganizationPaycards.fulfilled, (state, action) => {
      state.paycards = {
        ...state.paycards,
        pending: false,
        fetchFailed: false,
        list: action.payload.values,
        pageNumber: action.payload.page,
        numPages: action.payload.totalPages,
      };
    });

    builder.addCase(fetchOrganizationPaycards.rejected, (state) => {
      state.paycards = {
        ...state.paycards,
        pending: false,
        fetchFailed: true,
      };
    });

    builder.addCase(registerPaycard.pending, (state) => {
      state.registerCard.pending = true;
    });

    builder.addCase(registerPaycard.fulfilled, (state) => {
      state.registerCard.pending = false;
    });

    builder.addCase(registerPaycard.rejected, (state) => {
      state.registerCard.pending = false;
    });

    builder.addCase(loadFunds.pending, (state) => {
      state.loadFunds.pending = true;
    });

    builder.addCase(loadFunds.fulfilled, (state) => {
      state.loadFunds.pending = false;
    });

    builder.addCase(loadFunds.rejected, (state) => {
      state.loadFunds.pending = false;
    });
  },
});

export const {
  resetOrganizationPaycardSettings,
  resetOrganizationsPaycardProvider,
  resetOrganizationsPaycardProviderPrograms,
  setSearchLocation,
  resetSearchLocation,
  setPaycardsSearchOption,
  setPaycardsSearchValue,
  resetPaycardsSearchOption,
  resetPaycardsSearchValue,
  setOrganizationsPaycardRegisterCardLocation,
  resetOrganizationsPaycardRegisterCardLocation,
  setEmployeeSearchByCriteria,
  setEmployeeSearch,
  setOrganizationsPaycardRegisterCardSelectedEmployee,
  resetOrganizationsPaycardRegisterCardSelectedEmployee,
  resetEmployeeSearchAndCriteria,
  setIsAddEmployeeFormValid,
  setIsPairCardFormValid,
  setOrganizationsPaycardRegisterCardEmployeeDetails,
  resetOrganizationsPaycardRegisterCardEmployeeDetails,
  setOrganizationsPaycardRegisterCardPaymentDetails,
  resetOrganizationsPaycardRegisterCardPaymentDetails,
} = paycardsSlice.actions;

export const paycardOrganizationSettingsSelector = (state: RootState) => state.paycards.organizationSettings.list;
export const paycardOrganizationSettingsPendingSelector = (state: RootState) => state.paycards.organizationSettings.pending;
export const paycardOrganizationSettingsIsListFetchedSelector = (state: RootState) => state.paycards.organizationSettings.isListFetched;
export const paycardProviderOrganizationsListSelector = (
  state: RootState,
): OrganizationPaycardProviderListType[] => state.paycards.providerOrganizations.list;
export const paycardProviderOrganizationsListPendingSelector = (state: RootState): boolean => state.paycards.providerOrganizations.pending;
export const paycardProviderOrganizationsListRejectedSelector = (state: RootState): boolean => state.paycards.providerOrganizations.rejected;
export const paycardProviderOrganizationsWithParentIdListSelector = (
  state: RootState,
): OrganizationPaycardProviderListType[] => state.paycards.providerOrganizationsWithParentId.list;
export const paycardProviderOrganizationsWithParentIdListPendingSelector = (
  state: RootState,
): boolean => state.paycards.providerOrganizationsWithParentId.pending;
export const paycardProviderOrganizationsWithParentIdListRejectedSelector = (
  state: RootState,
): boolean => state.paycards.providerOrganizationsWithParentId.rejected;
export const paycardPersonalizedProgramsListSelector = (state: RootState) => state.paycards.providerOrganizationPrograms.personalizedCardsProgramsList;
export const paycardNonPersonalizedProgramsListSelector = (state: RootState) => state.paycards.providerOrganizationPrograms.nonPersonalizedCardsProgramsList;
export const paycardProgramsListPendingSelector = (state: RootState): boolean => state.paycards.providerOrganizationPrograms.pending;
export const paycardProgramsListRejectedSelector = (state: RootState): boolean => state.paycards.providerOrganizationPrograms.rejected;
export const paycardPersonalizedProgramsConfigListSelector = (
  state: RootState,
) => state.paycards.providerOrganizationProgramsPaycardConfig.personalizedCardsProgramsList;
export const paycardNonPersonalizedProgramsConfigListSelector = (
  state: RootState,
) => state.paycards.providerOrganizationProgramsPaycardConfig.nonPersonalizedCardsProgramsList;
export const paycardProgramsConfigListPendingSelector = (
  state: RootState,
): boolean => state.paycards.providerOrganizationProgramsPaycardConfig.pending;
export const paycardProgramsConfigListRejectedSelector = (
  state: RootState,
): boolean => state.paycards.providerOrganizationProgramsPaycardConfig.rejected;
export const paycardFundingAccountIdsListSelector = (state: RootState) => state.paycards.fundingAccountIds.list;
export const paycardFundingAccountIdsListPendingSelector = (state: RootState) => state.paycards.fundingAccountIds.pending;
export const paycardFundingAccountIdsListRejectedSelector = (state: RootState) => state.paycards.fundingAccountIds.rejected;
export const paycardMasterAccountBalanceSelector = (state: RootState): OrganizationPaycardMasterAccountBalanceType => state.paycards.masterAccountBalance.value;
export const paycardMasterAccountBalancePendingSelector = (state: RootState): boolean => state.paycards.masterAccountBalance.pending;
export const paycardMasterAccountBalanceFetchFailedSelector = (state: RootState): boolean => state.paycards.masterAccountBalance.fetchFailed;
export const paycardPaycardManagementCardStockSearchLocationSelector = (state: RootState) => state.paycards.cardStock.searchLocation;
export const paycardPaycardManagementCardStockListSelector = (state: RootState): CardStockLocationItem[] => state.paycards.cardStock.list;
export const paycardPaycardManagementCardStockListPendingSelector = (state: RootState): boolean => state.paycards.cardStock.pendingList;
export const paycardPaycardManagementCardStockTotalPagesSelector = (state: RootState): number => state.paycards.cardStock.numPages;
export const paycardPaycardManagementCardStockPageNumberSelector = (state: RootState): number => state.paycards.cardStock.pageNumber;
export const paycardPaycardManagementCardStockFetchFailedSelector = (state: RootState): boolean => state.paycards.cardStock.fetchFailed;
export const paycardOrganizationEligibleLocationsListSelector = (state: RootState)
: OrganizationPaycardEligibleLocation[] => state.paycards.eligibleLocations.list;
export const paycardOrganizationEligibleLocationsPendingSelector = (state: RootState): boolean => state.paycards.eligibleLocations.pending;
export const paycardOrganizationEligibleLocationsFetchFailedSelector = (state: RootState): boolean => state.paycards.eligibleLocations.fetchFailed;
export const paycardsListSelector = (state: RootState): PaycardItem[] => state.paycards.paycards.list;
export const paycardsListPendingSelector = (state: RootState): boolean => state.paycards.paycards.pending;
export const paycardsListPageNumberSelector = (state: RootState): number => state.paycards.paycards.pageNumber;
export const paycardsListNumPagesSelector = (state: RootState): number => state.paycards.paycards.numPages;
export const paycardsListFetchFailedSelector = (state: RootState): boolean => state.paycards.paycards.fetchFailed;
export const paycardsListSearchOptionTypeSelector = (state: RootState): SearchDropDown[] => state.paycards.paycards.searchOptionType;
export const paycardsListSearchValueSelector = (state: RootState): string => state.paycards.paycards.searchValue;
export const paycardOrganizationRegisterCardSelectedLocationSelector = (state: RootState) => state.paycards.registerCard.data.selectedLocation;
export const paycardOrganizationRegisterCardChooseEmployeeSearchByCriteriaSelector = (state: RootState) => state.paycards.registerCard.data.employeesSearchBy;
export const paycardOrganizationRegisterCardChooseEmployeeSearchSelector = (state: RootState) => state.paycards.registerCard.data.employeesSearch;
export const paycardOrganizationRegisterCardSelectedEmployeeSelector = (state: RootState) => state.paycards.registerCard.data.selectedEmployee;
export const paycardOrganizationRegisterCardIsValidAddEmployeeFormSelector = (state: RootState)
: boolean => state.paycards.registerCard.data.isAddEmployeeFormValid;
export const paycardOrganizationRegisterCardEmployeeDetailsSelector = (state: RootState) => state.paycards.registerCard.data.employeeDetails;
export const paycardOrganizationRegisterCardIsValidPairCardFormSelector = (state: RootState): boolean => state.paycards.registerCard.data.isPairCardFormValid;
export const paycardOrganizationRegisterCardPaymentDetailsSelector = (state: RootState) => state.paycards.loadFunds.paymentDetails;
export const paycardsRegisterPaycardPendingSelector = (state: RootState): boolean => state.paycards.registerCard.pending;
export const paycardsLoadFundsPendingSelector = (state: RootState): boolean => state.paycards.loadFunds.pending;
export const paycardLocationConfigSettingsSelector = (state: RootState) => state.paycards.locationConfigSettings.values;
export const paycardLocationConfigSettingsPendingSelector = (state: RootState) => state.paycards.locationConfigSettings.pending;
export const paycardLocationConfigSettingsRejectedSelector = (state: RootState) => state.paycards.locationConfigSettings.rejected;

export default paycardsSlice.reducer;
